import React, {useEffect, useState} from 'react';
import {ShiftBatRowNotePriority, ShiftBatRowType} from '../../model/shiftBat';
import ShiftBatServiceRowEdit from './ShiftBatServiceRowEdit';
import './ShiftBat.css';
import ShiftBatStopRowEdit from './ShiftBatStopRowEdit';
import ShiftBatDeadRowEdit from './ShiftBatDeadRowEdit';
import ShiftBatNoteRowEdit from './ShiftBatNoteRowEdit';
import ShiftBatBreakRowEdit from './ShiftBatBreakRowEdit';
import ShiftBatCharterRowEdit from './ShiftBatCharterRowEdit';

function ShiftBatRowEdit({
                             shiftBatRow, schedules,
                             allRoutes, setAllStops,
                             allStops, setOnMapSelection,
                             onCancel,
                             onUpdate, onCreate,
                             setEditRouteRow,
                             isLoading, charter,
                             updating, setUpdating
                         }) {

    const [hideTime, setHideTime] = useState(shiftBatRow.hideTime);
    const [time, setTime] = useState(shiftBatRow.time);
    const [type, setType] = useState(shiftBatRow.type);
    const [title, setTitle] = useState(shiftBatRow.title);
    const [description, setDescription] = useState(shiftBatRow.description);
    const [duration, setDuration] = useState((shiftBatRow.duration / 60).toFixed(0));
    const [route, setRoute] = useState(shiftBatRow.route);
    const [trip, setTrip] = useState(shiftBatRow.trip);
    const [stop, setStop] = useState(shiftBatRow.stop);
    const [priority, setPriority] = useState(shiftBatRow.priority);

    useEffect(() => {
        setHideTime(shiftBatRow.hideTime);
        setTime(shiftBatRow.time);
        setType(shiftBatRow.type);
        setTitle(shiftBatRow.title);
        setDescription(shiftBatRow.description);
        setDuration((shiftBatRow.duration / 60).toFixed(0));
        setRoute(shiftBatRow.route);
        setTrip(shiftBatRow.trip);
        setStop(shiftBatRow.stop);
        setPriority(shiftBatRow.priority);
    }, [shiftBatRow, setHideTime, setTime, setType, setTitle, setDescription, setDuration, setRoute, setTrip, setStop, setPriority]);

    // TODO: removed for BUS-454 as deemed unnecessary due to the stop rows being added in shiftBat.js
    // useEffect(() => {
    //     if (route) {
    //         route.setBaseStops(allStops, route.stopTimes, !config?.operator?.opts?.shiftbat?.turnsOnAllStops);
    //     }
    // }, [allStops, route])

    const resetRow = type => {
        setHideTime(type === ShiftBatRowType.note);
        setType(type);
        setRoute(null);
        setTrip(null);
        setTitle(null);
        setDuration('0');
        setDescription(null);
        setPriority(ShiftBatRowNotePriority.normal);
    };

    // useEffect(() => {
    //
    //     if(shiftBatRow) {
    //         setTime(shiftBatRow.time)
    //     }
    //
    // }, [shiftBatRow, setTime])

    // console.log(time);

    return (
        <div key={shiftBatRow.id} className="row d-flex align-items-center sb-row current-edit">
            <div className="col-lg-12 sb-row-edit">
                {type === ShiftBatRowType.noteTimed ?
                    <ShiftBatNoteRowEdit shiftBatRow={shiftBatRow} hideTime={hideTime} time={time} type={type}
                                         title={title}
                                         description={description}
                                         priority={priority} setTime={setTime} setTitle={setTitle}
                                         setDescription={setDescription} updating={updating} isLoading={isLoading}
                                         setPriority={setPriority} resetRow={resetRow} onCancel={onCancel}
                                         onCreate={onCreate} onUpdate={onUpdate}/>
                    : type === ShiftBatRowType.note ?
                        <ShiftBatNoteRowEdit shiftBatRow={shiftBatRow} type={type} title={title}
                                             description={description} hideTime={hideTime}
                                             priority={priority} setTitle={setTitle}
                                             setDescription={setDescription} updating={updating} isLoading={isLoading}
                                             setPriority={setPriority} resetRow={resetRow} onCancel={onCancel}
                                             onCreate={onCreate} onUpdate={onUpdate}/>
                        : type === ShiftBatRowType.stop ?
                            <ShiftBatStopRowEdit shiftBatRow={shiftBatRow} type={type} setType={setType} stop={stop}
                                                 setStop={setStop} setAllStops={setAllStops}
                                                 time={time} setTime={setTime} title={title} setTitle={setTitle}
                                                 description={description} setDescription={setDescription}
                                                 allStops={allStops} resetRow={resetRow}
                                                 setOnMapSelection={setOnMapSelection} charter={charter}
                                                 updating={updating} onCancel={onCancel} onUpdate={onUpdate}/>
                            : type === ShiftBatRowType.dead ?
                                <ShiftBatDeadRowEdit shiftBatRow={shiftBatRow} setEditRouteRow={setEditRouteRow}
                                                     route={route} setRoute={setRoute} time={time} title={title}
                                                     setTitle={setTitle} updating={updating} isLoading={isLoading}
                                                     onCancel={onCancel} onCreate={onCreate} onUpdate={onUpdate}
                                                     description={description} setDescription={setDescription}
                                                     duration={duration} setDuration={setDuration}/>
                                :
                                type === ShiftBatRowType.charter ?
                                    <ShiftBatCharterRowEdit shiftBatRow={shiftBatRow} allRoutes={allRoutes}
                                                            allStops={allStops} type={type} schedules={schedules}
                                                            setTime={setTime} time={time} title={title}
                                                            setTitle={setTitle} route={route} setRoute={setRoute}
                                                            trip={trip} updating={updating} setUpdating={setUpdating}
                                                            resetRow={resetRow} setEditRouteRow={setEditRouteRow}
                                                            description={description}
                                                            setTrip={setTrip} isLoading={isLoading}
                                                            setDescription={setDescription}
                                                            onCancel={onCancel} onCreate={onCreate}
                                                            onUpdate={onUpdate}/>
                                    :
                                    type === ShiftBatRowType.service ?
                                        <ShiftBatServiceRowEdit shiftBatRow={shiftBatRow} allRoutes={allRoutes}
                                                                allStops={allStops} type={type} schedules={schedules}
                                                                setTime={setTime} time={time} title={title}
                                                                setTitle={setTitle} route={route} setRoute={setRoute}
                                                                trip={trip} updating={updating}
                                                                setUpdating={setUpdating}
                                                                resetRow={resetRow}
                                                                description={description}
                                                                setTrip={setTrip} isLoading={isLoading}
                                                                setDescription={setDescription}
                                                                onCancel={onCancel} onCreate={onCreate}
                                                                onUpdate={onUpdate}/>
                                        :
                                        [ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(type) ?
                                            <ShiftBatBreakRowEdit shiftBatRow={shiftBatRow}
                                                                  type={type} setTime={setTime} time={time}
                                                                  setTitle={setTitle} title={title} duration={duration}
                                                                  setDuration={setDuration}
                                                                  updating={updating} resetRow={resetRow}
                                                                  onCancel={onCancel} onUpdate={onUpdate}/>
                                            :
                                            <></>

                }
            </div>
        </div>
    );

}

export default React.memo(ShiftBatRowEdit);
