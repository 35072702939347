import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Charters.css';
import {charterModelData, routeModelData} from '../../services/ModelService';
import {Charter} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import {Button, Card, Col, Flex, Input, Row, Tabs} from 'antd';
import CharterMenu from './CharterMenu';
import {BusRoute} from '../../model/busRoute';
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import {ulid} from 'ulid';
import EditRouteDetails from '../../components/EditRouteDetails';
import RoutesViewer from '../../components/RoutesViewer';
import useAllStops from '../../hooks/useAllStops';
import useAllRoutes from '../../hooks/useAllRoutes';
import useAllSchedules from '../../hooks/useAllSchedules';
import {chain} from 'lodash';
import {toKmMs} from '../../libs/formatLib';
import LoadMessage from '../../components/LoadMessage';
import { DndContext, PointerSensor, closestCenter, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const {TextArea} = Input;

function createAcronym(str) {
    return chain(str)
        .words() // Split the string into words
        .map(word => word[0]) // Extract the first letter of each word
        .join('') // Combine the letters
        .toUpper() // Convert to uppercase
        .value(); // Get the final result
}

function CharterItinerary({initialInstance}) {

    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: initialInstance || new Charter({charterId: '_'}),
    });

    const {allStops} = useAllStops();
    const {allRoutes} = useAllRoutes();
    const {allSchedules} = useAllSchedules();
    const [itinerary, setItinerary] = useState([]);
    const [selectedRouteId, setSelectedRouteId] = useState(itinerary?.[0]?.routeId);
    const carousel = useRef();

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({...charter});
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                console.log('Updating charter: ', newCharter);
                return newCharter;
            });
        },
        [setCharter]
    );

    const newRoute = (charter, legNum = 1) => {
        const route = new BusRoute({
            routeId: ulid(),
            routeNumber: createAcronym(charter.name) + '-' + legNum,
            routeName: 'Leg ' + legNum,
            routeDetails: charter.name,
            published: 1,
            charter: true
        });
        return route;
    };

    useEffect(() => {
        if (charter.charterId === '_') {
            return;
        }
        if (charter.itinerary?.length) {
            return setItinerary(charter.itinerary);
        }
        setMode({edit: true});
        setItinerary([newRoute(charter)]);
    }, [charter, setItinerary, setMode]);

    const DraggableTabNode = ({className, ...props}) => {
        const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
            id: props['data-node-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
        };
        return React.cloneElement(props.children, {
            ref: setNodeRef,
            style,
            ...attributes,
            ...listeners,
        });
    };
    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const onDragEnd = ({active, over}) => {
        if (active.id !== over?.id) {
            setItinerary((prev) => {
                const activeIndex = prev.findIndex((i) => i.routeId === active.id);
                const overIndex = prev.findIndex((i) => i.routeId === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };
    return (
        <div className="charter-details w-secondary-menu">
            {itinerary?.length ? (
                <>
                    <SaveMenu
                        save={async () => {
                            charter.itinerary = itinerary;
                            await routeModelData.save(itinerary);
                            await charterModelData.save(charter);
                            setMode({edit: false});
                            setCharter(new Charter({...charter}))
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={'2'}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: false,
                                    duty: mode.edit || !charter.itinerary?.length,
                                    quote: mode.edit || !charter.duty,
                                }}
                            />
                        )}
                        <Col xs={24} lg={24}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    {/* <div className="d-flex align-items-center justify-content-between filter-options-main">
                                        <div className="d-flex align-items-center">
                                            {mode.edit && <Button onClick={() => {
                                                    let route = newRoute(charter, itinerary.length);
                                                    setItinerary(itinerary => itinerary.concat(route));
                                                    setSelectedRouteId(route.routeId);
                                                }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Add</Button>}
                                            {mode.edit && itinerary.length > 1 &&
                                                <Button danger={true} onClick={() => {
                                                    const idx = itinerary.findIndex(itinerary => itinerary.routeId === selectedRouteId);
                                                    const newItinerary = itinerary.filter(itinerary => itinerary.routeId !== selectedRouteId)
                                                    setItinerary(newItinerary);
                                                    setSelectedRouteId( idx >= newItinerary.length ? newItinerary[newItinerary.length-1].routeId : newItinerary[idx].routeId);
                                                }} type="primary" className="icon-button btn-filled btn-error icon-10" icon={<Close/>}>Remove</Button>}
                                        </div>
                                    </div> */}
                                    {/* <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Itinerary</div>
                                                <div>
                                                    {mode.edit && <Button onClick={() => {
                                                            let route = newRoute(charter, itinerary.length);
                                                            setItinerary(itinerary => itinerary.concat(route));
                                                            setSelectedRouteId(route.routeId);
                                                        }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Add Itinerary</Button>}
                                                    {mode.edit && itinerary.length > 1 &&
                                                        <Button danger={true} onClick={() => {
                                                            const idx = itinerary.findIndex(itinerary => itinerary.routeId === selectedRouteId);
                                                            const newItinerary = itinerary.filter(itinerary => itinerary.routeId !== selectedRouteId)
                                                            setItinerary(newItinerary);
                                                            setSelectedRouteId( idx >= newItinerary.length ? newItinerary[newItinerary.length-1].routeId : newItinerary[idx].routeId);
                                                        }} type="primary" className="icon-button btn-filled btn-error icon-10" icon={<Trash/>}></Button>}
                                                </div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    > */}
                                        {mode.edit ?
                                            // <Carousel ref={carousel} dots={true} draggable={false}
                                            //                    swipeToSlide={false} arrows={true} infinite={false}
                                            //                    beforeChange={(oldIndex, newIndex) => {
                                            //                        setItineraryIndex(newIndex);
                                            //                    }}
                                            // >
                                            <Tabs hideAdd
                                                  size={'middle'}
                                                  onChange={setSelectedRouteId}
                                                  defaultActiveKey={itinerary[0].routeId}
                                                  activeKey={selectedRouteId}
                                                  type="card"
                                                  renderTabBar={(tabBarProps, DefaultTabBar) => (
                                                      <DndContext sensors={[sensor]} onDragEnd={onDragEnd} collisionDetection={closestCenter}>
                                                          <SortableContext items={itinerary.map((i) => i.routeId)} strategy={horizontalListSortingStrategy}>
                                                              <DefaultTabBar {...tabBarProps}>
                                                                  {(node) => (
                                                                      <DraggableTabNode {...node.props} key={node.key}>
                                                                          {node}
                                                                      </DraggableTabNode>
                                                                  )}
                                                              </DefaultTabBar>
                                                          </SortableContext>
                                                      </DndContext>
                                                  )}
                                                  tabBarExtraContent={<div className="d-flex align-items-center">{mode.edit && <Button onClick={() => {
                                                    let route = newRoute(charter, itinerary.length);
                                                    setItinerary(itinerary => itinerary.concat(route));
                                                    setSelectedRouteId(route.routeId);
                                                }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Add</Button>}
                                            {mode.edit && itinerary.length > 1 &&
                                                <Button danger={true} onClick={() => {
                                                    const idx = itinerary.findIndex(itinerary => itinerary.routeId === selectedRouteId);
                                                    const newItinerary = itinerary.filter(itinerary => itinerary.routeId !== selectedRouteId)
                                                    setItinerary(newItinerary);
                                                    setSelectedRouteId( idx >= newItinerary.length ? newItinerary[newItinerary.length-1].routeId : newItinerary[idx].routeId);
                                                }} type="primary" className="icon-button btn-filled btn-error icon-10" icon={<Close/>}>Remove</Button>}</div>}
                                                  items={itinerary.map(route => {
                                                      return {
                                                          key: route.routeId,
                                                          label: route.routeName + ` (${toKmMs(route.distance)})`,
                                                          children: <EditRouteDetails charter={true}
                                                                                      initialRoute={route.clone()}
                                                                                      onRouteUpdate={r => {
                                                                                          console.log('Route update: ', r);
                                                                                          setItinerary(i => {
                                                                                              return i.map(route => {
                                                                                                  if (route.routeId === r.routeId) {
                                                                                                      return r;
                                                                                                  }
                                                                                                  return route;
                                                                                              });
                                                                                          });
                                                                                      }}/>
                                                      };
                                                  })}/> :
                                            // </Carousel> :
                                            <RoutesViewer
                                                routes={allRoutes}
                                                schedules={allSchedules}
                                                allStops={allStops}
                                                filteredRoutes={itinerary}
                                                fetching={!allStops}
                                                verifiedOnly={false}
                                                allowComments={false}
                                                showComments={false}
                                                showWpSelector={true}
                                                expand={true}
                                            />}
                                    {/* </Card> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (<LoadMessage message="Loading Charter Details..."/>)}
        </div>
    );
}

export default React.memo(CharterItinerary);
