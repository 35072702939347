import React, {useState} from 'react';
import EditItemView from '../EditItemView';
import {FEATURE} from '../../model/features';
import {Col, Form, Row} from 'react-bootstrap';
import {vehicleModelData} from '../../services/ModelService';
import {InputNumber, Radio, Select} from 'antd';
import {VEHICLE_FUEL_TYPES, VEHICLE_STATUS} from '../../model/vehicle';
import {DEFAULT_VEHICLE_RATE} from './Vehicles';

const {Option} = Select;

const vehicleStatusOptions = Object.keys(VEHICLE_STATUS).map(key => ({
    label: VEHICLE_STATUS[key],
    value: VEHICLE_STATUS[key],
    disabled: false
}));

function EditVehicleView(props) {
    const [vehicle, setVehicle] = useState({...props.editing, rate: DEFAULT_VEHICLE_RATE});

    return (
        <EditItemView item={vehicle} feature={FEATURE.dty} modelData={vehicleModelData}
                      {...props}
        >
            <Row><Col><Form.Group className="mb-3" controlId={vehicle.vehicleId + '_VehicleName'}>
                <Form.Label>Vehicle Name</Form.Label>
                <Form.Control type="text" placeholder="Vehicle name" value={vehicle.vehicleName || ''}
                              onChange={(e) => {
                                  setVehicle(vehicle => {
                                      vehicle.vehicleName = e.target.value;
                                      return {...vehicle};
                                  });
                              }}/>
            </Form.Group></Col></Row>
            <Row><Col><Form.Group className="mb-3" controlId={vehicle.vehicleId + '_VehicleRego'}>
                <Form.Label>Vehicle Rego</Form.Label>
                <Form.Control type="text" placeholder="Vehicle rego" value={vehicle.vehicleRego || ''}
                              onChange={(e) => {
                                  setVehicle(vehicle => {
                                      vehicle.vehicleRego = e.target.value;
                                      return {...vehicle};
                                  });
                              }}/>
            </Form.Group></Col></Row>

            <Row><Col><Form.Group className="mb-3" controlId={vehicle.vehicleId + '_VehicleRate'}>
                <Form.Label>Rate ($/hr)</Form.Label>
                <InputNumber
                    placeholder="Enter rate per km"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    value={vehicle.rate}
                    onChange={(value) => setVehicle(vehicle => {
                        vehicle.rate = value;
                        return {...vehicle};
                    })}
                />
            </Form.Group></Col></Row>
            <Row><Col>
                <Form.Group className="mb-3" controlId={vehicle.vehicleId + '_Tankage'}>
                    <Form.Label>Capacity</Form.Label>
                    <Select
                        size="lg"
                        className="w-100"
                        onChange={value => {
                            setVehicle(vehicle => {
                                vehicle.capacity = value;
                                return {...vehicle};
                            });
                        }}
                        value={vehicle.capacity}
                        options={Array.from(Array(100).keys()).map(seats => ({value: seats, label: seats}))}
                    /></Form.Group></Col></Row>
            <Row><Col>
                <Form.Group className="mb-3" controlId={vehicle.vehicleId + '_Tankage'}>
                    <Form.Label>Fuel Type</Form.Label>
                    <Select
                        size="lg"
                        className="w-100"
                        onChange={value => {
                            setVehicle(vehicle => {
                                vehicle.fuelType = value;
                                return {...vehicle};
                            });
                        }}
                        value={vehicle.fuelType}
                        options={Object.keys(VEHICLE_FUEL_TYPES).map(key => ({
                            value: key,
                            label: VEHICLE_FUEL_TYPES[key]
                        }))}
                    /></Form.Group></Col></Row>
            <Row><Col><Form.Group className="mb-3" controlId={vehicle.vehicleId + '_Tankage'}>
                <Form.Label>Tankage</Form.Label>
                <Form.Control type="number" placeholder="Tankage" value={vehicle.tankage || 0}
                              onChange={(e) => {
                                  setVehicle(vehicle => {
                                      vehicle.tankage = e.target.value;
                                      return {...vehicle};
                                  });
                              }}/>
            </Form.Group></Col></Row>
            <Row><Col><Form.Group className="mb-3" controlId={vehicle.vehicleId + '_maxDist'}>
                <Form.Label>Max Distance</Form.Label>
                <Form.Control type="number" placeholder="Max distance" value={vehicle.maxDistance || 0}
                              onChange={(e) => {
                                  setVehicle(vehicle => {
                                      vehicle.maxDistance = e.target.value;
                                      return {...vehicle};
                                  });
                              }}/>
            </Form.Group></Col></Row>
            <Row><Col>
                <Form.Group className="mb-3" controlId={vehicle.vehicleId + '_VehicleStatus'}>
                    <Form.Label className="mr-2">Status</Form.Label>
                    <Form.Row className="ml-0">
                        <Radio.Group
                            options={vehicleStatusOptions}
                            onChange={({target: {value}}) => setVehicle(vehicle => {
                                vehicle.vehicleStatus = value;
                                return {...vehicle};
                            })}
                            defaultValue={VEHICLE_STATUS.active}
                            value={vehicle.vehicleStatus}
                            optionType="button"
                            buttonStyle="solid"
                        /></Form.Row>
                </Form.Group>
            </Col></Row>
        </EditItemView>
    );
}

export default React.memo(EditVehicleView);

