import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import '../containers/BusRouteDetails.css';
import RouteMap from '../components/RouteMap';
import {Col, ColorPicker, Popover, Row, Select, Switch} from 'antd';
import {OrderedListOutlined} from '@ant-design/icons';
import {useAppContext} from '../libs/contextLib';
import {getDepartureTimeAsSecondsSinceMidnight, hasLogo, publicStopFilter, reverseRoute} from '../libs/routes-lib';
import {Button} from 'react-bootstrap';
import config from '../config';
import {
    driverShiftModelData,
    routeModelData,
    routesModelExpiryService,
    stopModelData,
    transferModelData,
    vehicleShiftModelData
} from '../services/ModelService';
import {cloneDeep, values} from 'lodash';
import {BusRoute, ROUTE_STATUSES} from '../model/busRoute';
import Trips from '../components/Trips';
import util from 'util';
import LoadMessage from '../components/LoadMessage';
import {ReactComponent as Plus} from '../assets/icons/Plus_md.svg';
import {ReactComponent as SwitchIcon} from '../assets/icons/Switch.svg';
import {ReactComponent as Color} from '../assets/icons/Color.svg';
import {ReactComponent as Trash} from '../assets/icons/Trash.svg';
import {ReactComponent as StopTime} from '../assets/icons/StopTime.svg';
import ReactGA from 'react-ga4';
import {ulid} from 'ulid';
import {debounce} from 'lodash/function';
import RouteTitleEdit from '../components/RouteTitleEdit';
import {last} from 'lodash/array';
import {useTimeTravelState} from '../libs/useTimeTravelState';
import {find} from 'lodash/collection';
import log from 'loglevel';
import TransfersModal from '../features/Transfers/TransfersModal';
import {ReactComponent as Timer} from '../assets/icons/Timer.svg';
import TripDetailsModal from '../components/TripDetailsModal';
import RouteVariantDetailsModal from '../components/RouteVariantDetailsModal';
import HistoryModal from '../components/HistoryModal';
import {getEditor} from '../hooks/getEditor';
import useAllowComments from '../hooks/useAllowComments';

const shiftSort = (s1, s2) => s1.shiftName.localeCompare(s2.shiftName);

const logger = log.getLogger('ServiceDetails');

function EditRouteDetails({initialRoute, onRouteUpdate, charter}) {
    const {id} = useParams();
    const {
        isAuthenticated,
        schedules,
        apiKey,
        operator,
        user,
        setBreadcrumb,
        setPageTitle,
        setFaultState,
        messageApi
    } = useAppContext();
    const {editor} = getEditor({user});
    const history = useHistory();
    const [route, setRouteHistory, controls] = useTimeTravelState(null, {maxSize: 10, onUpdate: onRouteUpdate});

    const [isLoaded, setIsLoaded] = useState(!!initialRoute);
    const [validationHelper, setValidationHelper] = useState({});

    // const [newScheduleVisible, setNewScheduleVisible] = useState(false);
    const [vehicleShifts, setVehicleShifts] = useState([]);
    const [driverShifts, setDriverShifts] = useState([]);
    const [allStops, setAllStops] = useState(null);
    const [allRoutes, setAllRoutes] = useState(null);
    const [allTransfers, setAllTransfers] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);

    const [deletedStops, setDeletedStops] = useState([]);

    const [tolerance, setTolerance] = useState(0);

    const [fileList, setFileList] = useState([]);

    const initialRouteRef = useRef(null);
    // const [updateCount, setUpdateCount] = useState(0)

    const [newRoute, setNewRoute] = useState(false);

    const [showSeconds, setShowSeconds] = useState(false);
    const [cumulativeUpdate, setCumulativeUpdate] = useState(false);

    const [selectedTripId, setSelectedTripId] = useState(null);

    const [showNewStop, setShowNewStop] = useState(false);
    const [zoomStop, setZoomStop] = useState(null);

    const [showAllStops, setShowAllStops] = useState(false);
    const [editingRoute, setEditingRoute] = useState(false);

    const [focusPt, setFocusPt] = useState(null);
    const [allowComments, setAllowComments] = useAllowComments();
    const [showComments, setShowComments] = useState(false);

    const [headsigns, setHeadsigns] = useState({});
    const [aliases, setAliases] = useState({});

    const [focusTransfers, setFocusTransfers] = useState({to: [], from: []});

    const [sortedSchedules, setSortedSchedules] = useState([]);


    const presets = ([
        {
            label: 'Presets',
            colors: ['#007bff', '#1b65ac', '#00aeef', '#5567a9', '#812990', '#cb0b0b', '#a02125', '#c90b86', '#e6b00e', '#f57d20', '#008a5d', '#00b26a', '#005a23', '#76a33a', '#515553'],
        }
    ]);

    const [showColorPicker, setshowColorPicker] = useState(false);

    function handleColorPicker() {
        setshowColorPicker(showColorPicker => !showColorPicker);
    }

    useEffect(() => {
        const beforeunload = (e) => {
            if (updated) {
                e.returnValue = 'unloading';
                return 'unloading';
            }
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, [updated]);

    useEffect(() => {
        if (schedules) {
            setSortedSchedules(Object.values(schedules).sort((a, b) => a.scheduleName.localeCompare(b.scheduleName)));
        }
    }, [schedules, setSortedSchedules]);

    // eslint-disable-next-line
    const setRoute = useCallback(debounce((r, opts) => {
        console.log('$$$$$$$ SETTING ROUTE $$$$$$$');
        setRouteHistory(r, opts);
        if (!opts?.overwriteLastEntry) {
            setUpdated(true);
        }
    }, 500), [setRouteHistory, setUpdated]);

    const updateRoute = ({updatedRoute, setRoute, allStops, allTransfers}) => {
        if (!allStops || !allTransfers) {
            return;
        }
        setRoute(currentRoute => {
            if (updatedRoute && updatedRoute.updatedAt !== currentRoute.updatedAt) {
                updatedRoute.setTransfers(allTransfers, allRoutes);
                messageApi.info(`The route has been updated elsewhere by ${updatedRoute.getEditor()}.`, 10);
                return updatedRoute;
            }
            return currentRoute;
        }, {overwriteLastEntry: true});
    };

    useEffect(() => {
        // if dependencies aren't ready return
        if ((!initialRoute && !id) || !history || !setRoute || !apiKey || !editor || !setBreadcrumb || !setPageTitle
            || !initialRouteRef || !messageApi || !setNewRoute || !setAllTransfers || !setAllRoutes || allRoutes || allStops) return;

        async function onLoad() {
            try {

                if (!isAuthenticated) {
                    console.log('NOT AUTHENTICATED');
                    history.push('/');
                    return;
                }

                const vehShifts = values(await vehicleShiftModelData.getAll());
                setVehicleShifts(vehShifts.sort(shiftSort));
                const drShifts = values(await driverShiftModelData.getAll());
                setDriverShifts(drShifts.sort(shiftSort));
                transferModelData.addListener({
                    id: 'BusROuteDetailsTransferListener',
                    loaded: ts => setAllTransfers(ts), setterFn: setAllTransfers
                });

                const allTransfers = await transferModelData.getAll();
                const stopsById = await stopModelData.getAll();

                stopModelData.addListener({
                    id: 'EditRouteDetails',
                    refresh: debounce(() => {
                        stopModelData.getAll().then(stopsById => setAllStops(stopsById));
                    }, 1000)
                });

                routeModelData.addListener({
                    id: 'EditRouteDetails-routes', loaded: rs => setAllRoutes(rs), setterFn: setAllRoutes,
                    notifyUpdate: (updatedRoutes, local) => {
                        if (local) {
                            return;
                        }
                        const updatedRoute = find(updatedRoutes, ['routeId', id], 0);
                        if (updatedRoute) {
                            updateRoute({updatedRoute, setRoute: setRouteHistory, allStops: stopsById, allTransfers});
                        }
                    }
                });

                let route = initialRoute ? initialRoute : id !== '_new_' ? await routeModelData.get(apiKey, id) : BusRoute.create({
                    routeId: ulid(),
                    author: editor
                });
                if (id === '_new_' || !route.waypoints?.length) {
                    setEditingRoute(true);
                    setShowAllStops(true);
                    setNewRoute(true);
                    route.addTrip();
                }
                console.log('Loaded route: ', route);
                if (!route) {
                    history.push('/');
                }
                route.setTransfers(null, null);

                setPageTitle(route.routeName);
                setBreadcrumb(route.routeNumber);
                setSelectedTripId(route?.services?.[0].tripId);

                return {route, stopsById};
            } catch (e) {
                console.log(e);
            }
        }

        const loadedListener = {loaded: () => setIsLoaded(true)};
        routesModelExpiryService.addLoadedListener(loadedListener);

        onLoad().then(data => {
            try {
                const {route, stopsById} = data;

                setAllStops(stopsById);

                if (id && !route) {
                    console.log('no route');
                    return;
                }

                if (hasLogo(route)) {
                    console.log('Route logo: ', route.routeLogo);
                    setFileList([{
                        url: `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${route.routeLogo}?time=${Date.now()}`,
                        status: 'done'
                    }]);
                } else {
                    setFileList([{
                        url: 'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg',
                        status: 'done'
                    }]);
                }
                controls.reset(route.clone());

                if (route.routeId !== '_') {
                    initialRouteRef.current = route.clone();
                }
            } catch (e) {
                console.log(e);
            }
            ReactGA.send({hitType: 'pageview', page: '/', title: 'planable-services-details'});
        });

        return () => {
            stopModelData.removeListener('BusRouteDetails');
            routesModelExpiryService.removeLoadedListener(loadedListener);
            routeModelData.removeListener('BusRouteDetails-routes');
        };
        // eslint-disable-next-line
    }, []);//initialRoute, id, history, isAuthenticated, setRoute, apiKey, editor, setBreadcrumb, setPageTitle,
        // initialRouteRef, messageApi, setNewRoute, setAllTransfers, setAllRoutes, setSelectedTripId]);

    useEffect(() => {
        if (!allStops || !allTransfers || !allRoutes) {
            return;
        }
        setRoute(route => {
            if (!route) {
                return;
            }
            const r = new BusRoute({
                ...route,
                stops: route.stops.filter(s => Object.keys(allStops).indexOf(s.stopId) > -1)
            });
            r.setBaseStops(allStops, r.stopTimes || [], {removeStops: false});
            r.setTransfers(allTransfers, allRoutes);
            return r;
        }, {overwriteLastEntry: true});
    }, [setRoute, allStops, allTransfers, allRoutes]);

    useEffect(() => {
        if (!route) {
            return;
        }
        setValidationHelper(route.validate());
    }, [setValidationHelper, schedules, route, allStops]);

    const getSchedule = useCallback(scheduleId => {
        if (!scheduleId || !schedules) {
            return null;
        }
        return schedules[scheduleId] || null;
    }, [schedules]);

    function handleNewVehicleShift(vehicleShift) {
        setVehicleShifts(vehicleShifts => vehicleShifts.concat(vehicleShift).sort(shiftSort));
        // setVehicleShift(vehicleShift.shiftId)
        setRoute(route => {
            route.vehicleShift = vehicleShift.shiftId;
            return new BusRoute(route);
        });
    }

    function handleNewDriverShift(driverShift) {
        setDriverShifts(driverShifts => driverShifts.concat(driverShift).sort(shiftSort));
        // setDriverShift(driverShift.shiftId)
        setRoute(route => {
            route.driverShift = driverShift.shiftId;
            return new BusRoute(route);
        });
    }

    const handleStopClick = (stop) => {
        console.log(`Stop clicked: ${stop.stopId}`);
        setSelectedStop(stop);
    };

    const createNewStop = useCallback(async stop => {
        stop = await stopModelData.save(stop);
        setAllStops(allStops => {
            allStops[stop.stopId] = stop;
            return {...allStops};
        });
        setSelectedStop(stop);
    }, [setSelectedStop, setAllStops]);

    const handleRouteUpdate = useCallback((_route, calculateStartEnd = true) => {
        // setStops([..._route.stops])
        console.log('setting route2...', _route.stopTimes);
        setRouteHistory(route => {
            console.log(route, _route);
            route = new BusRoute({...route, ..._route});
            // route.stops = _route.stops
            // route.waypoints = _route.waypoints
            // route.stopTimes = _route.stopTimes
            if (calculateStartEnd) {
                route.calculateStartEnd({});
            }
            return route;
        });

        setUpdated(true);
        // setRoute(new BusRoute(_route));
    }, [setRouteHistory, setUpdated]);

    const cut = useCallback((startWpIdx, endWpIdx) => {
        if (startWpIdx >= endWpIdx) {
            return;
        }

        if (!window.confirm(`Are you sure you want to cut the route between the selected waypoints? This will also remove stops on the trimmed path.`)) {
            return;
        }

        console.log(`Cutting route.`);

        setRoute(route => {

            const _route = route.clone();
            console.log('wps before cut:', route.waypoints.length);
            _route.cut(startWpIdx, endWpIdx);
            console.log('wps after cut:', route.waypoints.length);

            console.log('Finish cut');
            const newRoute = new BusRoute(_route);
            console.log('wps after after cut:', newRoute.waypoints.length);
            // setStops([...route.stops]);
            newRoute.calculateStartEnd({stops: route.stops});
            return newRoute;
        });
        // handleRoutePathUpdate(newRoute.waypoints)
        // setBaseWaypoints([...route.waypoints])
    }, [setRoute]);

    const trim = useCallback((truncate, selectedStop) => {

        if (!window.confirm(`Are you sure you want to trim the route${truncate ? ` from ${selectedStop.stopName} to the end?` : ` from the start to ${selectedStop.stopName}?`}`)) {
            return;
        }

        console.log(`Trimming route. Truncate: ${truncate}. Selected marker: ${util.inspect(selectedStop)}`);

        if (selectedStop) {
            setRoute(route => {

                const _route = route.clone();
                console.log('wps before trim:', route.waypoints.length);
                _route.trim(selectedStop, truncate);
                console.log('wps after trim:', route.waypoints.length);

                console.log('Finish trim');
                const newRoute = new BusRoute(_route);
                console.log('wps after after trim:', newRoute.waypoints.length);
                // setStops([...route.stops]);
                newRoute.calculateStartEnd({stops: route.stops});
                return newRoute;
            });
            // handleRoutePathUpdate(newRoute.waypoints)
            // setBaseWaypoints([...route.waypoints])
        }
    }, [setRoute]);

    const getUpdatedRoute = useCallback((route, includeSchedule) => {

        const convertStopToStopTimes = (stops) => {
            return stops.map(({
                                  stopTimeId,
                                  stopId,
                                  distance,
                                  avgSpd,
                                  timingPoint,
                                  arriveSecs,
                                  departSecs,
                              }) => {
                return {
                    stopTimeId,
                    stopId,
                    distance,
                    avgSpd,
                    timingPoint,
                    arriveSecs,
                    departSecs,
                };
            });
        };

        route.services.forEach(trip => {
            trip.stopTimes = convertStopToStopTimes(trip.stopTimes);
        });

        const startTime = getDepartureTimeAsSecondsSinceMidnight(route, route.stops.filter(publicStopFilter)[0]);

        return {
            ...route,
            startTime,
            updatedAt: Date.now(),
            lastEditor: editor,
            schedule: includeSchedule ? getSchedule(route.scheduleId) : undefined,
            services: route.services.map(trip => {
                const baseTrip = {...trip};
                delete baseTrip.route;
                delete baseTrip.stops;
                delete baseTrip.schedules;
                delete baseTrip.startStopIdx;
                delete baseTrip.endStopIdx;
                delete baseTrip.shifts;
                return baseTrip;
            })
        };
    }, [editor, getSchedule]);

    const setTrip = useCallback((trip) => {
        setRoute(route => {
            route = route.clone();
            const tripIdx = route.services.findIndex(t => t.tripId === trip.tripId);
            route.services[tripIdx] = trip;
            route = new BusRoute(route);
            route.setBaseStops(allStops, cloneDeep(trip.stopTimes));
            // console.log(route.stops);
            return route;
        });
    }, [setRoute, allStops]);

    const handleReverse = useCallback(() => {
        // reverseRoute(route);
        // console.log(route.stops);
        // const _stops = route.stops.map(stop => {
        //     return {...stop}
        // })
        // setStops(_stops)
        console.log('setting route5...');

        // const newRoute = new BusRoute(route);
        // newRoute.calculateStartEnd({});
        // handleRoutePathUpdate(newRoute.waypoints)
        setRoute(route => {
            const _route = route.clone();
            reverseRoute(_route);
            _route.calculateStartEnd({});
            return new BusRoute(_route);
        });
    }, [setRoute]);

    const handleColourChange = useCallback((colour, hex) => {
        // setColour(colour)
        console.log('setting route colour...', colour, hex);
        setRoute(route => new BusRoute({...route, colour: hex}));
    }, [setRoute]);

    const addTrip = useCallback((route) => {

        const trip = route.addTrip();
        setRoute(route.clone());
        setSelectedTripId(trip);
        setUpdated(true);

    }, [setRoute, setSelectedTripId, setUpdated]);

    const removeTrip = useCallback((route, tripId) => {
        const tripIdx = route.services.findIndex(trip => trip.tripId === tripId);
        route.removeTrip(tripId);
        setSelectedTripId(route.services?.length === 0 ? null : tripIdx >= route.services?.length ? last(route.services) : route.services[tripIdx]);
        setRoute(route.clone());
        setUpdated(true);

    }, [setRoute, setSelectedTripId, setUpdated]);

    useEffect(() => {
        if (!showAllStops || !editingRoute) {
            setShowNewStop(false);
        }
    }, [showAllStops, editingRoute, setShowNewStop]);

    const buildToStop = useCallback(async (stop) => {
        if (stop) {
            if (stop.master) {
                stop = {...stop};
                delete stop.master;
                stop.stopId = stop.stopId.split('|')[1];
                stop.verified = 1;
                stop.duplicate = -1;
                await createNewStop(stop);
            }
            try {
                if (!(await route.addStop({...stop}, 'append', !!charter))) {
                    return messageApi.error('Could not append stop. It may be too far away from the route.', 5);
                }
                route.calculateStartEnd({});
                setSelectedStop(null);
                updateRoute(route);
            } catch (e) {
                console.log(e, e);
                messageApi.error('Could not append stop. Error: ' + e.toString(), 5);
            }
        }
    }, [route, updateRoute, setSelectedStop, messageApi, createNewStop]);


    return (
        <div className="BusRouteDetails">
            {isLoaded && schedules && route ? (
                <>
                    <div className="card-main map-holder-wrap no-pad d-flex">
                        <div className="map-holder" style={{width: '65%'}}>
                            <div className="MapPlaceholder">
                                <RouteMap route={route.clone()} colour={route.colour} charter={charter}
                                          allowComments={allowComments}
                                          setAllowComments={setAllowComments}
                                          showComments={showComments}
                                          setShowComments={setShowComments}
                                          focusPt={focusPt}
                                          setFocusPt={setFocusPt}
                                          tolerance={tolerance}
                                          setTolerance={setTolerance}
                                          editingRoute={editingRoute}
                                          setEditingRoute={setEditingRoute}
                                          showAllStops={showAllStops}
                                          setShowAllStops={setShowAllStops}
                                          allStops={allStops}
                                          allRoutes={allRoutes}
                                          setAllStops={setAllStops}
                                          createNewStop={createNewStop}
                                    // updateRoutePathFn={handleRoutePathUpdate}
                                          selectedTripId={selectedTripId}
                                          handleTrim={trim}
                                          handleCut={cut}
                                          selectedStop={selectedStop}
                                          setSelectedStop={setSelectedStop}
                                          zoomStop={zoomStop}
                                          setZoomStop={setZoomStop}
                                          focusStop={focusStop}
                                          setFocusStop={setFocusStop}
                                          deleteStop={(stop) => setDeletedStops(stops => {
                                              stops.push(stop);
                                              return stops;
                                          })}
                                          onHandleStopClick={handleStopClick}
                                          updateRouteFn={handleRouteUpdate}
                                          deselectStop={() => setSelectedStop(null)}
                                          focusTransfers={focusTransfers}/>
                            </div>
                        </div>
                        <div className="map-items" style={{width: '35%'}}>
                            <div className="ServiceRouteDetails">
                               <Row wrap={false}>
                                    <Col flex="auto">
                                        <RouteTitleEdit route={route} editable={true}
                                                        validationHelper={validationHelper}
                                                        setRoute={setRoute} fileList={fileList}
                                                        setFileList={setFileList}
                                                        handleLogoRemove={() => {
                                                            setRoute(route => new BusRoute({
                                                                ...route,
                                                                routeLogo: ''
                                                            }));
                                                        }}/>
                                    </Col>
                                   {!charter && <Col flex="none">
                                        <RouteVariantDetailsModal popPlacement={'left'}
                                                                  iconOnly={true}
                                                                  setRoute={setRoute}
                                                                  route={route}
                                                                  driverShifts={driverShifts}
                                                                  vehicleShifts={vehicleShifts}
                                                                  handleNewDriverShift={handleNewDriverShift}
                                                                  handleNewVehicleShift={handleNewVehicleShift}/>
                                        <Select options={ROUTE_STATUSES} defaultValue={-1}
                                                onChange={v => {
                                                    setRoute(route => new BusRoute({
                                                        ...route,
                                                        published: v,
                                                        approvedAt: v === 0 ? Date.now() : route.approvedAt,
                                                        approvedBy: v === 0 ? editor : route.approvedBy,
                                                        publishedAt: v === 1 ? Date.now() : route.publishedAt,
                                                        publishedBy: v === 1 ? editor : route.publishedBy
                                                    }));
                                                }} value={route.published}/>
                                    </Col>}
                                </Row>
                                <div
                                    className={`ServiceDetailsMenu`}>
                                    <Popover placement="left"
                                             title={<span>Choose route colour</span>}
                                             content={<div><p>Select a colour for this Route</p></div>}
                                             trigger="hover">
                                        <div onClick={handleColorPicker}
                                             className="ServiceColorPicker icon-button btn-filled btn-fade">
                                            <Color/>
                                            <span style={{background: route.colour}}></span>
                                            <ColorPicker value={route.colour} presets={presets}
                                                         onChange={handleColourChange} onOpenChange={() => {
                                                setshowColorPicker(false);
                                            }}/>
                                            {/* <Form.Control className="color-picker"
                                                                type="color"
                                                                list="presets"
                                                                value={route.colour}
                                                                onChange={event => handleColourChange(event.target.value)}
                                                    />
                                                    <datalist id="presets">
                                                        <option value="#007bff"></option>
                                                        <option value="#1b65ac"></option>
                                                        <option value="#00aeef"></option>
                                                        <option value="#5567a9"></option>
                                                        <option value="#812990"></option>
                                                        <option value="#cb0b0b"></option>
                                                        <option value="#a02125"></option>
                                                        <option value="#c90b86"></option>
                                                        <option value="#e6b00e"></option>
                                                        <option value="#f57d20"></option>
                                                        <option value="#008a5d"></option>
                                                        <option value="#00b26a"></option>
                                                        <option value="#005a23"></option>
                                                        <option value="#76a33a"></option>
                                                        <option value="#515553"></option>
                                                        <option value="#"></option>
                                                        <option value="#"></option>
                                                    </datalist> */}
                                        </div>
                                    </Popover>
                                    <Popover placement="left"
                                             title={<span>Reverse direction</span>}
                                             content={<div><p>Reverse direction of route variant</p></div>}
                                             trigger="hover">
                                        <Button
                                            className="icon-button btn-filled btn-fade"
                                            onClick={() => handleReverse(route)}><SwitchIcon width={14}
                                                                                             height={14}/></Button>
                                    </Popover>
                                    <HistoryModal route={route} popPlacement={'left'}/>
                                </div>

                                {/* <div className="row"> */}
                                {/* <div className="col-4 d-flex align-items-center"
                                                style={{fontSize: "0.9rem", paddingTop: "0px"}}>
                                                <div className="row">
                                                    <div className="col-lg-12 d-flex align-items-center">
                                                        <div className="d-flex info-text-wrap w-label mt-0">
                                                            <div className="icon-info-text">
                                                                <Services/>
                                                                <div>
                                                                    <span>Distance</span>{route?.waypoints && toKmMs(last(route.waypoints)?.distance)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                {/* <div className="col-8 d-flex align-items-center justify-content-end">
                                                {route.scheduleId &&
                                                    <div className="mr-2">
                                                        <PreviewModal route={getUpdatedRoute(route, true)}/>
                                                    </div>
                                                }
                                            </div> */}
                                {/* </div> */}
                            </div>
                            <div className="ServiceTripDetails">
                                <div className="row h-100">
                                    <div className="col-lg-12 h-100"
                                         style={{padding: '0 15px', overflow: 'hidden'}}>
                                        <Trips allStops={allStops} newRoute={newRoute}
                                            // onTripChange={tripId => {
                                            //     route?.services?.length && setSelectedTrip(trip => {
                                            //         return trip?.tripId !== tripId ? find(route.services, trip => trip.tripId === tripId) : trip
                                            //     })
                                            // }}
                                               charter={charter}
                                               route={route.clone()}
                                               operator={operator}
                                               setRoute={setRoute}
                                               selectedTripId={selectedTripId}
                                               setSelectedTripId={setSelectedTripId}
                                               showAllStops={showAllStops}
                                               editingRoute={editingRoute}
                                               showSeconds={showSeconds}
                                               setShowSeconds={setShowSeconds}
                                               cumulativeUpdate={cumulativeUpdate}
                                               setCumulativeUpdate={setCumulativeUpdate}
                                               baseStops={cloneDeep(route.stops)}
                                               allSchedules={schedules}
                                               schedules={sortedSchedules}
                                               setFocusStop={setFocusStop}
                                               selectedStop={selectedStop}
                                               setSelectedStop={stop => {
                                                   setSelectedStop(stop);
                                                   setZoomStop(stop);
                                               }}
                                               validationHelper={validationHelper}
                                               setFocusTransfers={setFocusTransfers}
                                               focusTransfers={focusTransfers}
                                               showNewStop={showNewStop}
                                               setShowNewStop={setShowNewStop}
                                               buildToStop={buildToStop}
                                               setZoomStop={setZoomStop}
                                        />
                                    </div>
                                </div>
                                <div className="ServiceDetailsMenu">
                                    {/*<div className="col-lg-6">*/}
                                    {/*    <Button*/}
                                    {/*        block*/}
                                    {/*        size="sm"*/}
                                    {/*        variant="warning"*/}
                                    {/*        onClick={handleReset}>Reset Times</Button>*/}
                                    {/*</div>*/}
                                    {!charter && <Popover placement="left"
                                             title={<span>Add timetable</span>}
                                             content={<div><p>Add service timetable to route variant</p></div>}
                                             trigger="hover">
                                        <Button className="icon-button btn-filled btn-fade"
                                                onClick={() => addTrip(route)}><Plus/></Button>
                                    </Popover>}
                                    <TripDetailsModal popPlacement={'left'} iconOnly={true} route={route}
                                                      trip={route.services ? find(route.services, {tripId: selectedTripId}) : null}
                                                      operator={operator} setTrip={setTrip}
                                                      allStops={allStops} schedules={schedules}
                                                      validationHelper={validationHelper}
                                                      setRoute={setRoute} setUpdated={setUpdated}
                                    />
                                    <Popover placement="left"
                                             title={<span>Show seconds</span>}
                                             content={<div><p>Show seconds in arrival and departure time
                                                 fields</p></div>}
                                             trigger="hover">
                                        <div
                                            className={`icon-switch icon-button btn-filled btn-fade ${showSeconds ? 'active' : ''}`}>
                                            <Timer
                                                className={showSeconds ? 'active' : ''} width={14}
                                                height={16}/><Switch
                                            size="small" onChange={val => {
                                            setShowSeconds(val);
                                        }} checked={showSeconds} style={{height: '30px'}}/></div>
                                    </Popover>
                                    <Popover placement="left"
                                             title={<span>Bulk update times cumulatively</span>}
                                             content={<div><p>Update all arrival and departure times cumulatively</p>
                                             </div>}
                                             trigger="hover">
                                        <div
                                            className={`icon-switch icon-button btn-filled btn-fade ${cumulativeUpdate ? 'active' : ''}`}>
                                            <OrderedListOutlined
                                                className={cumulativeUpdate ? 'active' : ''} width={14}
                                                height={16}/><Switch
                                            size="small" onChange={setCumulativeUpdate} checked={cumulativeUpdate}
                                            style={{height: '30px'}}/></div>
                                    </Popover>
                                    <Popover placement="left"
                                             title={<span>Add stop point</span>}
                                             content={<div><p>Add stop point to timetable and automatically
                                                 build route path</p></div>}
                                             trigger="hover">
                                        <div
                                            className={`icon-switch icon-button btn-filled btn-fade ${showNewStop ? 'active' : ''}`}>
                                            <StopTime
                                                width={16} height={18}
                                                className={showNewStop ? 'active' : ''}/><Switch
                                            size="small" onChange={val => {
                                            if (val) {
                                                setEditingRoute(true);
                                                setShowAllStops(true);
                                            }
                                            setShowNewStop(val);
                                        }} checked={showNewStop} style={{height: '30px'}}/></div>
                                    </Popover>
                                    <TransfersModal disabled={!selectedStop} width={'70%'} iconOnly={true}
                                                    popPlacement={'left'}
                                                    point={selectedStop ? allStops[selectedStop.stopId] : null}
                                                    routeId={route.routeId}
                                                    tripId={selectedTripId}
                                                    stopTimeId={selectedStop?.stopTimeId}/>
                                    <Popover placement="left"
                                             title={<span>Delete timetable</span>}
                                             content={<div><p>Delete selected service timetable starting
                                                 at {route.services && find(route.services, {tripId: selectedTripId})?.getStartTime(showSeconds)}</p>
                                             </div>}
                                             trigger="hover">
                                        <Button
                                            className="icon-button btn-filled btn-fade"
                                            onClick={() => removeTrip(route, selectedTripId)}><Trash
                                            width={12} height={14}/></Button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : <LoadMessage message={`Loading service...`} size={'lg'}/>}
        </div>
    );
}

export default React.memo(EditRouteDetails);

