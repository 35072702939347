import React, {useEffect, useState} from "react";
import {stopModelData} from "../services/ModelService";
import {keyBy, values} from "lodash";

const useAllStops = () => {
    const [allStops, setAllStops] = useState(null);
    const [allDepots, setAllDepots] = useState(null);

    useEffect(() => {
        const stopsListener = stopModelData.addListener({
            setterFn: setAllStops, loaded: stops => {
                setAllStops(stops);
            }
        });

        return () => {
            if (stopsListener)
                stopModelData.removeListener(stopsListener)
        }
    }, [])

    useEffect(() => {
        if (allStops) {
            setAllDepots(keyBy(values(allStops).filter(stop => stop.stopType === 'depot'), 'stopId'));
        }
    }, [allStops, setAllDepots]);

    return {
        allStops,
        allDepots
    };
}

export default useAllStops;

