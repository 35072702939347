import dayjs from '../dayjs';
import {find, keyBy} from 'lodash/collection';
import {cloneDeep, isEqual} from 'lodash/lang';
import {getDepartureTimeAsSecondsSinceMidnight, getDistanceInMetres} from '../libs/routes-lib';
import {ulid} from 'ulid';
import {findIndex, last} from 'lodash/array';
import {getCachedPath, getDirections} from '../libs/pathLib';
import {BusRoute, Trip} from './busRoute';
import {getSecondsSinceMidnightAsDayjs, toHrsMinsSecs, toTime} from '../libs/formatLib';
import {pickBy, values} from 'lodash/object';
import {chunk} from 'lodash';
import {eachSeries} from 'async';
import config from '../config';
import {CharterRouteRun, Deadrun} from './deadrun';
import {mapSeries} from 'async/index';
import {secsSinceMidnightToDayjs} from './timeFilter';

export class Service {
    static build({routeId, tripId, route, allStops}) {
        if (!route) {
            throw new Error('No route %s with id ', route.routeNumber, routeId);
        }
        const service = cloneDeep(route);
        const trip = find(route.services, trip => trip.tripId === tripId);
        if (!trip) {
            throw new Error('No trip on route %s with id ', route.routeNumber, tripId);
        }
        service.setBaseStops(allStops, trip.stopTimes);
        return service;
    }

}

export const combineDeadruns = deadruns => {
    return deadruns.reduce((deadrun, next) => {
        if (!next?.waypoints) {
            return deadrun;
        }
        if (!deadrun?.waypoints) {
            return next;
        }
        deadrun.waypoints = deadrun.waypoints.concat(next.waypoints);
        deadrun.duration += next.duration;
        deadrun.totalDuration += next.totalDuration;
        deadrun.idleTime += next.idleTime;
        deadrun.distance += next.distance;
        deadrun.endLocation = next.endLocation;
        return deadrun;
    }, null);
};

/**
 *
 * @param deadruns
 * @param sortFn to sort the deadruns. If not provided, the deadrun with the shortest distance is returned
 * @returns deadrun based on the sortFn or distance
 */
const calculateBestDeadrun = (deadruns, sortFn) => deadruns.reduce((best, deadrun) => {
    if (!best || (sortFn && sortFn(deadrun, best)) || deadrun?.distance < best.distance) {
        return deadrun;
    }
    return best;
}, null);

/**
 * Returns a deadrun if it is possible to travel from startLocation to endLocation in the allowed time frame
 * @param startLocation
 * @param endLocation
 * @param totalDuration totalDuration duration to perform this deadrun (including idle time)
 * @returns {Promise<{totalDuration: number, duration: number, distance: number, startLocation, idleTime: number, waypoints, endLocation}>}
 */
const getDeadrun = async (startLocation, endLocation, totalDuration = 0) => {
    if (!startLocation || !endLocation) {
        return {
            totalDuration, // Total time in secs between actual end of actual start of next job
            idleTime: 0, // Total time the driver/vehicle is sitting idle
            duration: 0, // The duration of the deadrun in seconds (when vehicle is moving)
            distance: 0, // The distance of the deadrun
            waypoints: [],
            startLocation, endLocation,
        };
    }
    const {waypoints, duration, distance} = await getCachedPath('bus', startLocation, endLocation);
    totalDuration = totalDuration > duration ? totalDuration : duration;
    const idleTime = totalDuration - duration;
    if (idleTime > 0) {
        return {
            totalDuration, // Total time in secs between actual end of actual start of next job
            idleTime, // Total time the driver/vehicle is sitting idle
            duration, // The duration of the deadrun in seconds (when vehicle is moving)
            distance, // The distance of the deadrun
            waypoints,
            startLocation, endLocation,
        };
    }
};

export class ShiftBat {

    static fromCharterItinerary = async ({charter, depot, deadrunModelData, allStops}) => {
        const {itinerary} = charter;
        let rows = [{type: ShiftBatRowType.noteTimed, title: 'Start Depot'}];
        if (depot) {
            rows.push({
                type: ShiftBatRowType.stop,
                time: 0,
                description: 'Depart Depot',
                stopId: depot.stopId,
                stop: depot
            });
        }
        if (itinerary?.length) {

            if (depot) {
                const dead = new BusRoute({
                    routeId: ulid(),
                    routeName: `Special - ${depot.stopName} to ${itinerary[0].stops[0].stopName}`
                });
                await dead.addStop(depot, 'append');
                await dead.addStop(itinerary[0].stops[0], 'append');
                // dead.setBaseStops(allStops);
                const duration = dead.duration;
                const distance = dead.distance;

                const deadRow = new ShiftBatDeadRow({
                    type: ShiftBatRowType.dead,
                    time: itinerary[0].stops[0].arriveSecs - duration,
                    route: dead,
                    start: depot,
                    end: itinerary[0].stops[0],
                    distance,
                    duration,
                });
                rows.push(deadRow);
                // await deadrunModelData.save(dead);
                rows[0].time = deadRow.time - 600;
                rows[1].time = deadRow.time;
            }
            itinerary.forEach((route, i) => {
                rows.push({
                    type: ShiftBatRowType.service,
                    route,
                    trip: route.services[0],
                    title: route.routeName,
                    description: route.routeDetails,
                });
                if (i < itinerary.length - 1) {
                    const duration = itinerary[i + 1].stops[0].arriveSecs - last(route.stops).departSecs;
                    const breakRow = new ShiftBatBreakRow({
                        type: ShiftBatRowType.break,
                        time: last(route.stops).departSecs,
                        title: `Break for ${toHrsMinsSecs(duration, false, true)}`,
                        duration
                    });
                    rows.push(breakRow);
                }
            });

            if (depot) {
                const dead = new BusRoute({
                    routeId: ulid(),
                    routeName: `Special - ${last(last(itinerary).stops).stopName} to ${depot.stopName}`
                });
                await dead.addStop(last(last(itinerary).stops), 'append');
                await dead.addStop(depot, 'append');
                dead.setBaseStops(allStops);
                const duration = dead.duration;
                const distance = dead.distance;
                const deadRow = new ShiftBatDeadRow({
                    type: ShiftBatRowType.dead,
                    time: last(last(itinerary).stops).arriveSecs,
                    start: last(last(itinerary).stops),
                    end: depot,
                    route: dead,
                    distance,
                    duration,
                });
                rows.push(deadRow);
                // await deadrunModelData.save(dead);
            }
        }
        if (depot) {
            rows.push({
                type: ShiftBatRowType.stop,
                description: 'Arrive Depot',
                time: last(last(itinerary).stopTimes).arriveSecs + rows[rows.length - 1].route.duration,
                stopId: depot.stopId,
                stop: depot
            });
        }
        // {type: ShiftBatRowType.stop, time: charter.startTime - 3 * 60, description: 'Depart Depot', stopId: depotId},
        // {type: ShiftBatRowType.stop, time: charter.endTime + 3 * 60, description: 'Arrive Depot', stopId: depotId},
        rows.push({type: ShiftBatRowType.noteTimed, time: last(rows).time + 600, title: 'End Depot'});
        // if (!depotId) {
        //     rows.splice(1, 2);
        // }
        const sb = new ShiftBat({
            shiftBatNumber: 'CHA',
            shiftBatName: charter.name,
            rows,
            charter: true,
            effectiveDate: charter.startDate
        });
        // await sb.updateRows({deadrunModelData})
        // await Promise.all(sb.rows.filter(row => row.updateRow).map(async row => {
        //     await row.updateRow({deadrunModelData, allStops});
        // }));
        return sb;
    };

    static fromCharterDetails = async ({charter, depot, deadrunModelData, roundtrip}) => {
        let rows = [{type: ShiftBatRowType.noteTimed, title: 'Start Depot'}];
        if (depot) {
            rows.push({
                type: ShiftBatRowType.stop,
                time: charter.startTime - 3 * 60,
                description: 'Depart Depot',
                stopId: depot.stopId,
                stop: depot
            });
        }
        if (charter.destinations?.length) {

            if (depot) {
                // let distance = getDistanceInMetres(depot, charter.destinations[0]);
                // let duration = distance / 14;
                const dead = new BusRoute({
                    routeId: ulid(),
                    routeName: `Special - ${depot.stopName} to ${charter.destinations[0].stopName}`
                });
                await dead.addStop(depot, 'append');
                await dead.addStop(charter.destinations[0], 'append');
                const duration = dead.duration;
                const distance = dead.distance;

                rows.push(new ShiftBatDeadRow({
                    type: ShiftBatRowType.dead,
                    time: charter.destinations[0].time - duration,
                    route: dead,
                    start: depot,
                    end: charter.destinations[0],
                    distance,
                    duration,
                }));
            }
            const routeId = ulid();
            const route = new BusRoute({
                routeId,
                routeNumber: 'CHA',
                routeName: charter.name,
                services: [new Trip({routeId, stopTimes: [], stops: []})]
            });

            await Promise.all(charter.destinations.map(async (destination, i) => {
                await route.addStop(destination, 'append');
                route.stopTimes[i].arriveSecs = destination.time;
                route.stopTimes[i].departSecs = destination.time;
                route.stops[i].arriveSecs = destination.time;
                route.stops[i].departSecs = destination.time;
                route.services[0].stopTimes[i].arriveSecs = destination.time;
                route.services[0].stopTimes[i].departSecs = destination.time;
                route.services[0].stops[i].arriveSecs = destination.time;
                route.services[0].stops[i].departSecs = destination.time;
                // if (i > 0) {
                //     const distance = getDistanceInMetres(charter.destinations[i - 1], destination);
                //     const duration = distance / 14;
                //
                //     rows.push({
                //         type: ShiftBatRowType.dead,
                //         start: charter.destinations[i - 1],
                //         end: destination,
                //         distance,
                //         duration,
                //     });
                // }
                // rows.push({
                //     type: ShiftBatRowType.stop,
                //     stop: {...destination, stopName: destination.name}
                // });

            }));

            // if (roundtrip) {
            //
            //     await Promise.all(charter.destinations.reverse().map(async (destination, i) => {
            //         await route.addStop(destination, 'append');
            //         route.stopTimes[i].arriveSecs = destination.time + 3600;
            //         route.stopTimes[i].departSecs = destination.time + 3600;
            //         route.stops[i].arriveSecs = destination.time + 3600;
            //         route.stops[i].departSecs = destination.time;
            //         route.services[0].stopTimes[i].arriveSecs = destination.time;
            //         route.services[0].stopTimes[i].departSecs = destination.time;
            //         route.services[0].stops[i].arriveSecs = destination.time;
            //         route.services[0].stops[i].departSecs = destination.time;
            //         // if (i > 0) {
            //         //     const distance = getDistanceInMetres(charter.destinations[i - 1], destination);
            //         //     const duration = distance / 14;
            //         //
            //         //     rows.push({
            //         //         type: ShiftBatRowType.dead,
            //         //         start: charter.destinations[i - 1],
            //         //         end: destination,
            //         //         distance,
            //         //         duration,
            //         //     });
            //         // }
            //         // rows.push({
            //         //     type: ShiftBatRowType.stop,
            //         //     stop: {...destination, stopName: destination.name}
            //         // });
            //
            //     }));
            //
            // }

            rows.push({
                type: ShiftBatRowType.charter,
                route,
                trip: route.services[0],
                title: charter.name,
                description: charter.description,
            });

            if (depot) {
                // let distance = getDistanceInMetres(last(charter.destinations), depot);
                // let duration = distance / 14;

                const dead = new BusRoute({
                    routeId: ulid(),
                    routeName: `Special - ${charter.destinations[0].stopName} to ${depot.stopName}`
                });
                await dead.addStop(last(charter.destinations), 'append');
                await dead.addStop(depot, 'append');
                const duration = dead.duration;
                const distance = dead.distance;
                rows.push({
                    type: ShiftBatRowType.dead,
                    time: last(charter.destinations).time + duration,
                    end: depot,
                    start: last(charter.destinations),
                    distance,
                    duration,
                });
            }
        }
        if (depot) {
            rows.push({
                type: ShiftBatRowType.stop,
                description: 'Arrive Depot',
                stopId: depot.stopId,
                stop: depot
            });
        }
        // {type: ShiftBatRowType.stop, time: charter.startTime - 3 * 60, description: 'Depart Depot', stopId: depotId},
        // {type: ShiftBatRowType.stop, time: charter.endTime + 3 * 60, description: 'Arrive Depot', stopId: depotId},
        rows.push({type: ShiftBatRowType.noteTimed, time: last(rows).time + 600, title: 'End Depot'});
        // if (!depotId) {
        //     rows.splice(1, 2);
        // }
        const sb = new ShiftBat({
            shiftBatNumber: 'CHA',
            shiftBatName: charter.name,
            rows,
            charter: true,
            effectiveDate: charter.startDate
        });
        // await sb.updateRows({deadrunModelData})
        await Promise.all(sb.rows.filter(row => row.updateRow).map(async row => {
            await row.updateRow({deadrunModelData, allStops: {}});
        }));
        return sb;
    };

    constructor(props) {
        this.shiftBatId = ulid();
        this.shiftBatNumber = 'NEW';
        this.shiftBatName = '';
        this.shiftBatDetails = '';
        this.shiftBatColour = '#007bff';
        this.shiftBatLogo = null;
        this.createdAt = dayjs();
        this.effectiveDate = dayjs();
        this.scheduleIds = [];
        this.rows = []; // [ShiftBatRow]
        this.forceUpdate = true;
        this.charter = false;
        Object.assign(this, props);
        // if (!this.rows.length) {
        //     this.rows = [new ShiftBatRow({title: 'First thing', description: 'Your description...', time: 6 * 60 * 60})]
        // }

        if (this.rows.length) {
            this.rows = this.rows.map(row => this.createRow(row));
        }
        if (typeof this.createdAt === 'string') {
            this.createdAt = dayjs(this.createdAt, 'DD/MM/YYYY');
        }
        if (typeof this.updatedAt === 'string') {
            this.updatedAt = dayjs(this.updatedAt, 'DD/MM/YYYY');
        }
        if (typeof this.effectiveDate === 'string') {
            this.effectiveDate = dayjs(this.effectiveDate, 'DD/MM/YYYY');
        }
    }

    getName() {
        return this.shiftBatNumber;
    }

    clone(field, value) {
        const newShiftBat = new ShiftBat({
            ...this,
            rows: this.rows.map(r => r.clone()),
        });
        if (field?.length) {
            newShiftBat[field] = value;
        }
        return newShiftBat;
    }


    isValid(args) {
        // valid if there is at least one service row, and all rows are valid
        return this.rows?.length && this.getShiftTime() > 0 && find(this.rows, {type: ShiftBatRowType.service}) && this.rows.every(r => r.isValid(args));
    }

    getStartLocation() {
        return this.rows.filter(row => row.getStartLocation)?.[0]?.getStartLocation();
    }

    getEndLocation() {
        return last(this.rows.filter(row => row.getEndLocation))?.getEndLocation();
    }

    getTrailingDeadrun() {
        for (let i = this.rows.length - 1; i >= 0; i--) {
            const row = this.rows[i];
            if (row.type === ShiftBatRowType.service) {
                return;
            } else if (row.type === ShiftBatRowType.dead) {
                return row.route;
            }
        }
    }

    getLeadingDeadrun() {
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            if (row.type === ShiftBatRowType.service) {
                return;
            } else if (row.type === ShiftBatRowType.dead) {
                return row.route;
            }
        }
    }

    getPrevTimedRow(idx) {
        if (idx === 0) {
            return;
        }
        for (let i = idx - 1; i >= 0; i--) {
            const row = this.rows[i];
            if (Number.isFinite(row.time) && row.time > -1 && [ShiftBatRowType.service, ShiftBatRowType.stop,
                ShiftBatRowType.noteTimed, ShiftBatRowType.dead, ShiftBatRowType.break, ShiftBatRowType.breakMeal,
                ShiftBatRowType.breakBroken].includes(row.type)) {
                return row;
            }
        }
    }

    getFirstStopRow() {
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            if (row.type === ShiftBatRowType.service) {
                return;
            } else if (row.type === ShiftBatRowType.stop) {
                return row;
            }
        }
    }

    getLastStopRow() {
        for (let i = this.rows.length - 1; i >= 0; i--) {
            const row = this.rows[i];
            if (row.type === ShiftBatRowType.service) {
                return;
            } else if (row.type === ShiftBatRowType.stop) {
                return row;
            }
        }
    }

    getStartTime(opts = {date: dayjs(), asDayJs: false}) {
        const {date, asDayJs} = opts;
        const firstTimedRow = this.rows.filter(r => r.getTime() >= 0)[0];
        const time = firstTimedRow?.getTime() || 0;
        if (asDayJs) {
            return secsSinceMidnightToDayjs(time, date);
        }
        return time;
    }

    getEndTime(opts = {date: dayjs(), asDayJs: false}) {
        const {date, asDayJs} = opts;
        const lastTimedRow = last(this.rows.filter(r => r.getTime() >= 0));
        const time = lastTimedRow?.getEndTime() || 0 + lastTimedRow?.duration || 0;
        if (asDayJs) {
            return secsSinceMidnightToDayjs(time, date);
        }
        return time;
    }

    getActualStartTime(opts = {date: dayjs(), asDayJs: false}) {
        const {date, asDayJs} = opts;
        const time = this.rows.filter(row => [ShiftBatRowType.service].includes(row.type))?.[0]?.getTime() || 0;
        if (asDayJs) {
            return secsSinceMidnightToDayjs(time, date);
        }
        return time;
    }

    getActualEndTime(opts = {date: dayjs(), asDayJs: false}) {
        const {date, asDayJs} = opts;
        const lastServiceRow = last(this.rows.filter(row => [ShiftBatRowType.service].includes(row.type)))
        const time = lastServiceRow?.getEndTime() || 0 | lastServiceRow?.duration || 0;
        if (asDayJs) {
            return secsSinceMidnightToDayjs(time, date);
        }
        return time;
    }

    getActualStartLocation() {
        return this.rows.filter(row => [ShiftBatRowType.service].includes(row.type))?.[0]?.getEndLocation();
    }

    getActualEndLocation() {
        return last(this.rows.filter(row => [ShiftBatRowType.service].includes(row.type)))?.getEndLocation();
    }

    isRunning(date = dayjs(), schedulesById) {
        return this.rows.filter(r => r.type === ShiftBatRowType.service)?.some(r => {
            return r?.trip?.isRunning(date, schedulesById);
        });
    }

    getNextRunDate(date = dayjs(), schedulesById) {
        return this.rows.filter(r => r.type === ShiftBatRowType.service)?.[0]?.route?.getNextRunDate(date, schedulesById);
    }

    /**
     * Returns all variations of deadruns between this job and otherJob.
     * Deadrun from this job's end location to otherJob's start location
     * Deadrun from this job's actual end location to otherJob's start location
     * Deadrun from this job's end location to otherJob's actual start location
     * Deadrun from this job's actual end location to otherJob's actual start location
     * @param otherDuty
     */
    async getPossibleDeadruns(otherDuty) {
        if (!otherDuty) {
            throw new Error('Missing other duty');
        }
        let endToStart = await getDeadrun(this.getEndLocation(), otherDuty.getStartLocation(), otherDuty.getStartTime({asDayJs: true}).diff(this.getEndTime({asDayJs: true}), 's'));
        endToStart = combineDeadruns([this.getTrailingDeadrun(), endToStart, otherDuty.getLeadingDeadrun()]);
        let endToActualStart = await getDeadrun(this.getEndLocation(), otherDuty.getActualEndLocation(), otherDuty.getActualStartTime({asDayJs: true}).diff(this.getEndTime({asDayJs: true}), 's'));
        endToActualStart = combineDeadruns([this.getTrailingDeadrun(), endToActualStart]);
        let actualEndToStart = await getDeadrun(this.getActualEndLocation(), otherDuty.getStartLocation(), otherDuty.getStartTime({asDayJs: true}).diff(this.getEndTime({asDayJs: true}), 's'));
        actualEndToStart = combineDeadruns([actualEndToStart, otherDuty.getLeadingDeadrun()]);
        let actualEndToActualStart = await getDeadrun(this.getActualEndLocation(), otherDuty.getActualStartLocation(), otherDuty.getActualStartTime({asDayJs: true}).diff(this.getActualEndTime({asDayJs: true}), 's'));
        return {
            endToStart,
            endToActualStart,
            actualEndToStart,
            actualEndToActualStart,
        };
    }

    async getBestDeadrun(otherDuty, untouchedDeadrun, deadrunSortFn) {
        const deadruns = await this.getPossibleDeadruns(otherDuty);
        return calculateBestDeadrun(Object.values(deadruns).filter(deadrun => !!deadrun).concat(untouchedDeadrun), deadrunSortFn);
    }

    getShiftTime() {
        let lastRow;
        for (let i = this.rows.length - 1; i >= 0; i--) {
            if (this.rows[i].getEndTime() >= 0) {
                lastRow = this.rows[i];
                break;
            }
        }
        let firstRow;
        for (let i = 0; i < this.rows.length; i++) {
            if (this.rows[i].getTime() >= 0) {
                firstRow = this.rows[i];
                break;
            }
        }
        const excludedBreakTime = this.rows
            .filter(row => [ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken]
                .includes(row.type))
            .reduce((p, c) => p + (c.duration || 0), 0);
        return (lastRow?.getEndTime() || 0) - (firstRow?.getTime() || 0) - excludedBreakTime;
    }

    getShiftDistance() {
        return this.rows.reduce((p, c) => p + c.getDistance(), 0);
    }

    createRow(row, modelData) {
        switch (row.type) {
            case ShiftBatRowType.service:
                row = new ShiftBatServiceRow(row);
                break;
            case ShiftBatRowType.charter:
                row = new ShiftBatCharterRow(row);
                break;
            case ShiftBatRowType.stop:
                row = new ShiftBatStopRow(row);
                break;
            case ShiftBatRowType.break:
            case ShiftBatRowType.breakMeal:
            case ShiftBatRowType.breakBroken:
                row = new ShiftBatBreakRow(row);
                break;
            case ShiftBatRowType.dead:
                row = new ShiftBatDeadRow(row);
                break;
            case ShiftBatRowType.charter:
                row = new ShiftBatCharterRouteRow(row);
                break;
            case ShiftBatRowType.note:
            case ShiftBatRowType.noteTimed:
                row = new ShiftBatNoteRow(row);
                break;
            case ShiftBatRowType.template:
                row = new ShiftBatTemplateRow(row, modelData);
                break;
            default:
                row = new ShiftBatInvalidRow(row);
        }
        row.shiftBat = this;
        return row;
    }

    updateAllRows() {
        this.rows.forEach(row => row.loaded = false);
    }


    async updateRows({apiKey, allStops, allTransfers, allRoutes, deadrunModelData, opts = {}}) {

        console.log('Updating rows...');

        // eslint-disable-next-line
        let prevRow, nextRow;
        for (let i = 0; i < this.rows.length; i++) {
            if (i > 0) {
                // eslint-disable-next-line
                prevRow = this.rows[i - 1];
            }
            let row = this.rows[i];
            if (i < this.rows.length - 1) {
                // eslint-disable-next-line
                nextRow = this.rows[i + 1];
            }

            if (i > 0 && [ShiftBatRowType.service, ShiftBatRowType.stop].includes(row.type)) {
                if (this.checkPrevLoc(row, i)) {
                    // row.time = -1
                    i++;
                    // if([ShiftBatRowType.stop].includes(row.type))
                    // row.time = -1 // reset time so that it can be calculated
                }
            }

            // if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevRow.type)) {
            //     prevLocRow = true
            // }

            // if (prevRow && row.time <= 0) {
            //     row.time = prevRow.time + (prevRow.duration || 0);
            // }

            // if (row.type === ShiftBatRowType.dead) {
            //     if (!prevRow || !nextRow || ![ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevRow.type) || ![ShiftBatRowType.service, ShiftBatRowType.stop].includes(nextRow.type)) {
            //         this.rows.splice(i, 1);
            //         i--;
            //     }
            // }

        }

        await Promise.all(this.rows.filter(row => row.updateRow).map(async row => row.updateRow({
            apiKey,
            allStops,
            allTransfers,
            allRoutes,
            deadrunModelData,
            opts
        })));

        // let prevTimedRow = null;
        // for (let i = 1; i < this.rows.length; i++) {
        //     let row = this.rows[i];
        //     prevTimedRow = this.getPrevTimedRow(i)
        //     if (!prevTimedRow) {
        //         continue
        //     }
        //
        //     if (!row.manualTime && ![ShiftBatRowType.service].includes(row.type)) {
        //         row.time = prevTimedRow.time + (prevTimedRow.duration || 0);
        //     }
        //
        // }

        return this;
    }

    addRow(row, idx = 0) {
        return this.putRow(idx, row);
    }

    deleteNextDeadRow(idx) {
        for (let i = idx + 1; i < this.rows.length; i++) {
            const row = this.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(row.type)) {
                return;
            }
            if (row.type === ShiftBatRowType.dead) {
                this.rows.splice(i, 1);
                return;
            }
        }
    }

    deletePrevDeadRow(idx) {
        for (let i = idx - 1; i >= 0; i--) {
            const row = this.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(row.type)) {
                return;
            }
            if (row.type === ShiftBatRowType.dead) {
                this.rows.splice(i, 1);
                return;
            }
        }
    }

    replaceRow(row, idx = 0) {
        let replace = false;
        let existingIdx = this.rows.findIndex(r => row.id === r.id);
        if (existingIdx > -1) {
            replace = true;
            idx = existingIdx;
        }
        // const currentRow = this.rows[idx]
        row = this.putRow(idx, row, replace);
        // Check for dead runs on either side. Delete so they will be rebuilt.
        if (row && [ShiftBatRowType.service, ShiftBatRowType.stop, ShiftBatRowType.noteTimed].includes(row.type)) {// && row.locationUpdated(currentRow)) {
            this.deleteNextDeadRow(idx);
            this.deletePrevDeadRow(idx);
            // let prevRow, nextRow;
            // if (idx > 0) {
            //     prevRow = this.rows[idx - 1];
            // }
            // if (idx < this.rows.length - 1) {
            //     nextRow = this.rows[idx + 1];
            // }
            //
            // if (nextRow?.type === ShiftBatRowType.dead && !nextRow.routeEdited) {
            //     this.rows.splice(idx + 1, 1);
            // }
            // if (prevRow?.type === ShiftBatRowType.dead && !prevRow.routeEdited) {
            //     this.rows.splice(idx - 1, 1);
            // }
            // } else if (row.type === ShiftBatRowType.dead) {
            //     this.rows[idx + 1].time = -1;
        }
        return row;
    }

    putRow(idx, row, replace) {
        row = this.createRow(row);
        const existingRow = this.rows[idx];
        if (isEqual(row, existingRow)) {
            return existingRow;
        }
        this.rows.splice(idx, replace ? 1 : 0, row);

        // if (row.time === -1 && row.type !== ShiftBatRowType.service) {
        //     console.log('Updating time on row')
        //     let prevRow = idx > 0 ? this.rows[idx - 1] : null;
        //     if (prevRow) {
        //         row.time = prevRow.time + (prevRow.duration || 0);
        //     }
        // }
        // if (existingRow && existingRow.time > -1 && row.time > -1 && row.time !== existingRow.time) {
        //     row.manualTime = true;
        // }

        return row;
    }

    removeRow(row) {
        const idx = this.rows.findIndex(r => row.id === r.id);
        if (idx >= 0) {
            if ([ShiftBatRowType.service, ShiftBatRowType.stop, ShiftBatRowType.noteTimed].includes(row.type)) {
                this.deleteNextDeadRow(idx);
            }
            this.rows.splice(idx, 1);
            if ([ShiftBatRowType.service, ShiftBatRowType.stop, ShiftBatRowType.noteTimed].includes(row.type)) {
                this.deletePrevDeadRow(idx);
            }
            // let prevRow, nextRow;
            // if (idx > 0) {
            //     prevRow = this.rows[idx - 1];
            // }
            // if (idx < this.rows.length - 1) {
            //     nextRow = this.rows[idx + 1];
            // }
            // // Check if there are dead runs next door and delete those too
            // // if in between to dead runs
            // if (prevRow?.type === ShiftBatRowType.dead && nextRow?.type === ShiftBatRowType.dead) {
            //     this.rows.splice(idx - 1, 3);
            // } else if (prevRow?.type === ShiftBatRowType.dead) { // if after dead
            //     this.rows.splice(idx - 1, 2);
            // } else if (nextRow?.type === ShiftBatRowType.dead) { // if before dead run
            //     this.rows.splice(idx, 2);
            // } else {
            //     this.rows.splice(idx, 1);
            // }
        }
    }

    checkPrevLoc(row, idx) {
        let addedDeadRow = false;
        if (idx > 0 && row.type !== ShiftBatRowType.dead) {
            let prevIdx = idx - 1;
            let prevRow = this.rows[prevIdx--];

            if (prevRow.type === ShiftBatRowType.dead) {
                return false;
            }

            // Add dead running if necessary
            if (row.getStartLocation) {
                const toLoc = row.getStartLocation();
                if (toLoc) {
                    let prevLocRow = prevRow,
                        prevTimeRow = Number.isFinite(prevRow.time) && prevRow.time > -1 ? prevRow : null;
                    while (prevIdx >= 0 && !prevLocRow.getEndLocation) {
                        prevLocRow = this.rows[prevIdx--];
                        if (!prevTimeRow && Number.isFinite(prevLocRow.time) && prevLocRow.time > -1) {
                            prevTimeRow = prevLocRow;
                        }
                    }

                    if (prevLocRow.getEndLocation) {
                        const fromLoc = prevLocRow.getEndLocation();
                        if (fromLoc && toLoc.geohash !== fromLoc.geohash) {
                            const distance = getDistanceInMetres(prevLocRow.getEndLocation(), row.getStartLocation());
                            const duration = distance / 14;

                            const deadRow = this.createRow({
                                type: ShiftBatRowType.dead,
                                time: prevTimeRow ? prevTimeRow.time + (prevTimeRow.duration || 0) : -1,
                                start: prevLocRow.getEndLocation(),
                                startStopId: prevLocRow.getEndLocation().stopId,
                                end: row.getStartLocation(),
                                endStopId: row.getStartLocation().stopId,
                                distance,
                                duration,
                            });
                            this.rows.splice(idx, 0, deadRow);
                            addedDeadRow = true;
                            prevRow = deadRow;
                        }
                    }
                }
            }

            // Update the new rows time
            // if (row.time === -1 && ![ShiftBatRowType.service].includes(row.type)) {
            //     row.time = prevRow.time + (prevRow.duration || 0)
            // }
            row.prevRow = prevRow;
        }
        return addedDeadRow;
    }

    toJson() {
        return pickBy({
            ...this,
            forceUpdate: undefined,
            createdAt: this.createdAt.format('DD/MM/YYYY'),
            rows: this.rows.map(row => row.toJson()),
            effectiveDate: this.effectiveDate?.format('DD/MM/YYYY')
        }, (val, key) => val !== null && val !== undefined && !['schedules'].includes(key));
    }
}

export const ShiftBatRowType = {
    noteTimed: 'Note timed',
    note: 'Note',
    service: 'Service',
    'break': 'Break',
    breakMeal: 'Meal break',
    breakBroken: 'Broken shift break',
    location: 'Add location',
    stop: 'Point',
    stopNote: 'Stop Note',
    dead: 'Dead running',
    charter: 'Charter route',
    template: 'Template'

};
export const ShiftBatRowTypeReverseMap = {
    'Note timed': 'noteTimed',
    'Note': 'note',
    'Service': 'service',
    'Break': 'break',
    'Meal break': 'breakMeal',
    'Broken shift break': 'breakBroken',
    'Add location': 'location',
    'Point': 'stop',
    'Stop Note': 'stopNote',
    'Dead running': 'dead',
    'Charter route': 'charter',
    'Template': 'template'
};

export const ShiftBatRowTypeLabel = {
    itinerary: 'Itinerary',
    service: 'Service',
    stop: 'Point',
    noteTimed: 'Note - Timed',
    note: 'Note - Untimed',
    'break': 'Break - Crib',
    breakMeal: 'Break - Meal',
    breakBroken: 'Break - Split Shift',
    location: 'Add location',
    stopNote: 'Stop Note',
    dead: 'Dead running',
    charter: 'Charter route',
    template: 'Template'
};
export const SelectableShiftBatRowTypes = ['service', 'stop', 'noteTimed', 'note', 'break', 'breakMeal', 'breakBroken'];
export const ShiftBatRowNotePriority = {normal: 'Normal', high: 'High'};
export const ShiftBatRowNotePriorityLabel = {normal: 'Normal', high: 'Important'};

const abbreviate = text => {
    const abbreviations = {
        'Street': 'St',
        'Avenue': 'Ave',
        'Boulevard': 'Blvd',
        'Drive': 'Dr',
        'Esplanade': 'Esp',
        'Road': 'Rd',
        'Lane': 'Ln',
        'Court': 'Ct',
        'Square': 'Sq',
        'Trail': 'Trl',
        'Parkway': 'Pkwy',
        'Terrace': 'Tce',
        'Place': 'Pl',
        'Circle': 'Cir',
        'Highway': 'Hwy',
        'Crescent': 'Cr',
        'Parade': 'Pde',
        'Circuit': 'Cct',
        'Close': 'Cl'
    };

    // Create a regex pattern from the keys of the abbreviations object
    const pattern = new RegExp('\\b(' + Object.keys(abbreviations).join('|') + ')\\b', 'gi');

    // Replace matched patterns with their abbreviations
    return text.replace(pattern, (match) => abbreviations[match]);
};

export class ShiftBatRow {
    constructor(props) {

        this.id = ulid();
        this.type = ShiftBatRowType.noteTimed;
        this.title = '';
        this.description = '';
        this.priority = ShiftBatRowNotePriority.normal;
        this.time = -1; //seconds since midnight
        this.loaded = false;
        this.loading = false;
        this.initialised = false;
        this.shiftBat = null;
        Object.assign(this, props);

        if (this.title) {
            this.title = abbreviate(this.title);
        }

        if (this.description) {
            this.description = abbreviate(this.description);
        }
    }

    clone() {
        return new ShiftBatRow(cloneDeep(this));
    }

    isValid() {
        return !this.loaded || this.title?.length;
    }

    invalidReason() {
        return null;
    }

    getInvalidMessage() {
        const missing = [];
        if (!this.title?.length) missing.push('title');
        if (this.time < 0) missing.push('time');
        return missing?.length ? `Missing ${missing.join(', ')}` : null;
    }

    isDefault() {
        return !this.title?.length && !this.description?.length && (!this.priority?.length || this.priority === ShiftBatRowNotePriority.normal);
    }

    needsUpdate() {
        return false;
    }

    getTime() {
        if (Number.isFinite(this.time) && this.time >= 0) {
            return this.time;
        }
    }

    getEndTime() {
        if (Number.isFinite(this.time) && this.time >= 0) {
            return this.time;
        }
    }

    toTimeString() {
        if (Number.isFinite(this.time) && this.time >= 0) {
            return toTime(this.time);
        }
        return '';
    }

    getDuration() {
        return this.duration || 0;
    }

    getDistance() {
        return this.distance || 0;
    }

    toJson() {
        return pickBy({...this}, (val, key) => {
            return val !== null && val !== undefined && !['prevRow', 'loaded', 'notes', 'routes', 'shiftBat'].includes(key);
        });
    }

    async updateRow(data = {}) {

    }
}

export class ShiftBatLocationRow extends ShiftBatRow {

    getStartLocation() {
        throw new Error('Please implement getStartLocation');
    }

    getEndLocation() {
        throw new Error('Please implement getEndLocation');
    }

    locationUpdated(otherRow) {
        throw new Error('Please implement locationUpdated');

    }
}

export class ShiftBatServiceRow extends ShiftBatLocationRow {
    constructor(props) {
        super(props);
        this.type = ShiftBatRowType.service;
        this.route = null;
        this.startStopIdx = -1;
        this.endStopIdx = -1;
        this.trip = null;
        this.stopRows = null;
        this.duration = 0;
        this.distance = 0;
        this.hasDirections = false;
        Object.assign(this, props);

        if (this.route) {
            if (this.startStopIdx > -1) {
                this.route.startStopIdx = this.startStopIdx;
            }
            if (this.endStopIdx > -1) {
                this.route.endStopIdx = this.endStopIdx;
            }
            this.route = new BusRoute(this.route);
        }
        if (this.trip) {
            this.trip = new Trip(this.trip);
        }

        if (this.stopRows) {
            this.stopRows = this.stopRows.map(sr => new ShiftBatStopRow(sr));
        }

        // if (this.trip && this.route?.stops) {
        //     const stopRows = this.route?.stops.map((stop, idx) => new ShiftBatStopRow({
        //         stop: stop,
        //         time: getDepartureTimeAsSecondsSinceMidnight(this.trip, this.trip.stopTimes[idx]),
        //         title: stop.stopName,
        //         sequence: idx + 1
        //     }));
        //
        //     if(this.stopRows) {
        //         stopRows.forEach((sr, idx) => {
        //             const existingSr = this.stopRows[idx]
        //             if (existingSr?.stop.stopId === sr.stop.stopId) {
        //                 sr.noteRows = this.stopRows[idx].noteRows
        //                 sr.transfersTo = this.stopRows[idx].transfersTo
        //                 sr.transfersFrom = this.stopRows[idx].transfersFrom
        //             }
        //         })
        //     }
        //
        //     // if (stopRows?.length === this.stopRows?.length &&
        //     //     differenceWith(stopRows, this.stopRows,
        //     //         (a, b) => a.stop?.stopTimeId === b.stop?.stopTimeId && a.stop?.stopId === b.stop?.stopId).length === 0) {
        //     //     stopRows.forEach((sr, idx) => {
        //     //         sr.noteRows = this.stopRows[idx].noteRows
        //     //         sr.transfersTo = this.stopRows[idx].transfersTo
        //     //         sr.transfersFrom = this.stopRows[idx].transfersFrom
        //     //     })
        //     // }
        //     this.stopRows = stopRows;
        //     if (this.stopRows?.length) {
        //         this.duration = last(this.stopRows).time - this.stopRows[0].time;
        //     }
        // }

    }

    clone() {
        return new ShiftBatServiceRow(cloneDeep(this));
    }

    isValid({allRoutes}) {
        if (!this.loaded) {
            return true;
        }
        const route = this.route || allRoutes[this.routeId];
        if (!route) {
            return false;
        }
        const trip = find(route.services, {tripId: (this.tripId || this.trip?.tripId)});
        if (!trip) {
            return false;
        }
        return true;
    }

    invalidReason({allRoutes}) {

        const route = allRoutes[this.routeId || this.route?.routeId];
        if (!route) {
            return `The ${this.title} route has been deleted, moved to Draft status, or is invalid`;
        }
        const trip = find(route.services, {tripId: (this.tripId || this.trip?.tripId)});
        if (!trip) {
            return `The ${getSecondsSinceMidnightAsDayjs(this.time).format('HH:MM')} service for ${allRoutes[this.routeId || this.route?.routeId].routeNumber} has been deleted, moved to Draft status, or is invalid`;
        }
        if (!this.shiftBat.scheduleIds?.length || this.shiftBat.scheduleIds.every(sId => !trip.scheduleIds.includes(sId))) {
            return `The ${this.title} route is not scheduled for this shift`;
        }
    }

    getInvalidMessage() {
        const missing = [];
        if (!this.route) missing.push('route');
        if (!this.trip) missing.push('trip');
        if (this.time < 0) missing.push('time');
        return missing?.length ? `Missing ${missing.join(', ')}` : null;
    }

    isDefault() {
        return super.isDefault() && !this.route && !this.trip;
    }

    getTime() {
        return this.trip?.getStartTimeAsSecondsSinceMidnight(this.startStopIdx > 0 ? this.startStopIdx : undefined) || 0;
    }

    getEndTime() {
        return this.trip?.getEndTimeAsSecondsSinceMidnight(this.endStopIdx > 0 ? this.endStopIdx : undefined) || 0;
    }

    toTimeString() {
        return this.trip?.stopTimes?.length ? toTime(this.getTime()) : '--:--';
    }

    needsUpdate() {
        return !this.loaded && !this.loading;
        // return !this.route || (!this.route.waypoints?.length || !this.stopRows?.length || !this.stopRows[0].transfersFrom || !this.stopRows[0].transfersTo || !this.distance || !this.duration)
    }

    async updateRow({apiKey, allStops, allTransfers = {}, allRoutes, opts = {directions: true}}) {
        if (!this.route && !this.routeId) {
            return;
        }
        this.loading = true;
        try {
            const route = this.route || allRoutes[this.routeId];
            if (!route) {
                console.log('ROUTE CANNOT BE FOUND!!!');
                this.loaded = true;
                this.loading = false;
                return;
            }
            this.route = route.clone();

            this.trip = this.trip || find(this.route.services, ['tripId', this.tripId]);
            this.route.setBaseStops(allStops, this.route.stopTimes);

            if (this.startStopIdx > -1 || this.endStopIdx > -1) {
                this.route.calculateStartEnd({
                    firstStop: this.route.stops[this.startStopIdx > -1 ? this.startStopIdx : 0],
                    lastStop: this.route.stops[this.endStopIdx > -1 ? this.endStopIdx : this.route.stops.length - 1]
                });
            }
            this.distance = this.route.getDistance();
            this.duration = this.route.getDuration();
            this.time = this.trip?.getStartTimeAsSecondsSinceMidnight(this.startStopIdx > 0 ? this.startStopIdx : null) || 0;

            if (this.stopRows) {
                this.stopRows = this.stopRows.map(sr => new ShiftBatStopRow(sr));
            }

            if (this.trip && this.route?.stops) {
                const stopRows = this.trip.stops
                    .filter((st, idx) => config?.operator?.opts?.shiftbat?.turnsOnAllStops || st.timingPoint || this.charter || this.route.charter || this.shiftBat.charter)
                    .map((st, idx) => {
                        return new ShiftBatStopRow({
                            stop: st,
                            stopTimeId: st.stopTimeId,
                            stopId: st.stopId,
                            tripId: this.trip.tripId,
                            routeId: this.route.routeId,
                            time: getDepartureTimeAsSecondsSinceMidnight(this.trip, st),
                            title: allStops[st.stopId]?.stopName || '',
                            sequence: this.trip.getStopSequence(st, allStops)
                        });
                    });

                if (this.stopRows) {
                    stopRows.forEach((sr, idx) => {
                        const existingSr = this.stopRows[idx];
                        if (existingSr?.stop?.stopId === sr.stop.stopId) {
                            sr.noteRows = this.stopRows[idx].noteRows;
                            sr.transfersTo = this.stopRows[idx].transfersTo;
                            sr.transfersFrom = this.stopRows[idx].transfersFrom;
                        }
                    });
                }

                // if (stopRows?.length === this.stopRows?.length &&
                //     differenceWith(stopRows, this.stopRows,
                //         (a, b) => a.stop?.stopTimeId === b.stop?.stopTimeId && a.stop?.stopId === b.stop?.stopId).length === 0) {
                //     stopRows.forEach((sr, idx) => {
                //         sr.noteRows = this.stopRows[idx].noteRows
                //         sr.transfersTo = this.stopRows[idx].transfersTo
                //         sr.transfersFrom = this.stopRows[idx].transfersFrom
                //     })
                // }
                this.stopRows = stopRows;
                if (this.stopRows?.length) {
                    this.duration = last(this.stopRows).time - this.stopRows[0].time;
                }
            }


            const tripTransfersTo = values(allTransfers).filter(t => !t.inSeat).filter(tx => {
                return tx.fromTripId === this.trip.tripId;
            });
            const tripTransfersFrom = values(allTransfers).filter(t => !t.inSeat).filter(tx => {
                return tx.toTripId === this.trip.tripId;
            });
            this.stopRows.forEach(stopRow => {
                stopRow.stop = stopRow.stop || allStops[stopRow.stopId];
                stopRow.title = stopRow.title || stopRow.stop.stopName;
                stopRow.transfersTo = tripTransfersTo.filter(tx => tx.fromStopTimeId === stopRow.stop.stopTimeId)
                    .map(tx => ({...allRoutes[tx.toRouteId], txTripId: tx.toTripId})).filter(tx => !!tx);

                stopRow.transfersFrom = tripTransfersFrom.filter(tx => tx.toStopTimeId === stopRow.stop.stopTimeId)
                    .map(tx => ({...allRoutes[tx.fromRouteId], txTripId: tx.fromTripId})).filter(tx => !!tx);
            });
            this.title = this.route.routeName;
            if (opts.directions) {
                await this.buildDirections();
            }
        } catch (e) {
            console.log(e, e);
        }
        this.loaded = true;
        this.loading = false;
    }

    getStopRows() {
        const startStopTimeId = this.trip?.stopTimes[this.startStopIdx > -1 ? this.startStopIdx : 0]?.stopTimeId;
        const endStopTimeId = this.trip?.stopTimes[this.endStopIdx > -1 ? this.endStopIdx : this.trip.stopTimes.length - 1]?.stopTimeId;
        const sRows = this.stopRows || [];
        return sRows.slice(sRows.findIndex(sr => sr.stopTimeId === startStopTimeId), sRows.findIndex(sr => sr.stopTimeId === endStopTimeId) + 1);
    }

    addNote(stopSequence, note, priority) {
        this.stopRows[stopSequence - 1].addNote(new ShiftBatRow({title: note, priority}));
    }

    async buildDirections(force) {
        if (this.route?.stops?.length && this.trip?.stops?.length && (force || !this.hasDirections)) {
            this.stopRows.forEach(sr => sr.noteRows = sr.noteRows.filter(nr => !nr.directions));
            const stops = this.route.stops
                .filter((st, idx) => config?.operator?.opts?.shiftbat?.turnsOnAllStops || this.trip.stopTimes[idx].timingPoint || this.charter || this.route.charter || this.shiftBat.charter);
            const wpsBetweenStops = stops
                .slice(1).map((stop, idx) => {
                    const prevStopWpIdx = stops[idx].wpIdx;
                    const thisStopWpIdx = stop.wpIdx;
                    let wps = this.route.waypoints.slice(prevStopWpIdx, thisStopWpIdx + 1);
                    return {idx, wps};
                });

            try {
                await Promise.all(wpsBetweenStops.map(async wpsBetweenStops => {
                    if (!wpsBetweenStops.wps?.length) {
                        return;
                    }
                    const chunks = chunk(wpsBetweenStops.wps, 1000);
                    await eachSeries(chunks, (wps, done) => {
                        if (!wps?.length || wps.length < 2) {
                            return done();
                        }
                        getDirections(wps).then(data => {
                            if (!data) {
                                return done();
                            }
                            const {directions, waypoints} = data;
                            if (directions?.length) {
                                this.stopRows[wpsBetweenStops.idx].noteRows.push(new ShiftBatRow({
                                    title: directions.join(', '),
                                    directions: true
                                }));
                            }

                            this.stopRows[wpsBetweenStops.idx].waypoints = this.stopRows[wpsBetweenStops.idx].waypoints || [];
                            this.stopRows[wpsBetweenStops.idx].waypoints.push(...waypoints.map(wp => ({
                                lat: wp.location[1],
                                lon: wp.location[0]
                            })));
                            done();
                        }).catch(e => {
                            console.log(e, e);
                            done();
                        });
                    });
                }));
                // this.mappedRoute = new BusRoute({
                //     colour: '#FE73D1',
                //     waypoints: flatten(this.stopRows.filter(stopRow => stopRow.waypoints?.length).map(stopRow => stopRow.waypoints.filter(wp => wp.lat && wp.lon)))
                // })
                this.hasDirections = true;
            } catch (e) {
                console.log('Could not build directions: ' + e, e);
            }
        }
    }

    toJson() {
        const obj = super.toJson();
        return pickBy({
            ...obj,
            routeId: this.routeId || this.route?.routeId,
            tripId: this.tripId || this.trip?.tripId,
            stopRows: this.stopRows.map(sr => sr.toJson())
        }, (val, key) => val !== null && val !== undefined && (key !== 'trip' || !this.tripId) &&
            (key !== 'route' || !this.routeId) &&
            !['mappedData', 'time', 'duration', 'distance', 'priority', 'shiftBat'].includes(key));
    }

    getStartLocation() {
        return this.route?.stops?.length ? this.route.stops[this.startStopIdx >= 0 ? this.startStopIdx : 0] : null;
    }

    getEndLocation() {
        return this.route?.stops?.length ? this.route.stops[this.endStopIdx >= 0 ? this.endStopIdx : this.route.stops.length - 1] : null;
    }

    getDistance() {
        return this.route?.getDistance() || 0;
    }

    getDuration() {
        return this.route?.getDuration() || 0;
    }

    locationUpdated(otherRow) {
        return otherRow?.route && this.route?.routeId !== otherRow.route?.routeId;
    }
}

export class ShiftBatCharterRow extends ShiftBatServiceRow {
    constructor(props) {
        super(props);
        this.type = ShiftBatRowType.charter;
    }
}

export class ShiftBatStopRow extends ShiftBatLocationRow {
    constructor(props) {
        super(props);
        // console.log("Creating ShiftBatStopRow")
        this.type = ShiftBatRowType.stop;
        this.stop = null;
        this.stopTimeId = null;
        this.stopId = null;
        this.tripId = null;
        this.routeId = null;
        this.sequence = 0;
        this.noteRows = [];
        this.transfersTo = [];
        this.transfersFrom = [];
        Object.assign(this, props);
        this.noteRows = this.noteRows.map(row => new ShiftBatRow(row));
        this.title = this.stop?.stopName;
    }

    clone() {
        return new ShiftBatStopRow(cloneDeep(this));
    }

    addNote({id, note, priority}, idx) {
        console.log('Adding note...');
        this.noteRows.splice(idx, 0, new ShiftBatRow({
            id,
            title: note || '',
            priority: priority || ShiftBatRowNotePriority.normal
        }));
    }

    replaceNote(note) {
        const idx = this.noteRows.findIndex(n => n.id === note.id);
        if (idx > -1) {
            this.noteRows.splice(idx, 1, note);
        }
    }

    removeNote(row) {
        const idx = this.noteRows.findIndex(r => row.id === r.id);
        if (idx >= 0) {
            this.noteRows.splice(idx, 1);
        }
    }

    getStartLocation() {
        return this.stop;
    }

    getEndLocation() {
        return this.stop;
    }

    toJson() {
        const obj = super.toJson();
        return pickBy({
            ...obj,
            stopId: this.stopId || this.stop.stopId,
            noteRows: this.noteRows.map(nr => {
                const obj = nr.toJson();
                return pickBy(obj, (val, key) => !['description', 'time'].includes(key));
            })
        }, (val, key) => {
            if (val === null || val === undefined) {
                return false;
            }
            // only include the stop if there is no stopId
            if (key === 'stop') {
                return !val.stopId;
            }
            return !['duration', 'title', 'transfersFrom', 'transfersTo', 'priority', 'loaded', 'shiftBat'].includes(key);
        });
    }

    async updateRow({allStops = {}}) {
        if (this.stop) {
            this.title = this.stop?.stopName || '';
            this.description = this.description || '';
            this.loaded = true;
            return;
        }
        this.loading = true;
        console.log('Looking up stop');
        if (this.stopId) {
            this.stop = allStops[this.stopId] || {};
        }
        this.title = this.stop?.stopName || '';
        this.description = this.description || '';
        this.loaded = true;
        this.loading = false;
    }

    locationUpdated(otherRow) {
        return otherRow?.stop && this.stop?.stopId !== otherRow.stop?.stopId;
    }

    isValid({allStops}) {
        return !this.loaded || this.stop || (allStops[this.stopId || this.stop?.stopId]);
    }

    invalidReason({allStops}) {
        if (!allStops[this.stopId || this.stop?.stopId]) {
            return `The stop ${this.title} has been deleted or is invalid`;
        }
    }

    getInvalidMessage() {
        const missing = [];
        if (!this.stopId?.length || !this.stop) missing.push('stop');
        return missing?.length ? `Missing ${missing.join(', ')}` : null;
    }

    getDistance() {
        return 0;
    }

}

export class ShiftBatDeadRow extends ShiftBatLocationRow {
    constructor(props) {
        super(props);
        // console.log("Creating ShiftBatDeadRow")
        this.type = ShiftBatRowType.dead;
        this.route = null; // Store the waypoints in a "BusRoute" so RouteMap.js can understand it.
        this.trip = null;
        this.start = null;
        this.end = null;
        this.duration = 0;
        this.distance = 0;
        Object.assign(this, props);

        if (this.route) {
            this.route = new Deadrun(this.route);
        }

    }

    clone() {
        return new ShiftBatDeadRow(cloneDeep(this));
    }

    needsUpdate() {
        return !this.loaded && !this.loading;
    }

    async updateRow({single, deadrunModelData}) {
        if (!deadrunModelData || !this.needsUpdate()) {
            return;
        }
        if ((!this.start && !this.startStopId) || (!this.end && !this.endStopId)) {
            this.loaded = true;
            return;
        }

        this.loading = true;
        console.log('Looking up dead running route path');
        if (!this.route) {
            const existingDeadruns = await deadrunModelData.fetch(this);
            if (!single && !existingDeadruns?.length) {
                if (!this.getStartLocation() || !this.getEndLocation()) {
                    this.loading = false;
                    this.loaded = true;
                    return;
                }
                let deadrun = new Deadrun({
                    startStop: this.getStartLocation(),
                    endStop: this.getEndLocation(),
                    routeId: '_'
                });
                try {
                    const result = await getCachedPath('bus', this.getStartLocation(), this.getEndLocation());
                    console.log(result);
                    if (result?.waypoints?.length) {
                        deadrun.waypoints = result.waypoints;
                        deadrun.duration = result.duration;
                        deadrun.distance = result.distance;
                        deadrun.routeName = `Special - ${this.getStartLocation().stopName} to ${this.getEndLocation().stopName}`;

                    }
                } catch (e) {
                    console.log(e, e);
                }
                if (!deadrun?.waypoints?.length) {
                    deadrun.waypoints = [this.getStartLocation(), this.getEndLocation()];
                    deadrun.distance = getDistanceInMetres(this.getStartLocation(), this.getEndLocation());
                    deadrun.duration = this.distance / (config.operator?.opts?.timetable?.avgSpd || 13.8);
                    deadrun.routeName = `As the crow flys - ${this.getStartLocation().stopName} to ${this.getEndLocation().stopName}`;
                }
                deadrun = await deadrunModelData.save(deadrun);
                existingDeadruns.push(deadrun);
                this.routeId = deadrun.routeId;
            }
            this.routes = keyBy(existingDeadruns, 'routeId');
            // Find the route and add it to the row
            this.route = this.routeId ? this.routes[this.routeId] : existingDeadruns[0];
            this.route = this.route || existingDeadruns[0];
        }
        // this.description = `Deadrun (${toHrsMinsSecs(this.route.duration, false, true, 1)}, ${toKmMs(this.route.distance)})`
        this.routeId = this.route.routeId;
        this.title = abbreviate(this.route.routeName);
        if (typeof this.route.routeDetails === 'string') {
            this.description = abbreviate(this.route.routeDetails);
        } else if (!single) {
            this.route.routeDetails = await this.buildDirections();
            await deadrunModelData.save(this.route);
            this.description = abbreviate(this.route.routeDetails);
        }
        this.duration = this.route.duration;
        this.distance = this.route.distance;
        this.hasDirections = true;

        const thisIdx = findIndex(this.shiftBat.rows, row => row.id === this.id);
        let prevLocRow = null;
        for (let i = thisIdx - 1; i >= 0; i--) {
            prevLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevLocRow.type)) {
                break;
            }
        }
        let startST = prevLocRow.trip?.stopTimes[0];
        if (!startST) {
            const startTime = secsSinceMidnightToDayjs((prevLocRow.time || 0) + (prevLocRow.duration || 0));
            startST = {departHour: startTime.hour(), departMin: startTime.minute()};
        }
        const stopTimes = [{
            ...this.route.waypoints[0], ...startST,
            distance: 0,
            delta: 0
        }, last(this.route.waypoints) || this.end];
        this.route.stopTimes = stopTimes;
        this.route.stops = stopTimes;
        this.trip = new Trip({stopTimes, stops: stopTimes});

        this.loaded = true;
        this.loading = false;
    }

    async reset({deadrunModelData}) {
        await deadrunModelData.delete(this.routeId, true, true);
        this.loaded = false;
        this.loading = false;
        this.hasDirections = false;
        this.route = null;
        this.routeId = null;
        this.distance = null;
        this.duration = null;
        const thisIdx = findIndex(this.shiftBat.rows, row => row.id === this.id);
        let prevLocRow = null, nextLocRow;
        for (let i = thisIdx - 1; i >= 0; i--) {
            prevLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevLocRow.type)) {
                break;
            }
        }

        for (let i = thisIdx + 1; i < this.shiftBat.rows.length; i++) {
            nextLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(nextLocRow.type)) {
                break;
            }
        }
        this.start = prevLocRow.getEndLocation();
        this.end = nextLocRow.getStartLocation();
        this.startStopId = this.start.stopId;
        this.endStopId = this.end.stopId;
        await this.updateRow({deadrunModelData});
        return this;
    }


    async buildDirections(force) {

        try {
            if (!this.route?.waypoints?.length || (!force && this.hasDirections)) {
                return;
            }
            const chunks = chunk(this.route.waypoints, 1000);
            const result = await mapSeries(chunks, (wps, done) => {
                if (!wps?.length || wps.length < 2) {
                    return done();
                }
                return getDirections(wps).then(data => {
                    if (!data) {
                        return done();
                    }
                    const {directions} = data;
                    if (directions?.length) {
                        // this.directionsRow = new ShiftBatRow({title: directions.join(', ')});
                        // return directions.join(', ');
                        return done(null, directions.join(', '));
                    }
                    done(null, '');
                }).catch(e => {
                    console.log(e, e);
                    done(e);
                });
            });
            return result.join(', ');
        } catch (e) {
            console.log('Could not build directions: ' + e, e);
        }
    }

    getStartLocation() {
        return this.start;
    }

    // toTimeString() {
    //     return toHrsMinsSecs(this.duration, false, true)
    // }

    getEndLocation() {
        return this.end;
    }

    getDistance() {
        return this.route?.getDistance() || 0;
    }

    getDuration() {
        return this.route?.getDuration() || 0;
    }

    isValid({allStops}) {
        return !this.loaded || (this.start && this.end) || (allStops[this.startStopId] && allStops[this.endStopId]);
    }

    invalidReason({allStops}) {
        if (!allStops[this.startStopId] && !allStops[this.endStopId]) {
            return `The start and end stops have been deleted or are invalid`;
        } else if (!allStops[this.startStopId]) {
            return `The start stop has been deleted or is invalid`;
        } else if (!allStops[this.endStopId]) {
            return `The end stop has been deleted or is invalid`;
        }
    }

    toJson() {
        const {id, distance, duration, title, type, route, start, end} = this;
        const json = {
            id,
            distance,
            duration,
            title,
            type,
            routeId: this.routeId,
            startStopId: this.startStopId,
            endStopId: this.endStopId
        };
        if (!json.routeId) json.route = route;
        if (!json.startStopId) json.start = start;
        if (!json.endStopId) json.end = end;
        return json;
    }

}

export class ShiftBatNoteRow
    extends ShiftBatRow {
    constructor(props) {
        super(props);
        // console.log("Creating ShiftBatNoteRow")
        this.type = ShiftBatRowType.noteTimed;
        Object.assign(this, props);
    }

    clone() {
        return new ShiftBatNoteRow(cloneDeep(this));
    }

    needsUpdate() {
        return !this.loaded;
    }

    async updateRow() {
        return false;
        // if (!this.needsUpdate()) {
        //     return;
        // }
        // console.log('Looking up notes');
        // this.notes = (await noteRefModelData.fetchAll()) || {}
        // let note;
        // if (!Object.keys(this.notes).length) {
        //     // note = {refId: "note#", title: "", description: "", priority: ShiftBatRowNotePriority.normal}
        //     // this.notes["note#"] = note
        // }
        // note = this.refId ? (this.notes[this.refId] || note) : null
        // this.title = note?.title || "";
        // this.description = note?.description || "";
        // this.priority = note?.priority || "";
        // this.loaded = true
    }

}

export class ShiftBatBreakRow extends ShiftBatRow {
    constructor(props) {
        super(props);
        this.duration = 0;
        Object.assign(this, props);
    }

    clone() {
        return new ShiftBatBreakRow(cloneDeep(this));
    }

    isValid() {
        return !this.loaded || (this.duration > 0 && super.isValid());
    }

    invalidReason() {
        return null;
    }

    isDefault() {
        return super.isDefault() && !this.duration;
    }

}


export class ShiftBatInvalidRow extends ShiftBatRow {
    constructor(props) {
        super(props);
        Object.assign(this, props);
    }

    isValid() {
        return false;
    }

}

export class ShiftBatCharterRouteRow extends ShiftBatLocationRow {
    constructor(props) {
        super(props);
        this.type = ShiftBatRowType.charter;
        this.route = new BusRoute(); // Store the waypoints in a "BusRoute" so RouteMap.js can understand it.
        this.trip = null;
        this.start = null;
        this.end = null;
        this.duration = 0;
        this.distance = 0;
        Object.assign(this, props);

        if (this.route) {
            this.route = new BusRoute(this.route);
        }

    }

    clone() {
        return new ShiftBatCharterRouteRow(cloneDeep(this));
    }

    needsUpdate() {
        return !this.loaded && !this.loading;
    }

    async updateRow({single, charterModelData}) {
        if (!this.needsUpdate()) {
            return;
        }
        if (!this.startStopId || !this.endStopId) {
            this.loaded = true;
            return;
        }

        this.loading = true;
        console.log('Looking up dead running route path');
        const existingDeadruns = await charterModelData.fetch(this) ?? {};
        if (!single && !existingDeadruns?.length) {
            if (!this.getStartLocation() || !this.getEndLocation()) {
                this.loading = false;
                this.loaded = true;
                return;
            }
            let deadrun = new CharterRouteRun({
                // let deadrun = new Deadrun({
                startStop: this.getStartLocation(),
                endStop: this.getEndLocation(),
                routeId: '_'
            });
            try {
                const result = await getCachedPath('bus', this.getStartLocation(), this.getEndLocation());
                console.log(result);
                if (result?.waypoints?.length) {
                    deadrun.waypoints = result.waypoints;
                    deadrun.duration = result.duration;
                    deadrun.distance = result.distance;
                    deadrun.routeName = `Route - ${this.getStartLocation().stopName} to ${this.getEndLocation().stopName}`;

                }
            } catch (e) {
                console.log(e, e);
            }
            if (!deadrun?.waypoints?.length) {
                deadrun.waypoints = [this.getStartLocation(), this.getEndLocation()];
                deadrun.distance = getDistanceInMetres(this.getStartLocation(), this.getEndLocation());
                deadrun.duration = this.distance / (config.operator?.opts?.timetable?.avgSpd || 13.8);
                deadrun.routeName = `As the crow flys - ${this.getStartLocation().stopName} to ${this.getEndLocation().stopName}`;
            }
            deadrun = await charterModelData.save(deadrun);
            existingDeadruns.push(deadrun);
            this.routeId = deadrun.routeId;
        }
        this.routes = keyBy(existingDeadruns, 'routeId');
        // Find the route and add it to the row
        this.route = this.routeId ? this.routes[this.routeId] : existingDeadruns[0];
        // this.description = `Deadrun (${toHrsMinsSecs(this.route.duration, false, true, 1)}, ${toKmMs(this.route.distance)})`
        this.route = this.route || existingDeadruns[0];
        this.routeId = this.route.routeId;
        this.title = abbreviate(this.route.routeName);
        if (this.route.routeDetails) {
            this.description = abbreviate(this.route.routeDetails);
        } else if (!single) {
            this.route.routeDetails = await this.buildDirections();
            await charterModelData.save(this.route);
            this.description = abbreviate(this.route.routeDetails);
        }
        this.duration = this.route.duration;
        this.distance = this.route.distance;
        this.hasDirections = true;

        const thisIdx = findIndex(this.shiftBat.rows, row => row.id === this.id);
        let prevLocRow = null;
        for (let i = thisIdx - 1; i >= 0; i--) {
            prevLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevLocRow.type)) {
                break;
            }
        }
        let startST = prevLocRow.trip?.stopTimes[0];
        if (!startST) {
            const startTime = secsSinceMidnightToDayjs((prevLocRow.time || 0) + (prevLocRow.duration || 0));
            startST = {departHour: startTime.hour(), departMin: startTime.minute()};
        }
        const stopTimes = [{
            ...this.route.waypoints[0], ...startST,
            distance: 0,
            delta: 0
        }, last(this.route.waypoints) || this.end];
        this.route.stopTimes = stopTimes;
        this.route.stops = stopTimes;
        this.trip = new Trip({stopTimes, stops: stopTimes});

        this.loaded = true;
        this.loading = false;
    }

    async reset({charterModelData}) {
        await charterModelData.delete(this.routeId, true, true);
        this.loaded = false;
        this.loading = false;
        this.hasDirections = false;
        this.route = null;
        this.routeId = null;
        this.distance = null;
        this.duration = null;
        const thisIdx = findIndex(this.shiftBat.rows, row => row.id === this.id);
        let prevLocRow = null, nextLocRow;
        for (let i = thisIdx - 1; i >= 0; i--) {
            prevLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(prevLocRow.type)) {
                break;
            }
        }

        for (let i = thisIdx + 1; i < this.shiftBat.rows.length; i++) {
            nextLocRow = this.shiftBat.rows[i];
            if ([ShiftBatRowType.service, ShiftBatRowType.stop].includes(nextLocRow.type)) {
                break;
            }
        }
        this.start = prevLocRow.getEndLocation();
        this.end = nextLocRow.getStartLocation();
        this.startStopId = this.start.stopId;
        this.endStopId = this.end.stopId;
        await this.updateRow({charterModelData});
        return this;
    }


    async buildDirections(force) {

        try {
            if (!this.route?.waypoints?.length || (!force && this.hasDirections)) {
                return;
            }
            const chunks = chunk(this.route.waypoints, 1000);
            const result = await mapSeries(chunks, (wps, done) => {
                if (!wps?.length || wps.length < 2) {
                    return done();
                }
                return getDirections(wps).then(data => {
                    if (!data) {
                        return done();
                    }
                    const {directions} = data;
                    if (directions?.length) {
                        // this.directionsRow = new ShiftBatRow({title: directions.join(', ')});
                        // return directions.join(', ');
                        return done(null, directions.join(', '));
                    }
                    done(null, '');
                }).catch(e => {
                    console.log(e, e);
                    done(e);
                });
            });
            return result.join(', ');
        } catch (e) {
            console.log('Could not build directions: ' + e, e);
        }
    }

    getStartLocation() {
        return this.start;
    }

    // toTimeString() {
    //     return toHrsMinsSecs(this.duration, false, true)
    // }

    getEndLocation() {
        return this.end;
    }

    getDistance() {
        return this.route?.getDistance() || 0;
    }

    getDuration() {
        return this.route?.getDuration() || 0;
    }

    isValid({allStops}) {
        return !this.loaded || (allStops[this.startStopId] && allStops[this.endStopId]);
    }

    invalidReason({allStops}) {
        if (!allStops[this.startStopId] && !allStops[this.endStopId]) {
            return `The start and end stops have been deleted or are invalid`;
        } else if (!allStops[this.startStopId]) {
            return `The start stop has been deleted or is invalid`;
        } else if (!allStops[this.endStopId]) {
            return `The end stop has been deleted or is invalid`;
        }
    }

    toJson() {
        const {id, distance, duration, title, type} = this;
        return {
            id,
            distance,
            duration,
            title,
            type,
            routeId: this.routeId || this.route.routeId,
            startStopId: this.startStopId || this.start.stopId,
            endStopId: this.endStopId || this.end.stopId
        };
    }

}


export class ShiftBatTemplateRow
    extends ShiftBatRow {
    constructor(props) {
        super(props);
        this.type = ShiftBatRowType.template;
        Object.assign(this, props);

        if (props.template && props.modelData) {
            // TODO: Update to have templates from shiftbat and charters
            this.initialize(props.template, props.modelData);
        }
    }

    async initialize(id, modelData) {
        if (!modelData) return;
        const _template = await modelData.getTemplate(id);
        if (!_template) return;

        this.templateObj = _template;
        this.title = _template.message;
    }

    clone() {
        return new ShiftBatTemplateRow(cloneDeep(this));
    }

    isValid() {
        return true;
    }

}