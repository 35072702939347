import {useEffect, useState} from 'react';
import {employeeModelData} from '../services/ModelService';
import {pickBy, values} from 'lodash';

const useAllEmployees = ({filter = null} = {}) => {
    const [allEmployees, setAllEmployees] = useState(null);
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    useEffect(() => {

        const setEmps = employees => {
            setAllEmployees(pickBy(employees, (v, k) => v.firstName && v.lastName && !v.isDeleted));
        };

        const employeesListener = employeeModelData.addListener({
            setterFn: setEmps, loaded: employees => {
                setEmps(employees);
            }
        });

        return () => {
            if (employeesListener)
                employeeModelData.removeListener(employeesListener);
        };
    }, []);

    useEffect(() => {
        if (allEmployees && filter) {
            setFilteredEmployees(values(allEmployees).filter(filter));
        }
    }, [setFilteredEmployees, allEmployees, filter]);

    return {
        allEmployees, filteredEmployees
    };
};

export default useAllEmployees;

