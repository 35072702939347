import React, {useEffect, useState} from 'react';
import './Rosters.css';
import {FilterSwitches} from '../../containers/FilterTag';
import {Button, Card, Col, Input, Row, Tabs} from 'antd';
import {ReactComponent as BusPlus} from '../../assets/icons/BusPlus.svg';
import {ReactComponent as BusStat} from '../../assets/icons/BusStat.svg';
import {ReactComponent as BusQuote} from '../../assets/icons/BusQuote.svg';
import {ReactComponent as Right} from '../../assets/icons/Right.svg';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import {debounce, find, values} from 'lodash';
import RosteringGrid from './RosteringGrid';
import dayjs from 'dayjs';
import useAllVehicles from '../../hooks/useAllVehicle';
import useAllEmployees from '../../hooks/useAllEmployees';
import useJobsForDate from '../../hooks/useJobsForDate';
import useUnallocated from '../../hooks/useUnallocated';
import {useAppContext} from '../../libs/contextLib';
import {PlusOutlined, SyncOutlined} from '@ant-design/icons';
import {Job, JobScenario} from '../../model/job';
import LoadMessage from '../../components/LoadMessage';
import {charterModelData, jobModelData} from '../../services/ModelService';
import {toTime} from '../../libs/formatLib';

const {Search} = Input;

export const filterItems = {
    completed: {
        name: 'Status',
        color: 'success',
        showFn: (r) => !r.status || r.status === 'status',
    }
};

const staffMap = {
    '01J7WJ59226PNACZCJVRDV2MD2': '01J7YM2P4516SE7ZCZPVBMPTVD',
    '01J7WJ5MRDM1Y5GW2N5MCCWRD4': '01J7YT4Y47MJFV0ND1JZSAK3R9',
    '01J7XQ32CVGWYC6HJ6JD77Y29W': '01J7YV3JX2Z8C0BJBJPPJWHRZF'
};


function Rosters() {
    const {setBreadcrumb} = useAppContext();
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState('');
    const setFilterDebounced = debounce(setFilter, 250);
    const [showDashboards, setShowDashboards] = useState(true);

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const {allVehiclesArray} = useAllVehicles();
    const {allEmployees} = useAllEmployees();
    const {allJobsAsArray, vehicleJobs} = useJobsForDate({date: selectedDate});
    const {unallocatedCharters, unallocatedShiftBats} = useUnallocated({date: selectedDate});
    const [loadedVehicles, setLoadedVehicles] = useState(null);


    const [mode, setMode] = useState('vehicle');
    const [showUnallocated, setShowUnallocated] = useState(false);
    const [expandedView, setExpandedView] = useState(false);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [allUnAllocated, setAllUnAllocated] = useState(null);

    const [scenarios, setScenarios] = useState([]);
    const [selectedScenarioId, setSelectedScenarioId] = useState(null);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [jobs, setJobs] = useState([]);

    const [savingScenario, setSavingScenario] = useState(false);
    const [calculatingScenario, setCalculatingScenario] = useState(false);

    useEffect(() => {
        if (!allVehiclesArray || !vehicleJobs) {
            return;
        }
        const loadedVehicles = allVehiclesArray.map(vehicle => vehicle.clone());
        vehicleJobs.forEach(job => {
            const vehicle = find(loadedVehicles, {vehicleId: job.allocationId});
            vehicle.addJob(job);
        });
        setLoadedVehicles(loadedVehicles);
    }, [allVehiclesArray, vehicleJobs, setLoadedVehicles]);

    useEffect(() => {
        if (!vehicleJobs || !unallocatedShiftBats || !loadedVehicles || !selectedDate) {
            return;
        }
        setJobs(allJobsAsArray);
        setScenarios(scenarios => {
            if (scenarios?.length) {
                return scenarios;
            } else {
                const jobScenario = JobScenario.from({
                    name: 'Current',
                    jobs: allJobsAsArray,
                    live: true
                });
                return [jobScenario];
            }
        });
    }, [setScenarios, setJobs, allJobsAsArray, setSelectedScenario, setSelectedScenarioId, unallocatedShiftBats, loadedVehicles, selectedDate]);

    useEffect(() => {
        if (scenarios?.length === 1) {
            setSelectedScenarioId(scenarios[0].id);
            setSelectedScenario(scenarios[0]);
            setJobs([...scenarios[0].jobs]);
        }
    }, [scenarios, setSelectedScenario, setJobs, setSelectedScenarioId]);

    useEffect(() => {
        console.log(allVehiclesArray);
    }, [allVehiclesArray]);

    useEffect(() => {
        setSelectedEmployees(values(allEmployees));
    }, [allEmployees]);

    useEffect(() => {
        setBreadcrumb(mode === 'vehicle' ? 'Vehicle Roster' : mode === 'staff' ? 'Staff Roster' : '');
    }, [mode]);

    // useEffect(() => {
    //     setUnallocatedShiftBats(shiftBatsForDate.filter(sb => allJobs.filter(job => job.type === 'shiftbat').every(job => job.typeId !== sb.shiftBatId)))
    // }, [shiftBatsForDate]);
    //
    // useEffect(() => {
    //     setUnallocatedCharters(chartersForDate.filter(charter => allJobs.filter(job => job.type === 'charter').every(job => job.typeId !== charter.charterId)))
    // }, [chartersForDate]);

    useEffect(() => {
        if (!unallocatedCharters || !unallocatedShiftBats) return;
        let all = [
            ...unallocatedShiftBats?.map(duty => Job.fromDuty('shiftbat', duty, selectedDate)),
            ...unallocatedCharters?.map(duty => Job.fromDuty('charter', duty, selectedDate))];
        setAllUnAllocated(all);
    }, [unallocatedCharters, unallocatedShiftBats]);

    const addNewBlock = () => {
        if (mode === 'staff') {
            // setSelectedEmployees([...selectedEmployees, allEmployees[selectedEmployees.length]]);
        } else if (mode === 'vehicles') {
            // setSelectedVehicles([...selectedVehicles, allVehicles[selectedVehicles.length]]);
        }
    };

    const [totalSecs, setTotalSecs] = useState(0);
    const [driverHours, setDriverHours] = useState(0);
    const [wages, setWages] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const [otpercentage, setOTPercentage] = useState(0);

    useEffect(() => {
        let seconds = 0;
        if (allJobsAsArray) {
            allJobsAsArray.map((job) => {
                const time = job.getDuration();
                seconds += time;
            });
        }
        seconds = Number.isFinite(seconds) ? seconds : 0;
        let hours = (seconds / 3600);
        hours = Number.isFinite(hours) ? hours : 0;
        let overtime = hours > 40 * 3600 ? (hours - 40 * 3600) : 0;
        overtime = Number.isFinite(overtime) ? overtime : 0;
        let otpercentage = overtime / (hours) * 100;
        otpercentage = Number.isFinite(otpercentage) ? otpercentage : 0;
        setTotalSecs(seconds);
        setDriverHours(toTime(seconds, false));
        setOvertime(toTime(overtime, false));
        setOTPercentage(otpercentage.toFixed(2));
    }, [allJobsAsArray]);

    useEffect(() => {
        const wage = 10 * totalSecs / 3600;
        setWages(wage);
    }, [totalSecs]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
                {/*<div className="d-flex justify-content-end w-separator" style={{gap: '10px'}}>*/}
                {/*    <Button type="primary" className="icon-button" onClick={() => {*/}
                {/*        setMode('vehicle');*/}
                {/*    }}> Vehicles</Button>*/}
                {/*    <Button type="primary" className="icon-button" onClick={() => {*/}
                {/*        setMode('staff');*/}
                {/*    }}> Staff</Button>*/}
                {/*</div>*/}
                <div className="filter-dropdown w-separator">
                    <DateSelector initialDate={selectedDate} onChange={setSelectedDate}/>
                </div>
                <div className="justify-content-end">
                    <Button onClick={() => {
                        setShowDashboards(!showDashboards);
                    }} type="primary" className={`icon-button ${showDashboards ? 'active' : ''}`}>Dashboard</Button>

                    {/*<Button type="primary"*/}
                    {/*        className={`icon-button btn-filled`}*/}
                    {/*        onClick={async () => {*/}
                    {/*            setCalculatingScenario(true);*/}
                    {/*            // const newScenario1 = await JobScenario.smartAllocate(unallocatedShiftBats, loadedVehicles, selectedDate);*/}
                    {/*            const newScenario2 = await JobScenario.allocate(unallocatedShiftBats, loadedVehicles, selectedDate);*/}
                    {/*            setScenarios([...scenarios, newScenario2]);*/}
                    {/*            setCalculatingScenario(false);*/}
                    {/*        }}>Auto scenarios</Button>*/}
                </div>
            </div>
            <div className="dispatch-list">
                {!calculatingScenario && scenarios?.length ?
                    <Tabs hideAdd
                          size={'middle'}
                          onChange={id => {
                              const scenario = find(scenarios, {id});
                              setSelectedScenario(scenario);
                              setJobs([...scenario.jobs]);
                              // setSelectedScenarioId(id);
                          }}
                          activeKey={selectedScenario?.id || scenarios[0].id}
                          type="card"
                          tabBarExtraContent={
                              <div className="d-flex align-items-center">
                                  {scenarios.length > 1 &&
                                      <Button danger={true} onClick={() => {
                                          setScenarios(scenarios.filter(scenario => scenario.id !== selectedScenarioId));
                                          setSelectedScenarioId(scenarios[0].id);
                                      }} type="primary" className="icon-button btn-filled btn-error icon-10"
                                              icon={<Close/>}>Remove</Button>}
                                  <Button onClick={() => {
                                      setSavingScenario(true);
                                      jobModelData.save(selectedScenario.jobs).then(() => {
                                          setSavingScenario(false);
                                          setShowUnallocated(false);
                                      });
                                      setSelectedDate(dayjs());
                                  }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Save</Button>
                                  <Button onClick={async () => {
                                      setCalculatingScenario(true);
                                      const scenario = await JobScenario.allocate(unallocatedShiftBats, loadedVehicles, selectedDate);
                                      scenario.playground = true;
                                      allJobsAsArray.forEach(job => scenario.addJob(job));
                                      setCalculatingScenario(false);
                                      setScenarios([...scenarios, scenario]);
                                      setSelectedScenarioId(scenario.id);
                                      setSelectedScenario(scenario);
                                  }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Quick
                                      Add</Button>
                                  {/*<Button onClick={async () => {*/}
                                  {/*    setCalculatingScenario(true);*/}
                                  {/*    const scenario = await JobScenario.smartAllocate(unallocatedShiftBats.map(sb => Job.fromDuty('shiftbat', sb, selectedDate)), loadedVehicles, selectedDate);*/}
                                  {/*    allJobsAsArray.forEach(job => scenario.addJob(job));*/}
                                  {/*    setCalculatingScenario(false);*/}
                                  {/*    setScenarios([...scenarios, scenario]);*/}
                                  {/*    setSelectedScenarioId(scenario.id);*/}
                                  {/*    setSelectedScenario(scenario)*/}
                                  {/*}} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Smart*/}
                                  {/*    Add</Button>*/}
                                  <Button onClick={() => {
                                      const scenario = JobScenario.from({
                                          name: 'Scenario' + scenarios.length,
                                          jobs: allJobsAsArray
                                      });
                                      scenario.playground = true;
                                      setScenarios([...scenarios, scenario]);
                                      setSelectedScenarioId(scenario.id);
                                  }} type="primary" className="icon-button mr-2" icon={<PlusOutlined/>}>Add</Button>
                              </div>}
                          items={scenarios.map(scenario => {
                              return {
                                  key: scenario.id,
                                  label: scenario.name || 'Current',
                                  children:
                                      <>
                                          <Row gutter={[20, 20]}
                                               className={`Dashboards mb-4 ${showDashboards ? 'show' : 'hide'}`}>
                                              <Col xs={24} lg={6} onClick={() => {
                                                  setShowUnallocated(!showUnallocated);
                                              }}>
                                                  <Card bordered={false}
                                                        className="card-main card-stats card-stats-flex">
                                                      <BusPlus/>
                                                      <div>
                                                          <h4>Unallocated</h4>
                                                          <div className="text-lg">{!allUnAllocated ?
                                                              <SyncOutlined spin/> : allUnAllocated.length}</div>
                                                      </div>
                                                  </Card>
                                              </Col>
                                              <Col xs={24} lg={12}>
                                                  <Card bordered={false}
                                                        className="card-main card-stats card-stats-profit card-stats-flex">
                                                      <BusStat/>
                                                      <div>
                                                          <h4>Driver Hours</h4>
                                                          <div className="text-lg">{driverHours}</div>
                                                      </div>
                                                      <div>
                                                          <h4>Overtime</h4>
                                                          <div className="text-lg">{overtime}</div>
                                                      </div>
                                                      <div>
                                                          <h4>Overtime %</h4>
                                                          <div className="text-lg">{otpercentage}%</div>
                                                      </div>
                                                  </Card>
                                              </Col>
                                              <Col xs={24} lg={6}>
                                                  <Card bordered={false}
                                                        className="card-main card-stats card-stats-flex">
                                                      <BusQuote/>
                                                      <div>
                                                          <h4>Total Wages</h4>
                                                          <div className="text-lg">${wages.toFixed(2)}</div>
                                                      </div>
                                                  </Card>
                                              </Col>
                                          </Row>

                                          <div
                                              className="d-flex align-items-center justify-content-between filter-options-main">
                                              <div className="d-flex align-items-center" style={{gap: '10px'}}>
                                                  {/* <Button type="primary" className="icon-button" icon={<PlusOutlined/>} onClick={addNewBlock}>New
                            Block</Button> */}
                                                  <Button type="primary"
                                                          className={`icon-button btn-filled btn-error ${showUnallocated ? 'active' : ''}`}
                                                          onClick={() => {
                                                              setShowUnallocated(!showUnallocated);
                                                          }}><i>{!allUnAllocated ? <SyncOutlined
                                                      spin/> : allUnAllocated.length}</i> Unallocated</Button>
                                                  {showUnallocated && (
                                                      <div className="justify-content-end">
                                                          <Button onClick={() => {
                                                              setExpandedView(!expandedView);
                                                          }} type="primary"
                                                                  className={`icon-button ${expandedView ? 'active' : ''}`}>Expand
                                                              Unallocated</Button>
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="d-flex align-items-center">
                                                  <div className="justify-content-end w-separator">
                                                      <Search
                                                          id="basic-addon1"
                                                          style={{
                                                              width: 200,
                                                          }}
                                                          allowClear={true}
                                                          onChange={(e) => setFilterDebounced(e.target.value)}
                                                      />
                                                  </div>
                                                  <div className="filter-switches">
                                                      <FilterSwitches filterItems={filterItems} filterBy={filterBy}
                                                                      setFilterBy={setFilterBy}/>
                                                  </div>
                                              </div>
                                          </div>
                                          <RosteringGrid mode={mode}
                                                         selectedDate={selectedDate}
                                                         setSelectedDate={setSelectedDate}
                                                         showUnallocated={showUnallocated}
                                                         setShowUnallocated={setShowUnallocated}
                                                         expandedView={expandedView}
                                                         setExpandedView={setExpandedView}
                                                         selectedVehicles={allVehiclesArray}
                                                         selectedEmployees={selectedEmployees}
                                                         allUnAllocated={allUnAllocated}
                                                         allocatedJobs={jobs}
                                                         saveJob={async job => {
                                                             if (!selectedScenario.playground) {
                                                                 delete job.suggestion;
                                                                 await jobModelData.save(job);
                                                                 // const driverJob = job.clone();
                                                                 // driverJob.jobId = '_';
                                                                 // delete driverJob.recurrenceId;
                                                                 // driverJob.allocationType = 'driver';
                                                                 // driverJob.allocationId = staffMap[job.allocationId];
                                                                 // await jobModelData.save(driverJob);

                                                                 if (job.type === 'charter') {
                                                                     const charterToSave = {
                                                                         charterId: job.job.charterId,
                                                                         status: 'allocated'
                                                                     };
                                                                     await charterModelData.save(charterToSave);
                                                                 }
                                                             }
                                                             selectedScenario.addJob(job);
                                                             setScenarios([...scenarios]);
                                                             setJobs([...jobs, job]);
                                                             setAllUnAllocated(allUnAllocated => {
                                                                 return allUnAllocated.filter(j => j.jobId !== job.jobId);
                                                             });
                                                         }}
                                                         deleteJob={async job => {
                                                             if (!selectedScenario.playground) {
                                                                 await jobModelData.delete(job.jobId, true, true);
                                                                 if (job.type === 'charter') {
                                                                     const charterToSave = {
                                                                         charterId: job.job.charterId,
                                                                         status: 'scheduled'
                                                                     };
                                                                     await charterModelData.save(charterToSave);
                                                                 }
                                                             }
                                                             selectedScenario.removeJob(job);
                                                             setScenarios([...scenarios]);
                                                             setJobs(jobs.filter(j => j.jobId !== job.jobId));
                                                             setAllUnAllocated(allUnAllocated => {
                                                                 return [...allUnAllocated, job];
                                                             });
                                                             setSelectedDate(dayjs());
                                                         }}
                                          />
                                      </>
                              };
                          })}/> :
                    <LoadMessage message={'Loading Rosters...'}/>
                }

            </div>
        </>
    );
}

const DateSelector = ({initialDate, onChange}) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);

    const getDateLabel = (date) => {
        const today = dayjs();
        if (today.isSame(date, 'day')) {
            const tomorrow = today.add(1, 'days');
            return `Today - ${tomorrow.format('DD/MM/YYYY')}`;
        }
        const yesterday = today.subtract(1, 'days');
        if (yesterday.isSame(date, 'day')) {
            return `${yesterday.format('DD/MM/YYYY')} - Today`;
        }
        const nextDay = date.add(1, 'days');
        return `${date.format('DD/MM/YYYY')} - ${nextDay.format('DD/MM/YYYY')}`;
    };

    useEffect(() => {
        onChange(selectedDate);
    }, [selectedDate]);


    return (
        <div className="DateSelector">
            <button onClick={() => {
                setSelectedDate(selectedDate.subtract(1, 'days'));
            }}><Right/></button>
            <strong>{getDateLabel(selectedDate)}</strong>
            <button onClick={() => {
                setSelectedDate(selectedDate.add(1, 'days'));
            }}><Right/></button>
        </div>
    );
};

export default React.memo(Rosters);
