import React, {useState} from 'react'
import {FEATURE} from "../../model/features";
import {Col} from "react-bootstrap";
import EditVehicleView from "./EditVehicleView";
import {Vehicle, VEHICLE_STATUS} from "../../model/vehicle";
import {vehicleModelData} from "../../services/ModelService";
import EditableList from "../EditableList";

const filterItems = {
    Active: {showFn: (s) => ![VEHICLE_STATUS.repair, VEHICLE_STATUS.service].includes(s.vehicleStatus)},
    Servicing: {
        name: VEHICLE_STATUS.service,
        color: '#bc3baf',
        showFn: (s) => s.vehicleStatus === VEHICLE_STATUS.service
    },
    Repairing: {
        name: VEHICLE_STATUS.repair,
        color: '#18bc6a',
        showFn: (s) => s.vehicleStatus === VEHICLE_STATUS.repair
    },
};

export const DEFAULT_VEHICLE_RATE = 3;

const headerCols = <>
    <Col lg={2}>Name</Col>
    <Col lg={1}>Rego</Col>
    <Col lg={2}>Rate ($/km)</Col>
    <Col lg={1}>Capacity</Col>
    <Col lg={1}>Fuel</Col>
    <Col lg={1}>Tankage</Col>
    <Col lg={1}>Dist</Col>
</>

function Vehicles() {

    const [vehicles, setVehicles] = useState([])

    const editableView = ({item = new Vehicle(), ...props}) => {
        return <EditVehicleView
            modelData={vehicleModelData}
            editing={Vehicle.from(item)}
            createItemFn={item => new Vehicle(item || {})}
            validateFn={item => item.vehicleRego?.length > 0}
            {...props}
        />
    }

    const itemColumns = (item) => (<>
        <Col lg={2}>{item.vehicleName}</Col>
        <Col lg={1}>{item.vehicleRego}</Col>
        <Col lg={2}>$ {item.rate}</Col>
        <Col lg={1}>{item.capacity}</Col>
        <Col lg={1}>{item.fuelType}</Col>
        <Col lg={1}>{item.tankage}</Col>
        <Col lg={1}>{item.maxDistance}</Col>
    </>)

    return (
        <EditableList filterItems={filterItems} feature={FEATURE.dty} items={vehicles}
                      modelData={vehicleModelData} setItems={setVehicles}
                      modelCopyFn={item => Vehicle.from(item)} createItemFn={item => new Vehicle(item)}
                      editableView={editableView} itemColumns={itemColumns} headerCols={headerCols}
                      name={'vehicles'} tagHdr={'Status'} itemName={'vehicle'}
                      itemFilterFn={(vehicle, filter) => {
                          return vehicle.vehicleName?.toLowerCase()?.includes(filter) ||
                              vehicle.vehicleRego?.toLowerCase()?.includes(filter) ||
                              vehicle.capacity?.toString()?.includes(filter)
                      }}
        />
    )
}

export default React.memo(Vehicles)
