import React from 'react';
import {Button, Tooltip} from 'antd';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as Plus} from '../../assets/icons/Plus.svg';
import {ReactComponent as Break} from '../../assets/icons/Break.svg';
import {ReactComponent as Services} from '../../assets/icons/Services.svg';
import {ShiftBatRowNotePriority, ShiftBatRowType} from '../../model/shiftBat';
import {ReactComponent as DeadRunning} from '../../assets/icons/DeadRunning.svg';
import {ReactComponent as Warning} from '../../assets/icons/Warning.svg';
import {ReactComponent as Pin} from '../../assets/icons/Pin.svg';
import ShiftBatServiceRow from './ShiftBatServiceRow';
import './ShiftBat.css';
import RouteNumber from '../../components/RouteNumber';
import {SyncOutlined} from '@ant-design/icons';
import {uniq} from 'lodash/array';
import {useDroppable} from '@dnd-kit/core';
import {toHrsMinsSecs, toKmMs} from '../../libs/formatLib';
import {deadrunModelData} from '../../services/ModelService';

function ShiftBatRow({
                         allRoutes,
                         allStops,
                         shiftBatRow,
                         disableEdit,
                         editMode, printMode,
                         editRow,
                         deleteRow,
                         addRow,
                         onUpdate,
                         viewing,
                         updating, setUpdating,
                         setViewing,
                         setSelectedStop, setHighlightedRouteIds,
                         setFocusStop, selectedStop
                     }) {

    const {isOver, setNodeRef} = useDroppable({
        id: shiftBatRow.id,
    });
    const style = {
        color: isOver ? 'green' : undefined,
    };

    const getTitle = (shiftBatRow) => {
        return `${shiftBatRow.title}${shiftBatRow.type === ShiftBatRowType.dead && shiftBatRow.route ? ` (${toHrsMinsSecs(shiftBatRow.route.duration, false, true, 1) || '0s'}, ${toKmMs(shiftBatRow.route.distance)})` : ''}`;
    };

    return (
        <div ref={setNodeRef} style={style}
             onMouseOver={() => {
                 if (!editMode) {
                     return;
                 }
                 if (shiftBatRow.route) {
                     setHighlightedRouteIds(highlightedRouteIds => uniq(highlightedRouteIds.concat(shiftBatRow.route?.routeId)));
                 }
                 if (shiftBatRow.stop) {
                     setFocusStop(shiftBatRow.stop);
                 }
             }}
             onMouseLeave={() => {
                 if (!editMode) {
                     return;
                 }
                 if (shiftBatRow.route) {
                     setHighlightedRouteIds(highlightedRouteIds => highlightedRouteIds.filter(rId => viewing[shiftBatRow.id] || rId !== shiftBatRow.route?.routeId));
                 }
                 if (shiftBatRow.stop) {
                     setFocusStop(null);
                 }
             }}
             key={shiftBatRow.id}
             className={`sb-row ${shiftBatRow?.priority === ShiftBatRowNotePriority.high ? 'sb-important' : ''} ${viewing[shiftBatRow.id] ? 'sb-highlight' : ''} ${shiftBatRow.type === ShiftBatRowType.service ? 'sb-row-service' : ''} ${!shiftBatRow.isValid({
                 allStops,
                 allRoutes
             }) ? 'alert-danger' : ''}`}>
            <div
                className={`row d-flex align-items-center ml-0 mr-0`}>
                <div
                    className="col-1 sb-time">
                    {!shiftBatRow.hideTime && shiftBatRow.type !== ShiftBatRowType.dead &&
                        <span>{updating ? <SyncOutlined spin={true}/> : shiftBatRow.toTimeString()}</span>
                    }
                    {/*{editMode &&*/}
                    {/*    <Tooltip placement="bottom"*/}
                    {/*             title={shiftBatRow.hideTime ? "Show time field" : "Hide time field"}>*/}
                    {/*        <div className="sb-controls">*/}
                    {/*        <Button className="sb-edit-row" style={{marginLeft: '5px'}}*/}
                    {/*                icon={updating ? <SyncOutlined spin={true}/> :*/}
                    {/*                    shiftBatRow.hideTime ? <Time  /> : <NoTime />}*/}
                    {/*                disabled={updating}*/}
                    {/*                onClick={e => {*/}
                    {/*                    e.stopPropagation();*/}
                    {/*                    setUpdating(true)*/}
                    {/*                    shiftBatRow.hideTime = !shiftBatRow.hideTime*/}
                    {/*                    onUpdate(e, shiftBatRow)*/}
                    {/*                }}/>*/}
                    {/*        </div>*/}
                    {/*    </Tooltip>*/}
                    {/*}*/}
                </div>
                <div className="col-2 sb-icon">
                    <div className={`RouteIcon`}>
                        {shiftBatRow.type !== ShiftBatRowType.note && shiftBatRow.type !== ShiftBatRowType.stop &&
                        [ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(shiftBatRow.type) ?
                            <Break/> : shiftBatRow.type === ShiftBatRowType.dead ?
                                <DeadRunning/> : shiftBatRow.type === ShiftBatRowType.service ?
                                    <RouteNumber
                                        route={shiftBatRow.route}/> : shiftBatRow.type === ShiftBatRowType.stop ?
                                        <Pin/> : <></>}
                        {shiftBatRow?.priority === ShiftBatRowNotePriority.high ? <Warning/> : ''}
                    </div>
                </div>
                <div className={'col-9 d-flex RouteTitle align-items-center sb-info '} style={{cursor: 'pointer'}}
                     onClick={() => {
                         if (printMode) {
                             return;
                         }
                         if ([ShiftBatRowType.charter, ShiftBatRowType.service, ShiftBatRowType.dead].includes(shiftBatRow.type)) {
                             setViewing(viewing => ({...viewing, [shiftBatRow.id]: !viewing[shiftBatRow.id]}));
                         }
                         setFocusStop(null);
                     }}>

                    <div className={`RouteInfo`}>
                        {!shiftBatRow.isValid({allStops, allRoutes}) ?
                            <Tooltip
                                title={`${shiftBatRow.invalidReason({
                                    allStops,
                                    allRoutes
                                })}. Please ${shiftBatRow.type === ShiftBatRowType.dead ? ' regenerate the deadrun' : [ShiftBatRowType.stop, ShiftBatRowType.service].includes(shiftBatRow.type) ? ' delete and add it again' : ''}.`}><span
                                className="text-danger">Invalid row. {shiftBatRow.invalidReason({allStops, allRoutes})}. Please edit the shift bat{shiftBatRow.type === ShiftBatRowType.dead ? ' and regenerate the deadrun' :
                                [ShiftBatRowType.stop, ShiftBatRowType.service].includes(shiftBatRow.type) ? ` and delete this ${shiftBatRow.title} ${shiftBatRow.type} and add it again` : ''}.</span>
                            </Tooltip> :
                            <>
                                <h4 className="title">{getTitle(shiftBatRow)}</h4>
                                {shiftBatRow.description &&
                                    <span className={`RouteDetails`}>{shiftBatRow.description}</span>}
                            </>
                        }
                        {/*    {editMode &&*/}
                        {/*    <div className={`RouteDetails ml-2`}>*/}
                        {/*        {shiftBatRow.duration > 0 &&*/}
                        {/*            <div>{shiftBatRow.duration > 0 && toHrsMinsSecs(shiftBatRow.duration, false, true)}</div>*/}
                        {/*        }*/}
                        {/*        {shiftBatRow.distance > 0 &&*/}
                        {/*            <div>{shiftBatRow.distance > 0 && toKmMs(shiftBatRow.distance, 0)}</div>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>

                    {editMode &&
                        <div className="sb-controls">
                            {[ShiftBatRowType.service, ShiftBatRowType.charter].includes(shiftBatRow.type) &&
                                <Tooltip placement="bottom" title="Generate Left/Rights for stops">
                                    <Button className="sb-directions"
                                            icon={updating ? <SyncOutlined spin={true}/> :
                                                <Services/>}
                                            disabled={updating}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setUpdating(true);
                                                shiftBatRow.buildDirections(true).then(() => onUpdate(e, shiftBatRow));
                                            }}/>
                                </Tooltip>
                            }
                            {shiftBatRow.type === ShiftBatRowType.dead &&
                                <Tooltip placement="bottom" title="Regenerate deadrun">
                                    <Button className="sb-directions"
                                            icon={updating ? <SyncOutlined spin={true}/> :
                                                <Services/>}
                                            disabled={updating}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setUpdating(true);
                                                shiftBatRow.reset({deadrunModelData}).then(() => {
                                                    onUpdate(e, shiftBatRow);
                                                });
                                            }}/>
                                </Tooltip>
                            }
                            {shiftBatRow.isValid({allStops, allRoutes}) ?
                                <Button
                                    className={`sb-edit-row ${[ShiftBatRowType.dead, ShiftBatRowType.charter].includes(shiftBatRow.type) ? 'single' : ''}`}
                                    disabled={updating || disableEdit}
                                    icon={updating ? <SyncOutlined spin={true}/> : <Edit/>}
                                    onClick={e => {
                                        e.stopPropagation();
                                        editRow(e, shiftBatRow);
                                    }}/> : <></>
                            }
                            {shiftBatRow.type !== ShiftBatRowType.dead &&
                                <Button className="sb-delete-row"
                                        icon={<Trash/>}
                                        disabled={updating || disableEdit}
                                        onClick={e => {
                                            e.stopPropagation();
                                            deleteRow(e, shiftBatRow);
                                        }}/>}
                        </div>
                    }
                </div>
            </div>
            {[ShiftBatRowType.service, ShiftBatRowType.charter].includes(shiftBatRow.type) && viewing[shiftBatRow.id] &&
                <ShiftBatServiceRow key={'SBServR' + shiftBatRow.id} shiftBatRow={shiftBatRow} editMode={editMode}
                                    onUpdate={onUpdate}
                                    onSave={onUpdate} setSelectedStop={setSelectedStop} printMode={printMode}
                                    selectedStop={selectedStop} setFocusStop={setFocusStop}/>}
            {editMode && shiftBatRow.type !== ShiftBatRowType.dead &&
                <Button className="sb-add-row" disabled={updating || disableEdit}
                        icon={<Plus/>}
                        onClick={e => {
                            e.stopPropagation();
                            addRow();
                        }}/>}
        </div>
    );

}

export default React.memo(ShiftBatRow);
