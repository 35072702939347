import {useEffect, useState} from 'react';
import {shiftBatModelData} from '../services/ModelService';
import {keyBy, values} from 'lodash';

const useAllShiftBats = ({sortFn} = {}) => {
    const [allShiftBats, setAllShiftBats] = useState(null);
    const [sortedShiftBats, setSortedShiftBats] = useState(null);
    const [allShiftBatsArray, setAllShiftBatsArray] = useState(null);

    useEffect(() => {
        const listener = shiftBatModelData.addListener({
            setterFn: setAllShiftBats, loaded: shifts => {
                setAllShiftBats(shifts);
            }
        });

        return () => {
            if (listener)
                shiftBatModelData.removeListener(listener);
        };
    }, []);

    useEffect(() => {
        if (!allShiftBats) {
            return;
        }
        if (!sortFn) {
            sortFn = (sb1, sb2) => sb1.getStartTime() - sb2.getStartTime();
        }
        let shiftBatsArray = values(allShiftBats).sort(sortFn);
        setSortedShiftBats(keyBy(shiftBatsArray, 'shiftBatId'));
        setAllShiftBatsArray(shiftBatsArray);

    }, [allShiftBats, sortFn, setSortedShiftBats, setAllShiftBatsArray]);

    return {allShiftBats: sortedShiftBats, allShiftBatsArray};
};

export default useAllShiftBats;