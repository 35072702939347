import React, {useCallback, useEffect, useState} from 'react';
import GridLayout from 'react-grid-layout';
import {Avatar, Button, Modal, Tag} from 'antd';
import 'react-grid-layout/css/styles.css';
import './Rosters.css';
import dayjs from 'dayjs';
import {Job} from '../../model/job';
import {find} from 'lodash';
import {ReactComponent as Close} from '../../assets/icons/Trash.svg';
import {toHrsMinsSecs, toKmMs} from '../../libs/formatLib';
import {SyncOutlined} from '@ant-design/icons';

const GRID_WIDTH = 3000;

const ROSTER_STATUSES = {
    'completed': {color: 'success', text: 'Completed'},
    'inprogress': {color: 'blue', text: 'In Progress'},
    'scheduled': {color: 'error', text: 'Scheduled'},
    'default': {color: 'blue', text: 'Scheduled'},
};

const RosteringGrid = ({
                           mode,
                           selectedDate,
                           setSelectedDate,
                           showUnallocated,
                           setShowUnallocated,
                           expandedView,
                           setExpandedView,
                           selectedVehicles,
                           selectedEmployees,
                           allUnAllocated,
                           allocatedJobs,
                           saveJob,
                           deleteJob
                       }) => {

    const [filteredLayout, setFilteredLayout] = useState([]);

    const [selectedUnallocated, setSelectedUnallocated] = useState(null);
    const [unallocatedLayout, setUnallocatedLayout] = useState([]);
    const [unallocatedJobs, setUnallocatedJobs] = useState([]);
    const [suggestedJobs, setSuggestedJobs] = useState(null);
    const [calculatingSuggestions, setCalculatingSuggestions] = useState(false);


    const [vehicleJobs, setVehicleJobs] = useState([]);
    const [vehicleLayout, setVehicleLayout] = useState([]);
    const [staffJobs, setStaffJobs] = useState([]);
    const [staffLayout, setStaffLayout] = useState([]);
    const [allocationMode, setAllocationMode] = useState(false);

    useEffect(() => {
        console.log('Allocated jobs: ', allocatedJobs.length);
        const vehicles = allocatedJobs?.filter(item => item.allocationType === 'vehicle').map((item) => {
            const status = getShiftStatus(item);
            return new Job({
                key: item.getId(), // required for layout mapping (layout.key)
                avatar: 'JS',
                status: status.text,
                statusColor: status.color,
                ...item,
            });
        }) || [];
        const staff = allocatedJobs?.filter(item => item.allocationType === 'employee').map(item => {
            const status = getShiftStatus(item);
            return new Job({
                key: item.getId(), // required for layout mapping (layout.key)
                time: `${item.getStartTime({asDayJs: true}).format('HH:mm')} - ${item.getEndTime({asDayJs: true}).format('HH:mm')}`,
                status: status.text,
                statusColor: status.color,
                ...item
            });
        }) || [];
        setVehicleJobs(vehicles);
        setStaffJobs(staff);
    }, [allocatedJobs]);

    useEffect(() => {
        if (!allUnAllocated) {
            return;
        }
        const allUnallocatedItems = allUnAllocated.map((item) => new Job({...item, key: item.getId()}));
        const validUnallocatedItems = allUnallocatedItems.map((item) => {
            if (item.getDuty()) {
                return item;
            }
        }).filter(item => !!item);
        setUnallocatedJobs(validUnallocatedItems);


    }, [setUnallocatedJobs, allUnAllocated]);

    useEffect(() => {
        const vLayout = generateLayoutFromJobs(vehicleJobs.concat(suggestedJobs || []), allocationMode);
        setVehicleLayout(vLayout);
        const sLayout = generateLayoutFromJobs(staffLayout);
        setStaffLayout(sLayout);
        setFilteredLayout(mode === 'vehicle' ? vLayout : mode === 'staff' ? sLayout : []);
    }, [vehicleJobs, staffJobs, suggestedJobs, allocationMode, selectedVehicles, selectedEmployees, mode]);


    const getShiftStatus = (item) => {
        const now = dayjs();
        if (now.isBefore(item.getStartTime({asDayJs: true}))) {
            return ROSTER_STATUSES['scheduled'];
        } else if (now.isAfter(item.getEndTime({asDayJs: true}))) {
            return ROSTER_STATUSES['completed'];
        } else if (now.isAfter(item.getStartTime({asDayJs: true})) && now.isBefore(item.getEndTime({asDayJs: true}))) {
            return ROSTER_STATUSES['inprogress'];
        } else {
            return ROSTER_STATUSES['default'];
        }
    };


    const generateLayoutFromJobs = (jobs, allocationMode) => {
        return jobs.map((job) => {
            let startTime = job.getStartTime({asDayJs: true});
            let endTime = job.getEndTime({asDayJs: true});
            if (allocationMode && !job.suggestion) {
                startTime = job.getDuty().getActualStartTime({asDayJs: true});
                endTime = job.getDuty().getActualEndTime({asDayJs: true});
            }
            const {
                startGridPosition,
                gridLength
            } = getGridPositionAndLengthFromTimestamps(startTime, endTime);
            const lane = getLaneForJob(job.allocationId);
            return {
                i: job.key,
                x: startGridPosition,
                y: lane,
                w: gridLength,
                h: 1,
                static: true,
                isDraggable: false,
                isResizable: false,
            };
        });
    };

    const getLaneForJob = (laneId) => {
        const lanes = mode === 'vehicle' ? selectedVehicles : mode === 'staff' ? selectedEmployees : [];
        const key = mode === 'vehicle' ? 'vehicleId' : mode === 'staff' ? 'employeeId' : '';
        const lane = lanes.find(laneItem => laneItem[key] === laneId);
        return lane ? lanes.indexOf(lane) : 0;
    };

    const getGridPositionAndLengthFromTimestamps = (startTime, endTime) => {
        // Extract hours and minutes from the start timestamp
        const startHours = startTime.hour();
        const startMinutes = startTime.minute();

        // Calculate the number of 15-minute intervals from the start of the day for the start time
        const totalStartMinutes = (startHours * 60) + startMinutes;
        const startGridPosition = Math.floor(totalStartMinutes / 15);

        // Extract hours and minutes from the end timestamp
        const endHours = endTime.hour();
        const endMinutes = endTime.minute();

        // Calculate the number of 15-minute intervals from the start of the day for the end time
        const totalEndMinutes = (endHours * 60) + endMinutes;
        const endGridPosition = Math.floor(totalEndMinutes / 15);

        // Calculate the length of the grid item (number of slots it occupies)
        const gridLength = endGridPosition - startGridPosition;

        return {startGridPosition, gridLength};
    };

    const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
        console.log('>>>> item onDragStop : ', oldItem, newItem);
    };

    const onLayoutChange = (newLayout) => {
        console.log('>>>> layout has been updated!!!', newLayout);
    };

    useEffect(() => {
        let layout = [];
        unallocatedJobs.map((job) => {
            const {
                startGridPosition,
                gridLength
            } = getGridPositionAndLengthFromTimestamps(job.getStartTime({asDayJs: true}), job.getEndTime({asDayJs: true}));
            layout.push({
                i: job.key,
                x: startGridPosition,
                y: 0,
                w: gridLength,
                h: 1,
                static: true,
                isDraggable: false,
                isResizable: false,
            });
        });
        setUnallocatedLayout(layout);
        console.log('unallocated jobs layout :', layout);
    }, [unallocatedJobs]);

    useEffect(() => {
        const newLayout = [];
        if (selectedUnallocated) {
            const {
                startGridPosition,
                gridLength
            } = getGridPositionAndLengthFromTimestamps(selectedUnallocated.getStartTime({asDayJs: true}), selectedUnallocated.getEndTime({asDayJs: true}));
            newLayout.push({
                i: selectedUnallocated.key,
                x: startGridPosition,
                y: 0,
                w: gridLength,
                h: 1,
                static: true,
                isDraggable: false,
                isResizable: false,
            });
            // todo - filter layout logic goes here for dead run calculations
            const layout = mode === 'vehicle' ? vehicleLayout : mode === 'staff' ? staffLayout : [];
            layout.map((item) => {
                newLayout.push({
                    ...item,
                    y: item.y + 1
                });
            });
            setFilteredLayout(newLayout);
        } else {
            // todo - make sure the layout is updated when a job item has been saved!!
            const layout = mode === 'vehicle' ? vehicleLayout : mode === 'staff' ? staffLayout : [];
            setFilteredLayout(layout);
        }
    }, [selectedUnallocated, setFilteredLayout, vehicleLayout, staffLayout, mode]);


    useEffect(() => {
        console.log('filteredLayout, selectedUnallocated :', filteredLayout, selectedUnallocated);
    }, [filteredLayout, selectedUnallocated]);

    const onSelectUnallocated = useCallback((item) => {
        const isSameItemSelected = selectedUnallocated && selectedUnallocated.key === item.key;
        const newSelection = isSameItemSelected ? null : item;
        setSelectedUnallocated(newSelection);
        setAllocationMode(newSelection !== null);
    }, [selectedUnallocated, setSelectedUnallocated, setAllocationMode]);

    useEffect(() => {
        if (selectedUnallocated) {
            setCalculatingSuggestions(true);
            let filteredVehicles = selectedVehicles.map(vehicle => vehicle.clone());
            vehicleJobs.forEach(j => find(filteredVehicles, {vehicleId: j.allocationId}).addJob(j));
            Promise.all(filteredVehicles.map(async vehicle => {
                await vehicle.addJobOption(selectedUnallocated, selectedUnallocated.type, selectedDate);
                return vehicle;
            })).then(filteredVehicles => {
                filteredVehicles = filteredVehicles.filter(vehicle => vehicle.jobOption);
                filteredVehicles.sort((j1, j2) =>
                    j1.jobOption.stats.total.distance - j2.jobOption.stats.total.distance);

                setSuggestedJobs(filteredVehicles.map(vehicle => {
                    vehicle.jobOption.key = vehicle.vehicleId + vehicle.jobOption.getId();
                    vehicle.jobOption.suggestion = true;
                    return vehicle.jobOption;
                }));
                setCalculatingSuggestions(false);
            });
        } else {
            setSuggestedJobs(null);
        }
    }, [selectedUnallocated, setSuggestedJobs, selectedVehicles, vehicleJobs, setCalculatingSuggestions]);

    const [selectedGridItem, setSelectedGridItem] = useState(null);

    const onGridItemClick = (item) => {
        const isSameItemSelected = selectedGridItem && selectedGridItem.key === item.key;
        const newSelection = isSameItemSelected ? null : item;
        setSelectedGridItem(newSelection);
        setAllocationMode(newSelection !== null);
    };

    return (
        <>
            <UnAllocatedModal
                isOpen={expandedView}
                onClose={() => {
                    setExpandedView(false);
                    setShowUnallocated(false);
                }}
                layout={unallocatedLayout}
                unallocatedJobs={unallocatedJobs}
                onSelect={onSelectUnallocated}
            />


            {showUnallocated && (
                <div className={''}>
                    <UnAllocatedGrid unallocatedJobs={unallocatedJobs} layout={unallocatedLayout}
                                     expandedView={expandedView}
                                     onSelect={onSelectUnallocated} onClose={() => {
                        setShowUnallocated(false);
                    }}/>
                </div>
            )}
            <div className="GridLayout">
                <div className="GridLabel">
                    {selectedUnallocated && (
                        <div key="unallocated-item">Unallocated Job</div>
                    )}
                    {mode === 'vehicle' && selectedVehicles && selectedVehicles.map((item) => (
                        <div key={item.vehicleId}>{item.vehicleName}</div>
                    ))}
                    {mode === 'staff' && selectedEmployees && selectedEmployees.map((item) => (
                        <div key={item.driverId}>{`${item.firstName} ${item.lastName}`}</div>
                    ))}
                </div>
                <AllocatedGrid layout={filteredLayout}
                               onLayoutChange={onLayoutChange}
                               onDragStop={onDragStop}
                               selectedUnallocated={selectedUnallocated}
                               jobs={mode === 'vehicle' ? vehicleJobs : mode === 'staff' ? staffJobs : []}
                               onSelectUnallocated={onSelectUnallocated}
                               onGridItemClick={onGridItemClick}
                               allocationMode={allocationMode}
                               suggestedJobs={suggestedJobs}
                               calculatingSuggestions={calculatingSuggestions}
                               onSelectSuggestedRoster={(data) => {
                                   console.log(data);
                                   saveJob(data);
                                   setSelectedUnallocated(null);
                               }}
                               onDeleteJob={(data) => {
                                   console.log(data);
                                   deleteJob(data);
                               }}
                />
            </div>
        </>
    )
        ;
};

const calculateDeadrunPerc = (item, leading) => {
    const deadrunDuration = item.getLeadingDeadrun()?.duration + item.getTrailingDeadrun()?.duration;
    const deadrun = leading ? item.getLeadingDeadrun() : item.getTrailingDeadrun();
    const totalDuration = item.getDuration();
    return (deadrun.duration / (totalDuration + deadrunDuration) * 100) + '%';
};

const AllocatedGrid = ({
                           layout,
                           onLayoutChange,
                           onDragStop,
                           selectedUnallocated,
                           jobs,
                           onSelectUnallocated,
                           onGridItemClick,
                           allocationMode,
                           suggestedJobs,
                           onSelectSuggestedRoster,
                           onDeleteJob,
                           calculatingSuggestions
                       }) => {
    return (
        <div className="GridWrap">
            <div className="GridItems" style={{width: GRID_WIDTH}}>
                <GridLayout
                    layout={layout}
                    cols={96}
                    rowHeight={80}
                    width={GRID_WIDTH}
                    onLayoutChange={onLayoutChange}
                    onDragStop={onDragStop}
                    allowOverlap={false}
                >
                    {selectedUnallocated && (
                        <div key={selectedUnallocated.key}
                             className={'unallocated-rosters'}
                             onClick={() => {
                                 onSelectUnallocated(selectedUnallocated);
                             }}>
                            {calculatingSuggestions && <SyncOutlined spin/>}
                            <strong>{selectedUnallocated.getName()}</strong>
                            {/*<span>{`${selectedUnallocated.getActualStartTime({asDayJs: true}).format('HH:mm')} - ${selectedUnallocated.getActualEndTime({asDayJs: true}).format('HH:mm')}`}</span>*/}
                            <span>{`${selectedUnallocated.getStartTime({asDayJs: true}).format('HH:mm')} - ${selectedUnallocated.getEndTime({asDayJs: true}).format('HH:mm')}`}</span>
                        </div>
                    )}
                    {jobs && jobs.map((item) => (
                        <div key={item.key}
                             className={`react-grid-item react-draggable cssTransforms react-resizable-hide react-resizable allocated-rosters ${!allocationMode ? 'ShowingDeadRunInfo' : ''}`}
                             onClick={() => {
                                 onGridItemClick(item);
                             }}>
                            {!allocationMode &&
                                <div className="DeadRunInfo" style={{width: calculateDeadrunPerc(item, true)}}>
                                    <strong
                                        className={'deadrun-distance'}>{toKmMs(item.getLeadingDeadrun().distance)}</strong>
                                    <strong
                                        className={'deadrun-time'}>{toHrsMinsSecs(item.getLeadingDeadrun().duration, false, true)}</strong>
                                </div>
                            }
                            <Avatar size={40} style={{backgroundColor: '#C1C5CE'}}>{item.avatar}</Avatar>
                            <div>
                                <strong>{item.getName()}
                                </strong>
                                {allocationMode ?
                                    <span>{`${item.getDuty().getActualStartTime({asDayJs: true}).format('HH:mm')} - ${item.getDuty().getActualEndTime({asDayJs: true}).format('HH:mm')}`}</span> :
                                    <span>{`${item.getStartTime({asDayJs: true}).format('HH:mm')} - ${item.getEndTime({asDayJs: true}).format('HH:mm')}`}</span>}
                            </div>
                            <div className="simple-tags">
                                <Tag color={item.statusColor}>{item.status}</Tag>
                                {/*{allocationMode &&*/}
                                <Button icon={<Close/>}
                                        className={'ant-btn ant-btn-primary icon-button btn-red '}
                                        style={{marginLeft: 5}}
                                        onClick={async () => {
                                            await onDeleteJob(item);
                                        }}/>
                                {/*}*/}
                            </div>
                            {!allocationMode &&
                                <div className="DeadRunInfo" style={{width: calculateDeadrunPerc(item, false)}}>
                                    <strong
                                        className={'deadrun-distance'}>{toKmMs(item.getTrailingDeadrun().distance)}</strong>
                                    <strong
                                        className={'deadrun-time'}>{toHrsMinsSecs(item.getTrailingDeadrun().duration, false, true)}</strong>
                                </div>
                            }
                        </div>
                    ))}
                    {allocationMode && suggestedJobs && suggestedJobs.map((item) => {
                        console.log(item);
                        return (
                            <div key={item.key}
                                 className="react-grid-item react-draggable cssTransforms react-resizable-hide react-resizable suggested-rosters"
                                 onClick={() => {
                                     onSelectSuggestedRoster(item);
                                 }}>
                                {item.leadingDeadrun &&
                                    <div className="DeadRunInfo" style={{width: calculateDeadrunPerc(item, true)}}>
                                        <strong
                                            className={`deadrun-time ${item.stats?.leading.distance < 0 ? 'green' : 'red'}`}>{item.stats?.leading.distance !== 0 ? toKmMs(item.stats?.leading.distance, 0) : ''}</strong>
                                    </div>
                                }
                                <Avatar size={40} style={{backgroundColor: '#C1C5CE'}}>{item.avatar}</Avatar>
                                <div>
                                    <strong>{item.getName()}</strong>
                                    {/*<span>{`${item.getActualStartTime().format('HH:mm')} - ${item.getActualEndTime().format('HH:mm')}`}</span>*/}
                                    <span>{`${item.getStartTime({asDayJs: true}).format('HH:mm')} - ${item.getEndTime({asDayJs: true}).format('HH:mm')}`}</span>
                                </div>
                                {item.trailingDeadrun &&
                                    <div className="DeadRunInfo" style={{width: calculateDeadrunPerc(item, true)}}>
                                        <strong
                                            className={`deadrun-time ${item.stats?.leading.distance <= 0 ? 'green' : 'red'}`}>{item.stats?.trailing.distance !== 0 ? toKmMs(item.stats?.trailing.distance, 0) : ''}</strong>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </GridLayout>
            </div>
            <div className="GridLines" style={{width: GRID_WIDTH}}>
                {[...Array(24)].map((_, i) => <div key={i}>{i}:00</div>)}
            </div>
        </div>);
};

const UnAllocatedModal = ({isOpen, onClose, layout, unallocatedJobs, onSelect}) => (
    <>
        <Modal
            open={isOpen}
            afterOpenChange={open => {
            }}
            width="90%"
            style={{
                top: 50,
            }}
            title={`Unallocated Driver Duties & Charters`}
            onCancel={onClose}
            onClose={onClose}
            closable={true}
            destroyOnClose
            footer={<></>}
        >
            <>
                <div className="mb-4"><p>Select an Unallocated Driver Duty or Charter to allocate</p></div>
                <UnAllocatedGrid unallocatedJobs={unallocatedJobs} layout={layout} onSelect={onSelect}
                                 onClose={onClose} expandedView={isOpen}/>
            </>
        </Modal>
    </>
);

const UnAllocatedGrid = ({layout, expandedView, unallocatedJobs, onSelect, onClose}) => {

    const [height, setHeight] = useState('100%');
    const minHeight = expandedView ? 'calc(75vh)' : 444;

    const onLayoutChange = (layout) => {
        const maxY = layout.reduce((max, obj) => (obj.y > max ? obj.y : max), -Infinity) + 1;
        const height = (maxY * 65) + 20;
        setHeight(height > 444 ? height : minHeight);
    };

    return (
        <div className="GridLayout UnallocatedGrid">
            <div className="GridWrap">
                <div className="GridItems" style={{width: GRID_WIDTH}}>
                    <GridLayout
                        layout={layout}
                        cols={96}
                        rowHeight={46}
                        width={GRID_WIDTH}
                        onLayoutChange={onLayoutChange}
                    >
                        {unallocatedJobs && unallocatedJobs.map(unAllocated => (
                            <div key={unAllocated.key} onClick={() => {
                                onSelect(unAllocated);
                                onClose();
                            }}>
                                <strong>{unAllocated?.getName()}</strong>
                                {/*<span>{`${unAllocated.getActualStartTime().format('HH:mm')} - ${unAllocated.getActualEndTime().format('HH:mm')}`}</span>*/}
                                <span>{`${unAllocated.getStartTime().format('HH:mm')} - ${unAllocated.getEndTime().format('HH:mm')}`}</span>
                            </div>
                        ))}
                    </GridLayout>
                </div>
                <div className="GridLines" style={{width: GRID_WIDTH, height: height}}>
                    {[...Array(24)].map((_, i) => <div key={i}>{i}:00</div>)}
                </div>
            </div>
        </div>
    );
};

export default RosteringGrid;
