import {Button} from 'antd';
import {ulid} from 'ulid';
import ShiftBatRowEdit from './ShiftBatRowEdit';
import ShiftBatRow from './ShiftBatRow';
import {ShiftBatRowType} from '../../model/shiftBat';
import React, {useCallback, useEffect, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {ReactComponent as Plus} from '../../assets/icons/Plus.svg';
import ShiftBatRowPrint from './ShiftBatRowPrint';
import ShiftBatRowPrintSm from './ShiftBatRowPrintSm';
import {deadrunModelData} from '../../services/ModelService';

function ShiftBatRows({
                          shiftBat,
                          setEditing,
                          editing,
                          allRoutes,
                          schedules,
                          printLayout,
                          printMode, setAllStops,
                          updating, setUpdating,
                          allStops,
                          onUpdate, onCreate,
                          onCancel,
                          deleteRow, handleNewDeadrun,
                          fetching, setHighlightedRouteIds,
                          editMode, viewing, setViewing,
                          setEditRouteRow, disableEditForRow,
                          selectedStop, setOnMapSelection,
                          setSelectedStop, setFocusStop,
                          allowRoutes, charter
                      }) {

    const [draftShiftBat, setDraftShiftBat] = useState(null);
    useEffect(() => {
        if (!shiftBat) {
            return;
        }
        setDraftShiftBat(shiftBat);
        // setDraftShiftBat(shiftBat.clone()); //Removed clone as it was causing Deadruns to not show until another update
    }, [shiftBat, setDraftShiftBat]);

    const addRow = useCallback(async (draftShiftBat, idx = null, row = {time: -1, type: ShiftBatRowType.noteTimed}) => {
        const id = ulid();
        console.log('New row...', id);
        const newRow = draftShiftBat.addRow({id, ...row}, idx);
        if (newRow.updateRow) {
            await newRow.updateRow({deadrunModelData});
        }
        setDraftShiftBat({...draftShiftBat});
        setEditing(editing => ({...editing, [id]: true}));
    }, [setEditing, setDraftShiftBat]);

    return (
        <>
            {editMode && !shiftBat?.rows?.length &&
                <div className="sb-first-row">
                    <Button className="icon-button sb-add-btn"
                            disabled={disableEditForRow()}
                            onClick={async () => await addRow(draftShiftBat)}><PlusOutlined/> New row</Button>
                </div>
            }
            <div className={`sb-items-wrap ${editMode ? 'edit-mode' : ''}`}>
                {editMode &&
                    <div className="add-initial-row">
                        <Button className="sb-add-row"
                                icon={<Plus/>}
                                onClick={async () => await addRow(draftShiftBat)}/>
                    </div>
                }
                {draftShiftBat && draftShiftBat.rows.map((row, idx) => {
                    return editMode && editing[row.id] ?
                        <ShiftBatRowEdit key={'SBRE-' + row.id} shiftBatRow={row}
                                         allRoutes={allRoutes} editing={editing} schedules={schedules}
                                         updating={updating} setUpdating={setUpdating} setEditRouteRow={setEditRouteRow}
                                         allStops={allStops} setAllStops={setAllStops}
                                         onUpdate={(e, row, keepEditing) => onUpdate(e, row, idx, keepEditing)}
                                         onCreate={(e, row, keepEditing) => onCreate(e, row, idx, keepEditing)}
                                         onCancel={onCancel} isLoading={fetching}
                                         handleNewDeadrun={handleNewDeadrun} setOnMapSelection={setOnMapSelection}
                                         allowRoutes={allowRoutes} charter={charter}/> :
                        printLayout === 'ltwo' ?

                            <ShiftBatRowPrintSm key={'SBR-' + row.id} shiftBatRow={row} editMode={false}
                                                viewing={viewing}
                                                printMode={printMode}/> :
                            printMode?.length ?
                                <ShiftBatRowPrint key={'SBR-' + row.id} shiftBatRow={row} editMode={false}
                                                  viewing={viewing}
                                                  printMode={printMode}/>
                                :
                                <ShiftBatRow key={'SBR-' + row.id} allStops={allStops} allRoutes={allRoutes}
                                             shiftBatRow={row} editMode={editMode} printMode={printMode}
                                             updating={updating} setUpdating={setUpdating}
                                             setSelectedStop={setSelectedStop}
                                             selectedStop={selectedStop} editing={editing}
                                             disableEdit={disableEditForRow()}
                                             viewing={viewing} setViewing={setViewing} setEditRouteRow={setEditRouteRow}
                                             setFocusStop={setFocusStop} setHighlightedRouteIds={setHighlightedRouteIds}
                                             allowRoutes={allowRoutes} charter={charter}
                                             editRow={(e, row) => {
                                                 if ([ShiftBatRowType.dead, ShiftBatRowType.charter].includes(row.type)) {
                                                     setEditRouteRow(row);
                                                 }
                                                 setEditing(editing => ({
                                                     ...editing,
                                                     [row.id]: true
                                                 }));
                                             }}
                                             deleteRow={deleteRow}
                                             addRow={async () => addRow(draftShiftBat, idx + 1)}
                                             onUpdate={(e, row) => onUpdate(e, row, idx)
                                             }/>;
                })}
            </div>
        </>
    );

}

export default React.memo(ShiftBatRows);
