import useJobsForDate from "./useJobsForDate";
import useShiftBatsForDate from "./useShiftBatsForDate";
import {useEffect, useState} from "react";
import useChartersForDate from "./useChartersForDate";

const useUnallocated = ({date}) => {
    const {allJobsAsArray} = useJobsForDate({date});
    const {chartersForDate} = useChartersForDate({date});
    const {shiftBatsForDate} = useShiftBatsForDate({date, validOnly: false});
    const [unallocatedShiftBats, setUnallocatedShiftBats] = useState([]);
    const [unallocatedCharters, setUnallocatedCharters] = useState([]);

    useEffect(() => {
        if (allJobsAsArray && chartersForDate && shiftBatsForDate) {
            setUnallocatedCharters(chartersForDate.filter(charter => allJobsAsArray.filter(job => job.type === 'charter').every(job => job.typeId !== charter.charterId)))
            setUnallocatedShiftBats(shiftBatsForDate.filter(sb => allJobsAsArray.filter(job => job.type === 'shiftbat').every(job => job.typeId !== sb.shiftBatId)))
        }
    }, [allJobsAsArray, chartersForDate, shiftBatsForDate])

    return {
        unallocatedCharters,
        unallocatedShiftBats
    };
}

export default useUnallocated;
