import {Select, Tabs} from 'antd';
import './Trips.css';
import React, {useCallback, useEffect, useState} from 'react';
import StopTimes from './StopTimes';
import {cloneDeep, find, noop, values} from 'lodash';
import {Col, Row} from 'react-bootstrap';
import {useAppContext} from '../libs/contextLib';
import {schedule_options} from '../model/schedule';
import TimeField from '../libs/timeField';
import dayjs from '../dayjs';
import {dayjsToSecsSinceMidnight} from '../model/timeFilter';
import {BusRoute} from '../model/busRoute';
import {Tooltip} from 'antd/lib';
import {keyBy} from 'lodash/collection';
import TripDetailsModal from '../components/TripDetailsModal';
import {driverModelData, vehicleModelData} from '../services/ModelService';

const {TabPane} = Tabs;

function Trips({
                   route, setRoute, allStops, schedules, setFocusStop, setSelectedStop,
                   selectedStop, selectedScheduleId, setUpdated = noop, immutable = false,
                   operator, setSelectedTripId, validationHelper = {global: []},
                   focusTransfers, setFocusTransfers, showSeconds, selectedTripId, buildToStop,
                   cumulativeUpdate, setShowNewStop, showNewStop, showAllStops, editingRoute, charter = false
               }) {

    const {isAdmin} = useAppContext();
    // const [selectedTripId, setSelectedTripId] = useState(null);
    // eslint-disable-next-line
    const [vehicles, setVehicles] = useState([]);
    // eslint-disable-next-line
    const [drivers, setDrivers] = useState([]);

    const schedulesById = keyBy(schedules, 'scheduleId');

    const setTrip = useCallback((trip) => {
        setRoute(route => {
            route = route.clone();
            const tripIdx = route.services.findIndex(t => t.tripId === trip.tripId);
            route.services[tripIdx] = trip;
            route = new BusRoute(route);
            route.setBaseStops(allStops, cloneDeep(trip.stopTimes));
            // console.log(route.stops);
            return route;
        });
    }, [setRoute, allStops]);

    const getTabItems = useCallback(() => route.services?.filter(trip => !selectedScheduleId || trip.scheduleIds.includes(selectedScheduleId))
        .sort((t1, t2) => (t1.stopTimes[0]?.departSecs) - (t2.stopTimes[0]?.departSecs))
        .map(trip => {
                const obsoleteTrip = trip.hasObsoleteCalendar(schedulesById);
                const tripInvalid = (validationHelper.isTripInvalid && validationHelper.isTripInvalid(trip.tripId)) || obsoleteTrip;
                return {
                    key: trip.tripId,
                    closable: false,
                    label: <Tooltip
                        title={tripInvalid ? 'This trip has validation errors or warnings.' :
                            !trip.stopTimes?.length ? 'Please add stops to set the start time.' : 'Set the start time of the service.'}>
                        <TimeField
                            value={trip.getStartTime(showSeconds)}
                            disabled={!trip.stopTimes?.length}
                            style={{width: showSeconds ? 58 : 48}} showSeconds={showSeconds}
                            className={`ant-input ant-input-sm inputtext-small text-center ${tripInvalid ? 'error' : ''}`}
                            onChange={(e, value) => {
                                if (!value) {
                                    return;
                                }
                                value = dayjs(value, showSeconds ? 'HH:mm:ss' : 'HH:mm');
                                if (!value.isValid()) {
                                    return;
                                }
                                const newStartSecs = dayjsToSecsSinceMidnight(value);
                                if (trip.stopTimes?.length) {
                                    trip.setStartTime(newStartSecs);
                                    setTrip(trip);
                                }
                            }}/></Tooltip>,
                    children: <> {!immutable && <>
                        {isAdmin && process.env.REACT_APP_VERBOSE &&
                            <Row>
                                <Col lg={12}><span
                                    className="font-weight-bolder small">Trip ID: {trip.tripId}</span></Col>
                            </Row>
                        }
                        {!charter && <Row>
                            <Col lg={12}><span
                                className="info-heading">Operating Calendars</span>
                            </Col>
                        </Row>}
                        {!charter && <Row className="mb-1">
                            <Col className="pr-1">
                                <Tooltip
                                    title={!trip.scheduleIds.length ? 'Please provide at least one Calendar for this service.' : obsoleteTrip ? 'There is an expired Calendar for this service.' : null}><Select
                                    className={`w-100`}
                                    status={validationHelper?.global?.some(m => m.type === 'schedule' && m.tripId === trip.tripId) || obsoleteTrip ? 'error' : ''}
                                    placeholder="Operating calendars"
                                    onChange={scheduleIds => {
                                        trip.scheduleIds = scheduleIds;
                                        setRoute(route.clone());
                                    }}
                                    value={trip.scheduleIds}
                                    mode="multiple"
                                    allowClear
                                    showSearch={true}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    style={{
                                        width: '100%'
                                    }}
                                    options={schedule_options(schedules)}
                                /></Tooltip>
                            </Col>
                            <Col xs="auto" className="pl-1">
                                <TripDetailsModal popPlacement={'top'} route={route}
                                                  trip={route.services ? find(route.services, {tripId: selectedTripId}) : null}
                                                  operator={operator} setTrip={setTrip}
                                                  allStops={allStops} schedules={schedules}
                                                  validationHelper={validationHelper}
                                                  setRoute={setRoute} setUpdated={setUpdated}
                                />
                            </Col>
                        </Row>}

                        {/*<Row>*/}
                        {/*    <Col lg={6}><span*/}
                        {/*        className='font-weight-bolder small'>Vehicle</span></Col>*/}
                        {/*    <Col lg={6}><span className='font-weight-bolder small'>Driver</span></Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="mb-3">*/}
                        {/*    <Col lg={6}>*/}
                        {/*        <div>*/}
                        {/*            <Select*/}
                        {/*                style={{width: "100%"}}*/}
                        {/*                placeholder="Select a vehicle"*/}
                        {/*                onChange={(value) => {*/}
                        {/*                    trip.vehicleId = value*/}
                        {/*                    setRoute(route.clone())*/}
                        {/*                }}*/}
                        {/*                value={trip.vehicleId}*/}
                        {/*                // mode="multiple"*/}
                        {/*                // allowClear*/}
                        {/*                options={vehicles}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*    <Col lg={6}>*/}
                        {/*        <div>*/}
                        {/*            <Select*/}
                        {/*                style={{width: "100%"}}*/}
                        {/*                placeholder="Select a driver"*/}
                        {/*                onChange={(value) => {*/}
                        {/*                    trip.driverId = value*/}
                        {/*                    setRoute(route.clone())*/}
                        {/*                }}*/}
                        {/*                value={trip.driverId}*/}
                        {/*                // mode="multiple"*/}
                        {/*                // allowClear*/}
                        {/*                options={drivers}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </>}
                        <Row className="TripsWrap">
                            <Col lg={12} className="h-100">
                                <StopTimes
                                    charter={charter}
                                    route={route}
                                    setTrip={setTrip}
                                    buildToStop={buildToStop}
                                    showNewStop={showNewStop}
                                    showAllStops={showAllStops}
                                    editingRoute={editingRoute}
                                    setShowNewStop={setShowNewStop}
                                    showSeconds={showSeconds}
                                    cumulativeUpdate={cumulativeUpdate}
                                    setRoute={setRoute}
                                    operator={operator}
                                    immutable={immutable}
                                    allStops={allStops}
                                    trip={trip.clone()}
                                    setFocusStop={setFocusStop}
                                    setSelectedStop={setSelectedStop}
                                    selectedStop={selectedStop}
                                    setUpdated={setUpdated}
                                    validationHelper={validationHelper}
                                    setFocusTransfers={setFocusTransfers}
                                    focusTransfers={focusTransfers}
                                />
                            </Col>
                        </Row></>
                };
            }
        ), [route.services]);

    useEffect(() => {

        const load = async () => {
            const vehicles = await vehicleModelData.getAll();
            setVehicles(values(vehicles).map(v => ({label: v.vehicleName, value: v.vehicleId})));
            const drivers = values(await driverModelData.getAll());
            setDrivers(drivers.map(v => ({label: v.driverName, value: v.driverId})));
        };

        load().then(() => console.log('drivers/vehicles loaded'));

    }, [setVehicles, setDrivers]);

    return (
        <div className="col-lg-12 h-100">
            <div className="TimetableSchedule h-100">
                <Row className="h-100">
                    <Col lg={12} className={'p-0 h-100'}>
                        <Tabs className="h-100"
                              hideAdd={true}
                              type="editable-card"
                              onChange={(tripId) => {
                                  console.log('onChange', tripId);
                                  setSelectedTripId(tripId);
                              }}
                              size={'middle'}
                              defaultActiveKey={route?.services?.[0]?.tripId}
                              activeKey={selectedTripId}
                              items={getTabItems()}
                        />
                    </Col>
                </Row>
            </div>
        </div>

    );
}

export default React.memo(Trips);
