import dayjs from '../dayjs';
import {clone, cloneDeep} from 'lodash';
import {DATE_STRING} from './schedule';
import {secsSinceMidnightToDayjs} from './timeFilter';
import {ShiftBat} from './shiftBat';
import {eachSeries} from 'async';
import {ulid} from 'ulid';

export class Jobable {

    constructor() {
        // Transient fields
        this.jobs = []; // Array of Vehicle Job
        this.jobOption = null; // Job Options
    }


    addJob(job) {
        this.jobs.push(job);
    }

    async addJobOption(duty, {deadrunSortFn = null} = {}) {
        throw new Error('Not implemented');
    }
}

const allocateJobOptions = async (duty, type, loadedVehicles, date) => {
    console.log('Allocating SB', duty.shiftBatNumber, duty.getStartTime({asDayJs: true}).format('HH:mm'), duty.getEndTime({asDayJs: true}).format('HH:mm'));
    if (!duty.getActualStartTime) {
        duty = new ShiftBat(duty);
    }
    loadedVehicles.forEach(vehicle => vehicle.jobOption = null);
    await Promise.all(loadedVehicles.map(async vehicle => await vehicle.addJobOption(duty, type, date)));

    let bestVehicle;
    try {
        let filteredVehicles = loadedVehicles.filter(vehicle => vehicle.jobOption);
        filteredVehicles.sort((j1, j2) =>
            j1.jobOption.stats.total.distance - j2.jobOption.stats.total.distance);
        bestVehicle = filteredVehicles[0];
        if (bestVehicle) {
            const job = new Job({
                date,
                name: `${duty.shiftBatNumber}-V`,
                job: duty,
                typeId: duty.shiftBatId,
                type: 'shiftbat',
                allocationType: 'vehicle',
                allocationId: bestVehicle.vehicleId,
                allocation: bestVehicle
            });
            bestVehicle.addJob(job);
            console.log(bestVehicle?.vehicleName, bestVehicle?.jobOption?.job?.shiftBatNumber);
            return {vehicle: bestVehicle, job};
        }
        console.log('No vehicle could be found for ', duty.shiftBatNumber);
        return {vehicle: null, job: null};

    } catch (e) {
        console.log(e);
    }
};

export class JobScenario {

    static from(data) {
        return new JobScenario(data);
    }

    static async allocate(unallocatedShiftBats, loadedVehicles, date) {
        loadedVehicles = loadedVehicles.map(vehicle => vehicle.clone());

        const jobSet = new JobScenario({name: '1st Available'});

        unallocatedShiftBats.forEach(sb => {
            console.log('Allocating SB', sb.shiftBatNumber, sb.getStartTime({asDayJs: true}).format('HH:mm'), sb.getEndTime({asDayJs: true}).format('HH:mm'));
            if (!sb.getActualStartTime) {
                sb = new ShiftBat(sb);
            }
            let nextAvailableVehicle = loadedVehicles.find(vehicle => vehicle.isJobPossible(sb));
            if (nextAvailableVehicle) {
                const job = new Job({
                    date,
                    name: `${sb.shiftBatNumber}-V`,
                    job: sb,
                    typeId: sb.shiftBatId,
                    type: 'shiftbat',
                    allocationType: 'vehicle',
                    allocationId: nextAvailableVehicle.vehicleId,
                    allocation: nextAvailableVehicle
                });
                nextAvailableVehicle.addJob(job); // temporarily add the job to the vehicle
                jobSet.addJob(job);
                jobSet.addVehicle(nextAvailableVehicle);
            }

        });
        return jobSet;
    };

    static async smartAllocate(unallocatedShiftBats, loadedVehicles, date) {

        loadedVehicles = loadedVehicles.map(vehicle => vehicle.clone());

        const jobSet = new JobScenario({name: 'Shortest Distance'});

        await eachSeries(unallocatedShiftBats, (sb, done) => {
            allocateJobOptions(sb, 'shiftbat', loadedVehicles, date)
                .then(({vehicle, job}) => {
                    if (vehicle && job) {
                        jobSet.addVehicle(vehicle);
                        jobSet.addJob(job);
                    }
                    done();
                });
            // console.log('Allocating SB', sb.shiftBatNumber, sb.getStartTime({asDayJs: true}).format('HH:mm'), sb.getEndTime({asDayJs: true}).format('HH:mm'));
            // if (!sb.getActualStartTime) {
            //     sb = new ShiftBat(sb);
            // }
            // loadedVehicles.forEach(vehicle => vehicle.jobOption = null);
            // Promise.all(loadedVehicles.map(async vehicle => {
            //     return await vehicle.addJobOption(sb);
            // }))
            //     .then(() => {
            //         let bestVehicle;
            //         try {
            //             let filteredVehicles = loadedVehicles.filter(vehicle => vehicle.jobOption);
            //             filteredVehicles.sort((j1, j2) =>
            //                 j1.jobOption.stats.total.distance - j2.jobOption.stats.total.distance);
            //             bestVehicle = filteredVehicles[0];
            //             if (bestVehicle) {
            //                 const job = new Job({
            //                     date,
            //                     name: `${sb.shiftBatNumber}-V`,
            //                     job: sb,
            //                     typeId: sb.shiftBatId,
            //                     type: 'shiftbat',
            //                     allocationType: 'vehicle',
            //                     allocationId: bestVehicle.vehicleId,
            //                     allocation: bestVehicle
            //                 });
            //                 bestVehicle.addJob(job);
            //                 jobSet.addJob(job);
            //                 jobSet.addVehicle(bestVehicle);
            //             }
            //         } catch (e) {
            //             console.log(e);
            //         }
            //         console.log(bestVehicle?.vehicleName, bestVehicle?.jobOption?.job?.shiftBatNumber);
            //         done();
            //     });
        });

        jobSet.vehicles.forEach(vehicle => vehicle.jobOption = null);

        return jobSet;
    }

    constructor(props) {
        this.id = ulid();
        this.name = 'Default';
        this.jobs = [];
        this.vehicles = [];
        Object.assign(this, props);
        if (this.jobs?.length) {
            this.jobs = this.jobs.map(job => new Job(job));
        }

    }

    clone() {
        return JobScenario.from(this);
    }

    addJob(job) {
        this.jobs = this.jobs || [];
        this.jobs.push(job);
    }

    removeJob(job) {
        if (this.jobs) {
            this.jobs = this.jobs.filter(j => j.jobId !== job.jobId);
        }
    }

    addVehicle(vehicle) {
        this.vehicles.push(vehicle);
    }

    getTotalDistance() {
        return this.jobs?.reduce((total, job) => total + job.getDuty()?.getShiftDistance() || 0, 0);
    }
}

export class Job {

    static fromDuty(type, duty, date) {
        const data = {
            date,
            type,
            job: duty
        };
        return new Job(data);
    }


    constructor(data) {
        this.jobId = null; // internal sortKey #{date}#{allocationType}#{allocationId}#{type}#{typeId}
        this.recurrenceId = null; // internal sortKey #{recurrenceType}#{allocationType}#{allocationId}#{type}#{typeId}
        this.recurrenceType = null; // the recurrence type used in the recurrenceId ('monday', 'tuesday'..., '01', '02'..., scheduleId) = (dayOfWeek, dayOfMonth, scheduleId)
        this.date = null; // date of the allocation
        this.type = 'shiftbat'; // type of job ('shiftbat', 'charter', 'adhoc')
        this.typeId = null; // shiftBatId, charterId, adhocId
        this.job = null; // shiftBat, charter, adhoc
        this.allocationType = 'employee'; // type of allocation ('employee', 'vehicle')
        this.allocationId = null; // employeeID, vehicleId
        this.allocation = null; // Driver or Vehicle

        // The following fields are used for Calendar export
        this.name = null; // name of the job
        this.description = null; // description of the job

        // Transient fields
        this.actualStartTime = null; // actual start time of the job
        this.actualEndTime = null; // actual end time of the job
        this.startTime = null; // actual start time of the job
        this.endTime = null; // actual end time of the job
        this.leadingDeadrun = null; // deadrun at the start of the job
        this.trailingDeadrun = null; // deadrun at the end of the job

        Object.assign(this, data);

        if (typeof this.date === 'string') {
            this.date = dayjs(this.date, DATE_STRING).startOf('day');
        }
        if (!this.typeId && this.type && this.job) {
            switch (this.type) {
                case 'shiftbat':
                    this.typeId = this.job.shiftBatId;
                    break;
                case 'charter':
                    this.typeId = this.job.charterId;
            }
        }
    }

    clone() {
        return clone(this);
    }

    /*
     * Returns the job as a ShiftBat
     */
    getDuty() {
        let duty;
        if (this.job && this.type) {
            if (this.type === 'charter') {
                duty = this.job.duty;
            } else {
                duty = this.job;
            }
            if (duty) {
                duty = new ShiftBat(duty);
            }
        }
        return duty;
    }

    getId() {
        return `${(this.date || dayjs())?.format?.('YYYYMMDD')}#${this.type || 'shiftbat'}#${this.typeId}#${this.allocationType || 'employee'}#${this.allocationId}#${Date.now()}`;
    }

    getName() {
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return this.getDuty().getName();
    }

    /**
     * Returns the deadrun at the start of this job if one exists
     * @returns {duration, distance, location}
     */
    getLeadingDeadrun() {
        if (this.leadingDeadrun) {
            return this.leadingDeadrun;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return this.getDuty().getLeadingDeadrun();
    }

    /**
     * Returns the deadrun at the end of this job if one exists
     * @returns {duration, distance, location}
     */
    getTrailingDeadrun() {
        if (this.trailingDeadrun) {
            return this.trailingDeadrun;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return this.getDuty().getTrailingDeadrun();
    }

    /**
     * Returns a dayjs representing the start of the job, including the deadrun if exists
     */
    getStartTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.startTime) {
            return this.startTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getStartTime(), date);
    }

    /**
     Returns a dayjs representing the end of the job, including the deadrun if exists
     */
    getEndTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.endTime) {
            return this.endTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getEndTime(), date);
    }

    /**
     * Returns a dayjs representing the start of the job, NOT including the deadrun if exists
     */
    getCardStartTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.actualStartTime) {
            return this.actualStartTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getStartTime() - (this.getLeadingDeadrun()?.duration || 0), date);
    }

    /**
     Returns a dayjs representing the end of the job, NOT including the deadrun if exists
     */
    getCardEndTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.actualEndTime) {
            return this.actualEndTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getEndTime() + (this.getTrailingDeadrun()?.duration || 0), date);
    }

    /**
     * Returns a dayjs representing the start of the job, NOT including the deadrun if exists
     */
    getActualStartTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.actualStartTime) {
            return this.actualStartTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getStartTime() + (this.getLeadingDeadrun()?.duration || 0), date);
    }

    /**
     Returns a dayjs representing the end of the job, NOT including the deadrun if exists
     */
    getActualEndTime(opts = {date: dayjs()}) {
        const {date} = opts;
        if (this.actualEndTime) {
            return this.actualEndTime;
        }
        if (!this.getDuty()) {
            throw new Error('Missing Duty');
        }
        return secsSinceMidnightToDayjs(this.getDuty().getEndTime() - (this.getTrailingDeadrun()?.duration || 0), date);
    }

    getDistance() {
        return this.getDuty()?.getShiftDistance() || 0;
    }

    getDuration() {
        return this.getDuty()?.getShiftTime() || 0;
    }

    toJson() {
        const obj = cloneDeep(this);
        obj.date = this.date.format(DATE_STRING);
        const {userId, jobId, recurrenceId, recurrenceType, date, type, typeId, allocationType, allocationId} = obj;
        return {userId, jobId, recurrenceId, recurrenceType, date, type, typeId, allocationType, allocationId};
    }

    fromJson(shiftBatsById, chartersById, employeesById, vehiclesById) {
        if (typeof this.date === 'string') {
            this.date = dayjs(this.date, DATE_STRING).startOf('day');
        }
        switch (this.type) {
            case 'shiftbat':
                this.job = shiftBatsById[this.typeId];
                this.start = secsSinceMidnightToDayjs(this.job.getStartTime());
                this.end = secsSinceMidnightToDayjs(this.job.getEndTime());
                break;
            case 'charter':
                this.job = chartersById[this.typeId];
                break;
            case 'adhoc':
                throw new Error('Adhoc job not implemented');
        }

        switch (this.allocationType) {
            case 'employee':
                this.allocation = employeesById[`#EMPLOYEE#${this.allocationId}#DETAILS`];
                break;
            case 'vehicle':
                this.allocation = vehiclesById[this.allocationId];
        }

        // this.allocation.addJob(this);
    }
}

// export class Allocation {
//     constructor(data) {
//         this.allocationId = null;
//         this.date = null; // date it is allocation
//         this.recurring = null; // recurring allocation [null, 'daily', 'weekly', 'monthly'];
//         this.type = null; // type of allocation ('driver', 'vehicle')
//         this.typeId = null; // staffId, vehicleId
//         this.allocated = null; // Driver or Vehicle
//         this.workItems = [];
//         Object.assign(this, data);
//     }
// }