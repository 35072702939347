import Pin from "./Pin";
import Form from "react-bootstrap/Form";
import TimeField from "../libs/timeField";
import {getArrivalTimeAsDayjs, getDepartureTimeAsDayjs,} from "../libs/routes-lib";
import dayjs from "../dayjs";
import React, {useEffect, useRef, useState} from "react";
import {useScroll} from "../libs/hooksLib";
import {Button, Col, Flex, Popover, Row, Tooltip} from "antd";
import {useAppContext} from "../libs/contextLib";
import config from "../config";
import {noop} from "lodash/util";
import {dayjsToSecsSinceMidnight} from "../model/timeFilter";
import RouteNumber from "./RouteNumber";
import {find} from "lodash/collection";
import {differenceBy, uniq} from "lodash/array";
import {EyeOutlined} from "@ant-design/icons";
import {ReactComponent as Down} from "../assets/icons/Down.svg";
import ShiftBatSelectRowEdit from "../features/ShiftBat/ShiftBatSelectRowEdit";
import {ReactComponent as Check} from "../assets/icons/Check.svg";
import {ReactComponent as Close} from "../assets/icons/Close.svg";
import {values} from "lodash/object";
import ChatButton from "./ChatButton";

function StopTimeField({
                           stop,
                           idx,
                           validationMessages,
                           showSeconds,
                           setUpdated,
                           trip,
                           onChange,
                           setEditing,
                           arrive,
                           value,
                           focus, setFocus, cumulativeUpdate
                       }) {
    const timeFieldRef = useRef();

    useEffect(() => {
        if (timeFieldRef.current) {
            timeFieldRef.current.addEventListener('focus', () => {
                setFocus({idx, arrive});
            })
            timeFieldRef.current.addEventListener('blur', () => {
                setFocus(null);
            })
        }
    }, [timeFieldRef, setFocus]);

    // const [format, setFormat] = useState("HH:mm")
    // const [ss, setSs] = useState(showSeconds)
    // useEffect(() => {
    //     if(showSeconds !== ss) {
    //         setFormat("HH:mm ")
    //     }
    // }, [showSeconds, ss, setFormat])
    const shouldFocus = () => {
        if (!focus) {
            return false
        }
        if (idx < focus.idx) {
            return false;
        }
        if (cumulativeUpdate) {
            return idx > focus.idx ? true : focus.idx === idx ? arrive === focus.arrive || focus.arrive : false;
        } else {
            return focus.idx === idx && focus.arrive === arrive;
        }
    }
    const getStyleForErrors = messages => !messages.length ? '' : messages.some(m => !m.warning) ? 'alert-danger' : 'alert-warning'
    return (
        <Tooltip
            title={<div>{validationMessages.map(m => (<p>{m.msg}</p>))}</div>}
            zIndex={!validationMessages?.length ? -10 : 10}>
            <TimeField
                inputRef={timeFieldRef}
                tabIndex={idx}
                className={`ant-input ant-input-sm inputtext-small text-center ${getStyleForErrors(validationMessages)} ${shouldFocus() ? 'alert-success' : ''}`}
                style={{width: showSeconds ? 58 : 48}}
                showSeconds={!!showSeconds}
                onFocus={() => setEditing(true)}
                onBlur={e => {
                    setEditing(false)
                    setUpdated(true)
                }}
                value={value}
                onChange={onChange}
            /></Tooltip>
    )
}

function NewStopTime({allStops, setSelectedStop, setShowNewStop, buildToStop, charter}) {

    const [stop, setStop] = useState(null);
    const [options, setOptions] = useState([])

    useEffect(() => {
        // Set the service options whenallRoutes change
        setOptions(values(allStops).filter(stop => (!stop.duplicate || stop.duplicate <= 0) && (!stop.stopType || stop.stopType === 'bus')).map(stop => ({
            label: stop.stopName + (stop.stopCode ? ` (${stop.stopCode})` : ''),
            value: stop.stopId
        })))
    }, [allStops, setOptions])


    return (<>
        <div key={`StopTime-Row-New`} className={'mt-2 AddNewRow'}>
            <Row>
                <Col xs={20}>
                    <ShiftBatSelectRowEdit className={"inputselect-small w-100"} ttTitle={'Select a stop'}
                                           suffixIcon={<Down/>}
                                           value={stop?.stopId || ""}
                                           placeholder={"Select stop"}
                                           onChange={value => {
                                               const stop = allStops[value];
                                               setStop(stop)
                                               setSelectedStop(stop);
                                           }}
                                           options={options}
                    /></Col>
                <Col xs={4}>
                    <div className="sb-edit-controls">
                        <Button className="sb-done"
                                icon={<Check/>}
                                disabled={!stop}
                                onClick={() => buildToStop(stop)}/>
                        <Button className="sb-close"
                                icon={<Close/>} onClick={() => setShowNewStop(false)}/>
                    </div>
                </Col>
            </Row>
        </div>
    </>)

}

function StopTime({
                      route, charter,
                      idx,
                      trip,
                      stopTimes,
                      stopValidationMessages,
                      arriveValidationMessages,
                      departValidationMessages,
                      isAdmin,
                      immutable,
                      setEditing,
                      editing,
                      setFocusStop,
                      setSelectedStop,
                      selectedStop,
                      scrollRef,
                      setTrip,
                      setUpdated,
                      stop,
                      allStops,
                      operator,
                      showSeconds,
                      cumulativeUpdate,
                      focus,
                      setFocus,
                      focusTransfers,
                      setFocusTransfers = noop,
                  }) {

    const {setChatContext, setChatOpen} = useAppContext();

    return (<>
            <Row key={`StopTime-Row-${stop.stopTimeId}}`}
                 onMouseOver={() => {
                     if (!editing) {
                         setFocusStop({...stop, ...allStops[stop.stopId]});
                     }
                 }}
                 onClick={() => {
                     setSelectedStop({...stop, ...allStops[stop.stopId]});
                 }}
                 ref={selectedStop && stop.stopTimeId === selectedStop.stopTimeId ? scrollRef : undefined}
                 className={`sb-row align-items-center flex-nowrap ${stop.timingPoint ? 'TP' : ''} ${selectedStop && stop.stopTimeId === selectedStop.stopTimeId ? 'Selected' : ''} ${stopValidationMessages?.length ? 'alert-danger' : ''}`}>
                {!immutable && <Col xs={1} className="text-center">{idx + 1}</Col>}
                <Col xs={2} className="text-center">{!immutable ?
                    <Pin size={25} type={allStops[stop.stopId]?.stopType}/> :
                    <Pin size={25} sequence={trip.getStopSequence(stop, allStops)}
                         type={allStops[stop.stopId]?.stopType}/>}</Col>
                <Col xs={charter ? 13 : 11} style={{lineHeight: '18px'}}>
                    {
                        stopValidationMessages?.length ?
                            <Tooltip
                                title={<div>{stopValidationMessages.map(m => (<p key={'vl_'+m.msg}>{m.msg}</p>))}</div>}>
                                <span>{allStops[stop.stopId]?.stopName}</span>
                            </Tooltip>
                            : isAdmin || config.local ?
                                <Popover
                                    title={"Verbose"}
                                    content={<div>
                                        <div>StopTime ID: {stop.stopTimeId}</div>
                                        <div>Stop ID: {stop.stopId}</div>
                                        <div>Wp idx: {stop.wpIdx}</div>
                                    </div>}>
                                    <span>{allStops[stop.stopId]?.stopName}</span>
                                </Popover>
                                :
                                <span>{allStops[stop.stopId]?.stopName}</span>
                    }
                </Col>
                {!immutable && !charter && <Col xs={2} className="text-center"><Form.Check
                    checked={stop.timingPoint}
                    type={'checkbox'}
                    id={`${idx}-TP-radio`}
                    onChange={(e) => {
                        console.log('TimingPoint clicked', !stop.timingPoint)
                        stop.timingPoint = !stop.timingPoint
                        setTrip(trip);
                        setUpdated(true);
                    }}

                />
                </Col>}
                <Col xs={4} className="text-center InputTimeField">
                    {immutable ?
                        <Flex justify={"space-between"}>
                            <div style={{
                                fontStyle: `${stop.timingPoint ? '' : 'italic'}`,
                                fontWeight: `${stop.timingPoint ? 'bold' : ''}`
                            }}>{stop.timingPoint || route.charter || operator?.opts?.timetable?.stopTimes?.showAll ? getDepartureTimeAsDayjs(trip, stop)?.format('HH:mm') || '-:-' : ''}</div>
                            <div>
                                <ChatButton chatContext={{
                                    type: 'route',
                                    id: route.routeId,
                                    routeId: route.routeId,
                                    stopTimeId: stop.stopTimeId,
                                    subTypeId: `${trip.tripId}_${stop.stopTimeId}`,
                                    stopId: stop.stopId,
                                    tripId: trip.tripId,
                                    route,
                                    stopTime: stop,
                                    subType: 'st'
                                }}/>
                            </div>
                        </Flex>
                        :
                        <StopTimeField validationMessages={arriveValidationMessages} idx={idx} stop={stop}
                                       setEditing={setEditing} setUpdated={setUpdated} trip={trip}
                                       cumulativeUpdate={cumulativeUpdate} arrive={true} focus={focus}
                                       setFocus={setFocus} showSeconds={showSeconds}
                                       onChange={(e, value) => {

                                           if (!value) {
                                               return
                                           }
                                           value = dayjs(value, showSeconds ? 'HH:mm:ss' : 'HH:mm')
                                           if (!value.isValid()) {
                                               return
                                           }
                                           // const departTime = getDepartureTimeAsDayjs(trip, stop)
                                           // stop.dwell = departTime.diff(value, 's')
                                           const currentSecs = stop.arriveSecs
                                           stop.arriveSecs = dayjsToSecsSinceMidnight(value)
                                           if (cumulativeUpdate) {
                                               const diff = stop.arriveSecs - currentSecs
                                               stop.departSecs += diff
                                               stopTimes.forEach((st, i) => {
                                                   if (i > idx) {
                                                       st.arriveSecs += diff
                                                       st.departSecs += diff
                                                   }
                                               })
                                           }
                                           setTrip(trip);

                                           if (e.shiftKey) {
                                               console.log("SHIFT KEY")
                                           }
                                       }}
                                       value={getArrivalTimeAsDayjs(trip, stop).format(showSeconds ? 'HH:mm:ss' : 'HH:mm')}
                        />
                    }
                    {/*<td>{getDepartureTime(route, stop, idx) || '-'}*/}
                    {/*<td>{startHour && startMin ? toTime(stop.delta - getPreviousTimingPoint(route, stop).delta + stop.delta + stop.adjustedDelta) : idx > 0 ? toHrsMinsSecs(stop.delta + stop.adjustedDelta - stopTimes[idx - 1].delta + stopTimes[idx - 1].adjustedDelta) + ' later' : '-'}*/}
                </Col>
                {/*<div className="col-sm-2 d-flex justify-content-center pl-0 pr-0">*/}
                {/*    {!immutable && !stop.timingPoint && idx > 0 &&*/}
                {/*        <div className="sb-controls">*/}
                {/*            <Button className="sb-delete-row" icon={<Minus/>} onClick={(e) => decreaseDelta(e, idx)} />*/}
                {/*            <Button className="sb-add-row" icon={<Plus/>} onClick={(e) => increaseDelta(e, idx)} />*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*</div>*/}

                {!immutable && <Col xs={4} className="text-center InputTimeField">
                    <StopTimeField validationMessages={departValidationMessages} idx={idx} stop={stop}
                                   setEditing={setEditing} setUpdated={setUpdated} trip={trip} focus={focus}
                                   setFocus={setFocus} arrive={false} cumulativeUpdate={cumulativeUpdate}
                                   showSeconds={showSeconds}
                                   onChange={(e, value) => {

                                       if (!value) {
                                           return
                                       }
                                       value = dayjs(value, showSeconds ? 'HH:mm:ss' : 'HH:mm')
                                       if (!value.isValid()) {
                                           return
                                       }
                                       // const arrivalTime = getArrivalTimeAsDayjs(trip, stop)
                                       // stop.departHour = value ? value.hour() : null;
                                       // stop.departMin = value ? value.minute() : null
                                       // stop.departSec = value ? value.second() : 0
                                       // stop.delta = calculateDelta(trip, stop, idx)
                                       // stop.dwell = value.diff(arrivalTime, 's')
                                       const currentSecs = stop.departSecs
                                       stop.departSecs = dayjsToSecsSinceMidnight(value)
                                       if (cumulativeUpdate) {
                                           const diff = stop.departSecs - currentSecs
                                           stopTimes.forEach((st, i) => {
                                               if (i > idx) {
                                                   st.arriveSecs += diff
                                                   st.departSecs += diff
                                               }
                                           })
                                       }
                                       setTrip(trip)
                                   }}
                                   value={getDepartureTimeAsDayjs(trip, stop).format(showSeconds ? 'HH:mm:ss' : 'HH:mm')}
                    />
                </Col>}
                {/*}*/}
                {/*<td>{idx > 0 ? toKmMs(stop.distance) : "-"}</td>*/}
                {/*<td>{idx > 0 ? toHrsMinsSecs(stop.delta + stop.adjustedDelta - (stopTimes[idx - 1].delta + stopTimes[idx - 1].adjustedDelta)) : "-"}</td>*/}
            </Row>

            {stop.transfersTo?.length > 0 &&
                <div
                    className={`d-flex transfer-row-info align-items-center justify-content-center ${differenceBy(stop.transfersTo, focusTransfers.to, 'routeId')?.length ? '' : 'Selected'}`}
                    onClick={e => {
                        if (e.metaKey) {
                            return
                        }
                        e.stopPropagation();
                        setFocusTransfers(focusTransfers => {
                            if (focusTransfers.to?.length) {
                                focusTransfers.to = []
                            } else {
                                focusTransfers.to = stop.transfersTo
                            }
                            return {
                                ...focusTransfers,
                                stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                            }
                        });
                    }}>
                    <span className="show-transfers"><EyeOutlined/></span>
                    <span>Transfer</span>
                    {stop.transfersTo.map((tx, idx) => <div
                        key={'tt_' + idx}
                        className={`transfer-single ${tx.invalid ? "transfer-warning" : ""} ${focusTransfers.to.filter(t => t.routeId === tx.routeId).length ? 'Selected' : ''}`}
                        onClick={e => {
                            if (e.metaKey) {
                                return
                            }
                            e.stopPropagation();
                            setFocusTransfers(focusTransfers => {
                                if (find(focusTransfers.to, ['routeId', tx.routeId])) {
                                    focusTransfers.to = focusTransfers.to.filter(t => t.routeId !== tx.routeId)
                                } else {
                                    focusTransfers.to = focusTransfers.to.concat(tx)
                                }
                                return {
                                    ...focusTransfers,
                                    stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                }
                            })
                        }}><RouteNumber key={'TxRidTo' + tx.routeId}
                                        route={tx} link={true} meta={true}
                                        tooltip={tx.invalid && tx.warnings?.length ? uniq(tx.warnings).map(w =>
                                            <p>{w}</p>) : null}
                    /></div>)}
                </div>
            }
            {stop.transfersFrom?.length > 0 &&
                <div
                    className={`d-flex transfer-row-info align-items-center justify-content-center ${differenceBy(stop.transfersFrom, focusTransfers.from, 'routeId')?.length ? '' : 'Selected'}`}
                    onClick={e => {
                        if (e.metaKey) {
                            return
                        }
                        e.stopPropagation();
                        setFocusTransfers(focusTransfers => {
                            if (focusTransfers.from?.length) {
                                focusTransfers.from = []
                            } else {
                                focusTransfers.from = stop.transfersFrom
                            }
                            return {
                                ...focusTransfers,
                                stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                            }
                        })
                    }}>
                    <span className="show-transfers"><EyeOutlined/></span>
                    <span>Pickup</span>
                    {stop.transfersFrom.map((tx, idx) => <div
                            key={'tf_' + idx}
                            className={`transfer-single ${tx.invalid ? "transfer-warning" : ""} ${focusTransfers.from.filter(t => t.routeId === tx.routeId).length ? 'Selected' : ''}`}
                            onClick={e => {
                                if (e.metaKey) {
                                    return
                                }
                                e.stopPropagation();
                                setFocusTransfers(focusTransfers => {
                                    if (find(focusTransfers.from, ['routeId', tx.routeId])) {
                                        focusTransfers.from = focusTransfers.from.filter(t => t.routeId !== tx.routeId)
                                    } else {
                                        focusTransfers.from = focusTransfers.from.concat(tx)
                                    }
                                    return {
                                        ...focusTransfers,
                                        stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                    }
                                })
                            }}><RouteNumber key={'TxRidFrom' + tx.routeId}
                                            route={tx} link={true} meta={true}
                                            tooltip={tx.invalid && tx.warnings?.length ? uniq(tx.warnings).map(w =>
                                                <p>{w}</p>) : null}
                        /></div>
                    )}
                </div>
            }
        </>
    )
}

export default function StopTimes({
                                      route, charter,
                                      allStops,
                                      showSeconds,
                                      cumulativeUpdate,
                                      trip,
                                      setTrip,
                                      showAllStops,
                                      editingRoute,
                                      setFocusStop,
                                      setSelectedStop,
                                      selectedStop,
                                      setUpdated = noop,
                                      immutable = false,
                                      setFocusTransfers,
                                      focusTransfers,
                                      operator, validationHelper = {isValid: true},
                                      buildToStop,
                                      showNewStop, setShowNewStop
                                  }) {
    const {isAdmin} = useAppContext();
    const [editing, setEditing] = useState(false);
    const [executeScroll, scrollRef] = useScroll();
    const [stopTimes, setStopTimes] = useState([]);
    const [focus, setFocus] = useState(null);

    useEffect(() => {
        executeScroll();
    }, [selectedStop, executeScroll]);

    useEffect(() => {
        if (!trip?.stopTimes) return
        // console.log('trip updated... setting stopTimes', trip.stopTimes)
        setStopTimes(immutable ? trip.getVisiblePublicStopTimes(allStops) : trip.stopTimes)
    }, [trip, allStops, immutable]);

    return (
        <div className="StopTimes h-100">
            <Row className="sb-row-heading align-items-center" key={`Stop-Header`}>
                {!immutable && <Col xs={1} className="text-center">#</Col>}
                <Col xs={2} className="text-center"></Col>
                <Col xs={charter ? 13 : 11}>Stop Name</Col>
                {!immutable && !route.charter && !charter && <Col xs={2} className="text-center">TP</Col>}
                <Col xs={4} className="text-center">Arrive</Col>
                <Col xs={4} className="text-center">Depart</Col>
            </Row>
            <div className="service-rows-wrap">
                {
                    stopTimes && stopTimes.filter(st => !!allStops[st.stopId]).map((stop, idx) => {
                            const stopValidationMessages = validationHelper.stops[idx] ? validationHelper.stops[idx].filter(s => !!s && (!s.tripId || (s.stop && s.tripId === trip.tripId))) : []
                            const arriveValidationMessages = validationHelper.stops[idx] ? validationHelper.stops[idx].filter(s => !!s && s.tripId === trip.tripId && s.arrive) : []
                            const departValidationMessages = validationHelper.stops[idx] ? validationHelper.stops[idx].filter(s => !!s && s.tripId === trip.tripId && s.depart) : []
                            // if (messages.length) {
                            //     console.log(messages)
                            //     return (
                            //         // <Tooltip title={<div>{messages.map(m => <p>{m}</p>)}</div>}>
                            //         <StopTime idx={idx} isAdmin={isAdmin} immutable={immutable} setEditing={setEditing}
                            //                   editing={editing} validationHelper={validationHelper}
                            //                   setFocusStop={setFocusStop} setSelectedStop={setSelectedStop}
                            //                   selectedStop={selectedStop}
                            //                   scrollRef={scrollRef} setTrip={setTrip} setUpdated={setUpdated} stop={stop}
                            //                   trip={trip}
                            //                   allStops={allStops} operator={operator} stopTimes={stopTimes}/>
                            //     )
                            // }
                            return (
                                <StopTime
                                    route={route} charter={charter}
                                    key={'st-' + idx} idx={idx} showSeconds={showSeconds} isAdmin={isAdmin}
                                    immutable={immutable} focus={focus} setFocus={setFocus}
                                    setEditing={setEditing}
                                    editing={editing} stopValidationMessages={stopValidationMessages}
                                    arriveValidationMessages={arriveValidationMessages}
                                    departValidationMessages={departValidationMessages}
                                    setFocusStop={setFocusStop} setSelectedStop={setSelectedStop}
                                    selectedStop={selectedStop}
                                    scrollRef={scrollRef} setTrip={setTrip} setUpdated={setUpdated} stop={stop}
                                    trip={trip} cumulativeUpdate={cumulativeUpdate}
                                    allStops={allStops} operator={operator} stopTimes={stopTimes}
                                    setFocusTransfers={setFocusTransfers} focusTransfers={focusTransfers}/>
                            )
                        }
                    )
                }
                {showNewStop && showAllStops && editingRoute &&
                    <NewStopTime allStops={allStops} showNewStop={showNewStop} setShowNewStop={setShowNewStop}
                                 buildToStop={buildToStop} setSelectedStop={setSelectedStop} charter={charter}/>
                }
            </div>
        </div>
    );
}
