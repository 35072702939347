import React, {useCallback, useEffect, useMemo} from 'react';
import './Charters.css';
import {charterModelData} from '../../services/ModelService';
import {Charter} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import LoadMessage from '../../components/LoadMessage';
import {Button, Card, Col, DatePicker, Flex, Input, InputNumber, Row} from 'antd';
import CharterMenu from './CharterMenu';
import {isEmpty, startCase} from 'lodash';
import LoaderButton from '../../components/LoaderButton';
import {TrashIcon} from '../../components/Icons';
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {secsSinceMidnightToDayjs} from '../../model/timeFilter';
import {DATE_STRING} from '../../model/schedule';
import {DEFAULT_VEHICLE_RATE} from '../../editableList/vehicles/Vehicles';
import {ReactComponent as Print} from "../../assets/icons/Print.svg";
import {useAppContext} from "../../libs/contextLib";
import config from "../../config";

const {TextArea} = Input;
const GST = 0.1;

const calculateDefaultCharterPrice = (charter) => {
    if (!charter.duty?.getShiftDistance() || !charter.duty?.getShiftTime()) {
        return 0;
    }
    const vehicleCost = Math.ceil((charter?.vehicle?.rate || DEFAULT_VEHICLE_RATE) * charter.duty.getShiftDistance() / 1000);
    const driverCost = Math.ceil(35 * charter.duty.getShiftTime() / 3600);
    return Math.ceil((vehicleCost + driverCost) * 1.3);
};

function CharterQuote() {
    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({charterId: '_'}),
    });

    // const [lineItems, setLineItems] = useState([]);

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({...charter});
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    const addLine = useCallback((lineItem = {
        name: null,
        description: null,
        unitPrice: null,
        quantity: null,
        discount: null,
        amount: null,
    }) => {
        setCharter(charter => {
            return new Charter({
                ...charter,
                quoteLineItems: [
                    ...(charter.quoteLineItems || []),
                    lineItem,
                ]
            });
        });
        // setLineItems(lineItems => [
        //     ...(lineItems || []),
        //     lineItem,
        // ]);
    }, [setCharter]);

    const cloneLine = (index) => {
        const clone = {...charter.quoteLineItems[index]};
        setCharterWith('quoteLineItems', [...charter.quoteLineItems, clone]);
        // setLineItems([...lineItems, clone]);
    };

    const deleteLine = (index) => {
        setCharterWith(
            'quoteLineItems',
            charter.quoteLineItems.filter((e, i) => i !== index)
        );
        // setLineItems(lineItems.filter((e, i) => i !== index));
    };

    const updateLine = (index, key, value) => {
        setCharterWith(
            'quoteLineItems',
            charter.quoteLineItems.map((e, i) => {
                if (i === index) {
                    return {
                        ...e,
                        [key]: value,
                    };
                }
                return e;
            })
        );
        // setLineItems(lineItems => lineItems.map((e, i) => {
        //     if (i === index) {
        //         return {
        //             ...e,
        //             [key]: value,
        //         };
        //     }
        //     return e;
        // }));
    };

    const calculateAmount = useCallback((line) => {
        const discount = line.discount || 0;
        if (line.unitPrice && line.quantity) {
            const total = line.unitPrice * line.quantity;
            const discountedAmount = total * (discount / 100);
            const finalAmount = total - discountedAmount;
            if (finalAmount < 0) return 0;
            return finalAmount;
        }
        return 0;
    }, []);

    // Memoize the calculateSubTotal function
    const calculateSubTotal = useMemo(() => {
        return ((
                charter.quoteLineItems.reduce((acc, current) => {
                    return acc + parseFloat(calculateAmount(current));
                }, 0) || 0).toFixed(2)
        );

        // return (
        //     lineItems.reduce((acc, current) => {
        //         return acc + parseFloat(calculateAmount(current));
        //     }, 0) || 0
        // );
    }, [charter.quoteLineItems, calculateAmount]);

    // Memoize the calculateGST function
    const calculateGST = useMemo(() => {
        return (parseFloat(calculateSubTotal) * GST || 0).toFixed(2);
    }, [calculateSubTotal]);

    // Memoize the calculateTotal function
    const calculateTotal = useMemo(() => {
        const subTotal = parseFloat(calculateSubTotal) || 0;
        const gst = parseFloat(calculateGST) || 0;
        return (subTotal + gst).toFixed(2);
    }, [calculateSubTotal, calculateGST]);


    useEffect(() => {
        if (charter?.duty?.getShiftTime() && !charter.quoteLineItems?.length) {
            addLine({
                name: charter.name,
                description: 'Vehicle + Driver (incl. fuel and maintenance)',
                unitPrice: calculateDefaultCharterPrice(charter),
                quantity: 1,
                discount: 0,
            });
        }
    }, [charter, addLine]);

    const {operator} = useAppContext();

    return (
        <div className="charter-details charter-quote w-secondary-menu">
            <h2 className="print-title">{startCase(charter.name)}</h2>
            {charter?.duty ? (
                <>
                    <SaveMenu
                        save={() => {
                            // charter.quoteLineItems = lineItems;
                            // setCharter(new Charter({...charter}));
                            charterModelData.save(charter).then(() => {
                                console.log('Charter saved.');
                                setMode({edit: false});
                            });
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                        showPrint={true}
                    />
                    
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={'4'}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: mode.edit,
                                    duty: mode.edit,
                                    quote: false,
                                }}
                            />
                        )}
                        <Col xs={24} lg={24} className="print-area">
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Summary</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} md={6}>
                                                <label>To (Address)</label>
                                                {/* <strong>Picton High School</strong>
                                                <small>480 Argyle St, Picton NSW 2571</small>
                                                <small>(02) 4677 1242</small> */}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 1"
                                                        status={!charter.address1?.length ? 'error' : ''}
                                                        value={charter.address1}
                                                        onChange={({target: {value}}) => setCharterWith('address1', value)}
                                                    />
                                                ) : (
                                                    <strong>{startCase(charter.address1)}</strong>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Line 2"
                                                        status={!charter.address2?.length ? 'error' : ''}
                                                        value={charter.address2}
                                                        onChange={({target: {value}}) => setCharterWith('address2', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.address2)}</small>
                                                )}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address Contact"
                                                        status={!charter.addressContact?.length ? 'error' : ''}
                                                        value={charter.addressContact}
                                                        onChange={({target: {value}}) => setCharterWith('addressContact', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.addressContact)}</small>
                                                )}
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <label>Quote Description</label>
                                                {/* <strong>Picton HS Swimming Carnival</strong> */}
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Address 1"
                                                        status={!charter.quoteDescription?.length ? 'error' : ''}
                                                        value={charter.quoteDescription}
                                                        onChange={({target: {value}}) => setCharterWith('quoteDescription', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.quoteDescription)}</small>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6} className="d-flex align-items-end">
                                                <img width={150}
                                                     src={`https://${
                                                         config.s3.BUCKET
                                                     }.s3.ap-southeast-2.amazonaws.com/public/${operator.operatorId}/logo.png?${Date.now()}`}
                                                     onError={(e) => {
                                                         e.nativeEvent.target.style.width = 0;
                                                         e.nativeEvent.target.style.height = 0;
                                                     }}
                                                />
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label>Quote Date</label>
                                                {/* <strong>27/06/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Quote Date"
                                                        {...(!isEmpty(charter.quoteDate) ? {value: charter.quoteDate} : {})}
                                                        status={!charter.quoteDate ? 'error' : ''}
                                                        format={{
                                                            format: DATE_STRING,
                                                            type: 'mask',
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith('quoteDate', value);
                                                            if (!charter.paymentDue) {
                                                                setCharterWith('paymentDue', value.add(2, 'w'));
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.quoteDate?.format ? charter.quoteDate.format(DATE_STRING) : 'TBC'}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label>Payment Due</label>
                                                {/* <strong>27/08/2024</strong> */}
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Payment Due"
                                                        {...(!isEmpty(charter.paymentDue) ? {value: charter.paymentDue} : {})}
                                                        status={!charter.paymentDue ? 'error' : ''}
                                                        format={{
                                                            format: DATE_STRING,
                                                            type: 'mask',
                                                        }}
                                                        onChange={(value) => {
                                                            setCharterWith('paymentDue', value);
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.paymentDue?.format ? charter.paymentDue.format(DATE_STRING) : 'TBC'}
                                                    </strong>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label>Start Date & Time</label>
                                                <strong>
                                                    {charter.startDate.format(DATE_STRING)} - {secsSinceMidnightToDayjs(charter.duty.getStartTime()).format('HH:mm')}
                                                </strong>
                                            </Col>
                                            {/*<Col xs={24} lg={6}>*/}
                                            {/*    <label>End Date & Time</label>*/}
                                            {/*    <strong>*/}
                                            {/*        {charter.endDate}*/}
                                            {/*    </strong>*/}
                                            {/*</Col>*/}
                                            <Col xs={24} md={6}>
                                                <label>Distance (kms)</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter Distance"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        value={charter.distance}
                                                        onChange={(value) => setCharterWith('distance', value)}
                                                        className='w-100'
                                                    />
                                                ) : (
                                                    <strong>{charter.distance}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label>Duration (hours)</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter Duration"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        value={charter.duration}
                                                        onChange={(value) => setCharterWith('duration', value)}
                                                        className='w-100'
                                                    />
                                                ) : (
                                                    <strong>{charter.duration}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label># Passengers</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter # Passengers"
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        value={charter.passengerCount}
                                                        onChange={(value) => setCharterWith('passengerCount', value)}
                                                        className='w-100'
                                                    />
                                                ) : (
                                                    <strong>{charter.passengerCount}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <label>Ref</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Ref"
                                                        status={!charter.ref?.length ? 'error' : ''}
                                                        value={charter.ref}
                                                        onChange={({target: {value}}) => setCharterWith('ref', value)}
                                                    />
                                                ) : (
                                                    <small>{startCase(charter.ref)}</small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col xs={24} lg={24}>
                                    <Card bordered={false} className="card-main">
                                        <Row className="card-row-header align-items-center">
                                            <Col xs={6} className="d-flex align-items-center">
                                                <h3>Charter Quote</h3>
                                                {mode.edit && (
                                                    <Button className="icon-button btn-sm" type="primary"
                                                            onClick={addLine}>
                                                        Add
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col xs={mode.edit ? 5 : 8} className="text-left">
                                                <span>Description</span>
                                            </Col>
                                            <Col xs={3}>
                                                <span>Unit price</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Qty</span>
                                            </Col>
                                            <Col xs={2}>
                                                <span>Disc(%)</span>
                                            </Col>
                                            <Col xs={3} className="text-right">
                                                <span>Amount</span>
                                            </Col>
                                            {mode.edit && <Col xs={3}>
                                                <span>Actions</span>
                                            </Col>}
                                        </Row>
                                        {charter.quoteLineItems?.map((line, index) => (
                                            <Row className="card-row-content border-full align-items-center"
                                                 style={{cursor: 'pointer'}}>
                                                <Col xs={6}>
                                                    {mode.edit ? (
                                                        <Input
                                                            placeholder="Name"
                                                            status={!line.name?.length ? 'error' : ''}
                                                            value={line.name}
                                                            onChange={({target: {value}}) => updateLine(index, 'name', value)}
                                                        />
                                                    ) : (
                                                        <strong>{startCase(line.name)}</strong>
                                                    )}
                                                </Col>
                                                <Col xs={mode.edit ? 5 : 8} className="text-left">
                                                    {mode.edit ? (
                                                        <Input
                                                            placeholder="Description"
                                                            value={line.description}
                                                            onChange={({target: {value}}) => updateLine(index, 'description', value)}
                                                        />
                                                    ) : (
                                                        <span>{line.description}</span>
                                                    )}
                                                </Col>
                                                <Col xs={3}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Unit Price"
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={line.unitPrice}
                                                            onChange={(value) => updateLine(index, 'unitPrice', value)}
                                                        />
                                                    ) : (
                                                        <span>${line.unitPrice}</span>
                                                    )}
                                                </Col>
                                                <Col xs={2}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Quantity"
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={line.quantity}
                                                            onChange={(value) => updateLine(index, 'quantity', value)}
                                                        />
                                                    ) : (
                                                        <span>{line.quantity}</span>
                                                    )}
                                                </Col>
                                                <Col xs={2}>
                                                    {mode.edit ? (
                                                        <InputNumber
                                                            placeholder="Discount"
                                                            max={100}
                                                            min={0}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={line.discount}
                                                            onChange={(value) => updateLine(index, 'discount', value)}
                                                        />
                                                    ) : (
                                                        <span>{line.discount}</span>
                                                    )}
                                                </Col>
                                                <Col xs={3} className="text-right">
                                                    ${calculateAmount(line)}
                                                </Col>
                                                {mode.edit && (
                                                    <Col xs={3} className="row-col-actions">
                                                        <LoaderButton className="btn-icon-control"
                                                                      onClick={() => cloneLine(index)}>
                                                            <Copy style={{width: 14}}/>
                                                        </LoaderButton>
                                                        <LoaderButton
                                                            className="btn-icon-control btn-delete"
                                                            onClick={() => deleteLine(index)}
                                                        >
                                                            <TrashIcon/>
                                                        </LoaderButton>
                                                    </Col>
                                                )}
                                            </Row>
                                        ))}
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong>Subtotal</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong style={{fontSize: '15px'}}>${calculateSubTotal}</strong>
                                            </Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center no-border">
                                            <Col xs={18}>
                                                <strong>Plus GST</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong style={{fontSize: '15px'}}>${calculateGST}</strong>
                                            </Col>
                                        </Row>
                                        <Row className="card-row-content border-full align-items-center">
                                            <Col xs={18}>
                                                <strong style={{fontSize: '18px'}}>Total</strong>
                                            </Col>
                                            <Col xs={mode.edit ? 3 : 6} className="text-right">
                                                <strong style={{fontSize: '18px'}}>${calculateTotal}</strong>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..."/>
            )}
        </div>
    );
}

export default React.memo(CharterQuote);
