import React, {useCallback, useEffect, useRef, useState} from 'react';
import DeckGL from '@deck.gl/react';
import {
    _MapContext,
    FlyToInterpolator,
    Marker,
    NavigationControl,
    Popup,
    StaticMap,
    WebMercatorViewport
} from 'react-map-gl';
import {AimOutlined, CloudUploadOutlined, CopyOutlined, FilterOutlined} from '@ant-design/icons';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {GeoJsonLayer} from '@deck.gl/layers';
import config from '../config';
import {
    getViewport,
    LATLON_PRECISION,
    toGeoJsonPtCollection,
    toGeoJsonStopCollection,
    toPublicGeoJson
} from '../libs/mapLib';
import './RouteMap.css';
import './RouteMapViewer.css';
import {hexToRgb, roundToPrecision} from '../libs/formatLib';
import Pin from './Pin';
import {getBearingAtCoordinate, getNearestWaypoint} from '../libs/routes-lib';
import RouteTitle from './RouteTitle';
import {isPointInPolygon} from 'geolib';

// eslint-disable-next-line
import {Button, ButtonGroup, Col, Image, Row, Toast} from 'react-bootstrap';
import MapLayerToolbar from './MapLayerToolbar';
import MapFilterToolbar from './MapFilterToolbar';
import MapToolbar from './MapToolbar';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import {filterVisibleStops} from '../libs/timetableLib';
import {localGeocoder, renderSuggestions} from '../libs/geocoderLib';
import {PathStyleExtension} from '@deck.gl/extensions';
import {GetFullUrl, getKey} from '../libs/hooksLib';
import {onError} from '../libs/errorLib';
import {debounce, flatten} from 'lodash';
import {Tooltip} from 'antd/lib';
import MapWpSelectorToolbar from './MapWpSelectorToolbar';
import {find} from 'lodash/collection';
import {cloneDeep} from 'lodash/lang';
import {intersectionBy} from 'lodash/array';
import {ReactComponent as TransferPin} from '../assets/icons/transfer-pin.svg';
import TransfersModal from '../features/Transfers/TransfersModal';
import {Popover} from 'antd';
import {BaseStop} from '../model/busRoute';
import {MAPBOX_STYLES} from './StopsMapViewer';
import {useAppContext} from '../libs/contextLib';
import Geocoder from 'react-map-gl-geocoder';
import ChatButton from './ChatButton';
import {filterComments} from '../hooks/useComments';
import ControlPanel from './ControlPanel';
import {RouteChat} from './ChatComponent';
import {ChatContext} from '../model/Comment';
import useAllComments from '../hooks/useAllComments';
import {ReactComponent as Chat} from '../assets/icons/Chat.svg';

const MAPBOX_ACCESS_TOKEN = config.maps.mabBox;

let defaultViewState = {longitude: 147.638367, latitude: -32.32370, zoom: 5, padding: 0, width: 800, height: 600};

const navStyle = {
    position: 'absolute', top: 0, left: 0, padding: '10px'
};


const preloadImgData = Array(360).fill(0).map((_, i) => {
    return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path transform="rotate(${i}, 256, 256)" d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z"/></svg>')`;
});

const showRoutes = ({
                        viewStateZoom,
                        props,
                        filteredRoutes
                    }) => viewStateZoom > 11 || props.tripPlanner || props.shiftBatBuilder || filteredRoutes.length < 200;

function RouteMapViewer({
                            match,
                            operatorId,
                            isLoading, className = '', setMapRef,
                            allowComments = false,
                            showComments, setShowComments,
                            zoom, setZoom,
                            ...props
                        }) {
    const fullUrl = GetFullUrl();
    const {isAuthenticated, editor} = useAppContext();
    const [key, setKey] = useState(null);

    const geocoderContainerRef = useRef(null);
    const deckRef = useRef(null);
    const mapRef = useRef(null);
    const [viewState, setViewState] = useState(null);
    const [layers, setLayers] = useState(null);
    const [markers, setMarkers] = useState();
    const [selectedRouteIds, setSelectedRouteIds] = useState([]);
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);
    const [selectedStop, setSelectedStop] = useState(null);
    const [selectedStops, setSelectedStops] = useState(null);
    const [popupData, setPopupData] = useState(null);
    // const [filter] = useState(props.filter);
    const [style, setStyle] = useState('light');
    const [show, setShow] = useState(false);
    const [poiMarker, setPoiMarker] = useState(null);
    const [transferMarker, setTransferMarker] = useState(null);

    const [startMarker, setStartMarker] = useState(null);
    const [endMarker, setEndMarker] = useState(null);

    const [userDefinedViewport, setUserDefinedViewport] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showCurrentLocation, setShowCurrentLocation] = useState(false);
    const [currentLocationTimer, setCurrentLocationTimer] = useState(null);

    const [selectedStartWpIdx, setSelectedStartWpIdx] = useState(-1);
    const [selectedEndWpIdx, setSelectedEndWpIdx] = useState(-1);
    const [wpIdxsInView, setWpIdxsInView] = useState([]);
    const [viewStateZoom, setViewStateZoom] = useState(0);
    // const [chatContext, setChatContext] = useState(ChatContext.fromContext({
    //     type: 'all',
    //     setSelectedRouteIds: setZoom,
    //     setFocusPt: props.setFocusPt
    // }));
    const [chatContext, setChatContext] = useState(null);
    const [chatOpen, setChatOpen] = useState(null);
    const {allRouteComments} = useAllComments();
    const [selectedChatContext, setSelectedChatContext] = useState(null);

    useEffect(() => {
        if (showCurrentLocation && !currentLocationTimer) {
            console.log('Setting up location timer');
            setCurrentLocationTimer(setInterval(() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    setCurrentLocation(position);
                });
            }, 2000));
        } else if (!showCurrentLocation && currentLocationTimer) {
            console.log('Clearing location timer');
            clearInterval(currentLocationTimer);
        }
        return () => {
            if (currentLocationTimer) {
                console.log('Clearing location timer');
                clearInterval(currentLocationTimer);
            }
        };
    }, [showCurrentLocation, setCurrentLocation, currentLocationTimer]);

    useEffect(() => {
        async function onLoad() {
            try {
                const _key = operatorId || await getKey();
                setKey(_key);
            } catch (e) {
                onError(e);
            }
        }

        onLoad().then(() => console.log('Key loaded.'));
    }, [setKey]);

    useEffect(() => {
        setSelectedStop(props.selectedStop);
        setSelectedStops(props.selectedStops);
    }, [props.selectedStop, props.selectedStops]);

    useEffect(() => {
        if ((props.focusTransfers?.to?.length || props.focusTransfers?.from?.length)) {
            setTransferMarker({
                ...props.focusTransfers.stop,
                transfersTo: props.focusTransfers.to,
                transfersFrom: props.focusTransfers.from
            });
        } else {
            setTransferMarker(null);
        }
    }, [props.focusTransfers, setTransferMarker]);

    // useEffect(() => {
    //     if (props.selectedRouteIds) {
    //         setSelectedRouteIds(props.selectedRouteIds)
    //         if (props.selectedRouteIds.length === 1) {
    //             setChatContext({type: 'route', id: props.selectedRouteIds[0], subType: 'wp'})
    //         } else {
    //             setChatContext({});
    //         }
    //     } else {
    //         setChatContext({});
    //     }
    // }, [props.selectedRouteIds, setChatContext])

    useEffect(() => {
        if (props.viewState) {
            setViewState(props.viewState);
        } else {
            setViewState(viewState => {
                if (!viewState) {
                    console.log('Setting default view state');
                    return defaultViewState;
                }
            });
        }
    }, [props.viewState, setViewState]);

    useEffect(() => {
        if (props.poiMarker) {
            console.log('setting poi marker');
            setPoiMarker(props.poiMarker);
        }
    }, [props.poiMarker]);

    useEffect(() => {
        if (props.startMarker) {
            console.log('setting startMarker');
            setPoiMarker(props.startMarker);
        }
    }, [props.startMarker]);

    useEffect(() => {
        if (props.endMarker) {
            console.log('setting endMarker');
            setPoiMarker(props.endMarker);
        }
    }, [props.endMarker]);

    useEffect(() => {
        if (props.popupData) {
            setPopupData(props.popupData);
        }
    }, [props.popupData]);

    useEffect(() => {
        if (props.highlightedRouteIds) {
            setHighlightedRouteIds(props.highlightedRouteIds);
        }
    }, [props.highlightedRouteIds]);

    useEffect(() => {
        if (props.filter) {
            setStartMarker(props.filter.from);
            setEndMarker(props.filter.to);
        }
    }, [props.filter]);

    useEffect(() => {
        if (mapRef && setMapRef) {
            console.log('setting mapRef');
            setMapRef(mapRef);
        }
    }, [mapRef, setMapRef]);

    useEffect(() => {
        if (!props.showComments) {
            setPoiMarker(null);
        }
    }, [showComments, setPoiMarker]);

    // eslint-disable-next-line
    const updateStuffInView = useCallback(
        debounce((viewStateProps) => {
            console.log('Updating stuff in view');
            setViewStateZoom(viewStateProps?.zoom || viewStateProps.viewState.zoom);
            const viewport = new WebMercatorViewport(viewStateProps.viewState || viewStateProps);
            const bounds = viewport.getBoundingRegion();

            let minLat = Math.min(...bounds.map((p) => p[1]));
            let minLon = Math.min(...bounds.map((p) => p[0]));
            let maxLat = Math.max(...bounds.map((p) => p[1]));
            let maxLon = Math.max(...bounds.map((p) => p[0]));

            const pg = [
                {latitude: maxLat, longitude: minLon},
                {latitude: minLat, longitude: minLon},
                {latitude: minLat, longitude: maxLon},
                {latitude: maxLat, longitude: maxLon},
            ];
            const wpsInView = flatten(props.filteredRoutes.filter(r => !props.highlightedRouteIds?.length || props.highlightedRouteIds.includes(r.routeId)).map(r => r.waypoints)).reduce((a, e, i) => {
                if (isPointInPolygon({latitude: e.lat, longitude: e.lon}, pg))
                    a.push(i);
                return a;
            }, []);
            setWpIdxsInView(wpsInView);
        }, 500), [setWpIdxsInView, props.filteredRoutes, setViewStateZoom, props.highlightedRouteIds]
    );

    useEffect(() => {
        if (viewState) {
            updateStuffInView(viewState);
        }
    }, [viewState, updateStuffInView, props.highlightedRouteIds]);

    useEffect(() => {
        let highlightedRoutes = [];
        let layers = [];

        let filteredRoutes = props.filteredRoutes;
        // console.log(`zoom: ${zoom} filtered: ${filteredRoutes.map(r => r.routeId)} selectedRouteIds: ${selectedRouteIds} highlightedRouteId: ${highlightedRouteIds}`)
        // if (selectedRouteIds.length) {
        //     filteredRoutes = filteredRoutes.filter(r => selectedRouteIds.indexOf(r.routeId) > -1)
        // }
        if (filteredRoutes?.length) {
            filteredRoutes.forEach(route => {
                let lineWidth = 2;
                if (highlightedRouteIds.includes(route.routeId)) {
                    highlightedRoutes.push(route);
                    return;
                }
                if (!highlightedRouteIds?.length && showRoutes({viewStateZoom, props, filteredRoutes})) {

                    if (!route.routeNumber && !route.deadrun) { // connecting route in trip planner
                        return;
                    }

                    let colour = hexToRgb(props.colour || route.colour);
                    if (highlightedRoutes.length || route.deadrun) {
                        colour.push(75);
                    }
                    let path = toPublicGeoJson(route);
                    let layerProps = {
                        id: `route-layer-${route.routeId}`,
                        data: path,
                        lineWidthMinPixels: lineWidth,
                        getLineColor: () => {
                            return colour;
                        },
                        pickable: true
                    };
                    if (route.deadrun) {
                        layerProps = {
                            ...layerProps,
                            getDashArray: (feature) => {
                                return [4, 3];
                            },
                            dashJustified: false,
                            extensions: [new PathStyleExtension({highPrecisionDash: true})]
                        };
                    }
                    layers.push(new GeoJsonLayer(layerProps));

                }
            });
            if (highlightedRoutes.length) {
                highlightedRoutes.filter(r => r.routeNumber && !r.deadrun).forEach(highlightedRoute => {

                    let selectedPath = toPublicGeoJson(highlightedRoute);
                    let layerProps = {
                        id: `route-layer-${highlightedRoute.routeId}`,
                        data: selectedPath,
                        lineWidthMinPixels: 5,
                        getLineColor: () => {
                            return hexToRgb(props.colour || highlightedRoute.colour);
                        },
                        pickable: true,
                    };
                    layers.push(new GeoJsonLayer(layerProps));

                    Object.keys(highlightedRoute.services).forEach(tripId => {
                        highlightedRoute.services[tripId].stopTimes.forEach(st => {
                            if (st.transfersTo?.length) {
                                intersectionBy((props.focusTransfers?.to || []), st.transfersTo, 'routeId').forEach(tx => {
                                    const txRoute = cloneDeep(find(props.routes, ['routeId', tx.routeId], 0));
                                    if (!txRoute) return;
                                    txRoute.calculateStartEnd({firstStop: st});
                                    let selectedPath = toPublicGeoJson(txRoute);
                                    let layerProps = {
                                        id: `tx-to-route-layer-${txRoute.routeId}`,
                                        data: selectedPath,
                                        lineWidthMinPixels: 5,
                                        getLineColor: () => {
                                            return hexToRgb(txRoute.colour);
                                        },
                                        pickable: true,
                                    };
                                    layers.push(new GeoJsonLayer(layerProps));
                                });
                            }
                            if (st.transfersFrom?.length) {
                                intersectionBy((props.focusTransfers?.from || []), st.transfersFrom, 'routeId').forEach(tx => {
                                    const txRoute = cloneDeep(find(props.routes, ['routeId', tx.routeId], 0));
                                    if (!txRoute) return;
                                    txRoute.calculateStartEnd({lastStop: st});
                                    let selectedPath = toPublicGeoJson(txRoute);
                                    let layerProps = {
                                        id: `tx-from-route-layer-${txRoute.routeId}`,
                                        data: selectedPath,
                                        lineWidthMinPixels: 5,
                                        getLineColor: () => {
                                            return hexToRgb(txRoute.colour);
                                        },
                                        pickable: true,
                                    };
                                    layers.push(new GeoJsonLayer(layerProps));
                                });
                            }
                        });
                    });
                });

                layers.push(new GeoJsonLayer(
                    {
                        id: `selected-wps`,
                        data: toGeoJsonPtCollection(null, flatten(highlightedRoutes.map(r => r.waypoints)).slice(selectedStartWpIdx, selectedEndWpIdx + 1)),
                        radiusScale: 5,
                        pointRadiusMinPixels: 8,
                        pointRadiusMaxPixels: 12,
                        lineWidthMinPixels: 2,
                        lineWidthMaxPixels: 8,
                        getLineColor: [255, 255, 255],
                        getFillColor: [255, 0, 0],
                        getPointRadius: 8,
                        pointRadiusUnits: 'pixels',
                    }
                ));

                highlightedRoutes.filter(r => !r.routeNumber || r.deadrun).forEach(highlightedRoute => {
                    let selectedPath = toPublicGeoJson(highlightedRoute);
                    layers.push(new GeoJsonLayer({
                        id: `route-layer-${highlightedRoute.routeId}`,
                        data: selectedPath,
                        lineWidthMinPixels: 5,
                        getLineColor: () => {
                            return hexToRgb(props.colour || highlightedRoute.colour);
                        },
                        pickable: true,
                        getDashArray: (feature) => {
                            return [4, 3];
                        },
                        dashJustified: false,
                        extensions: [new PathStyleExtension({highPrecisionDash: true})],
                    }));
                });
            } else {
                layers.push(new GeoJsonLayer(
                    {
                        id: `selected-wps`,
                        data: toGeoJsonPtCollection(null, flatten(props.filteredRoutes.map(r => r.waypoints)).slice(selectedStartWpIdx, selectedEndWpIdx + 1)),
                        radiusScale: 5,
                        pointRadiusMinPixels: 8,
                        pointRadiusMaxPixels: 12,
                        lineWidthMinPixels: 2,
                        lineWidthMaxPixels: 8,
                        getLineColor: [255, 255, 255],
                        getFillColor: [255, 0, 0],
                        getPointRadius: 8,
                        pointRadiusUnits: 'pixels',
                    }
                ));
            }
        } else if (props.stops && props.stops.length) {

            if (zoom) {
                if (selectedStop) {
                    setViewState(viewState => {
                        if (!userDefinedViewport && Number.isFinite(selectedStop.lat) && Number.isFinite(selectedStop.lon)) {
                            return {
                                ...viewState,
                                latitude: selectedStop.lat,
                                longitude: selectedStop.lon,
                                transitionInterpolator: new FlyToInterpolator(),
                                transitionDuration: 500
                            };
                        }
                        return {...viewState};
                    });
                }
            } else {
                setViewState(viewState => {
                    if (!userDefinedViewport) {
                        return getViewport({geos: props.stops, viewport: viewState});
                    }
                    return {...viewState};
                });
            }

            setMarkers(props.stops.map(stop => {
                return {
                    ...stop,
                    selected: (selectedStop && (selectedStop.stopId === stop.stopId)) || (selectedStops && selectedStops.indexOf(stop.stopId) > -1),
                    verified: props.verifiedStops && props.verifiedStops.indexOf(stop.stopId) > -1,
                    // focused: props.focusStop && props.focusStop.stopId === stop.stopId
                };
            }));
        }
        // } else {
        let data;
        let _markers = [];
        if (props.filteredRoutes?.length && highlightedRouteIds?.length) {
            highlightedRouteIds.forEach((rId, idx) => {
                const selectedRoute = props.filteredRoutes[props.filteredRoutes.findIndex(r => r.routeId === rId)];
                if (selectedRoute) {
                    data = filterVisibleStops(props.allStops, [selectedRoute], props.verifiedOnly);
                    _markers = _markers.concat(data.map((stop, stopIdx) => {

                        // Removed routes that aren't published yet.
                        let _routes = stop.routes ? stop.routes.filter(r => r.published) : [];
                        const sequence = selectedRoute.getPublicStopIndex(stop, selectedRoute.startStopIdx) + 1;
                        return {
                            ...stop,
                            routes: _routes,
                            routeId: selectedRoute.routeId + '_' + idx,
                            sequence,
                            count: data.slice(0, stopIdx).filter(st => stop.stopId === st.stopId).length || 0,
                            selected: selectedStop && selectedStop.stopId === stop.stopId
                        };
                    }).reverse().filter(stop => stop.sequence));
                }
            });
        }

        if (!data) {
            data = filterVisibleStops(props.allStops, props.filteredRoutes, props.verifiedOnly);
        }

        if (data?.length && !userDefinedViewport) {
            setViewState(viewState => {
                if (!props.filteredRoutes?.length) {
                    // console.log('setting viewport based on stops')
                    // return getViewport({geos: data, viewport: viewState});
                    const viewport = getViewport({geos: data, viewport: viewState});
                    return {
                        ...viewport,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionDuration: 500
                    };
                }
                return viewState;
            });
        }

        if (props.locations?.length) { // Show locations
            _markers = _markers.concat(props.locations.map((location, stopIdx) => {

                // Removed routes that aren't published yet.
                const sequence = stopIdx + 1;
                return {
                    ...location,
                    sequence,
                    stopType: 'shift',
                    count: props.locations.slice(0, stopIdx).filter(prevLocation => prevLocation && location.geohash === prevLocation.geohash).length || 0,
                    stopTimeId: sequence,
                    routeId: 'shift'
                };
            }));
        }

        setMarkers(_markers);
        if (data && !_markers?.length && !showComments) {
            layers.push(new GeoJsonLayer({
                id: 'scatter-plot',
                data: toGeoJsonStopCollection(data, props.stopOpts),
                // selectedFeatureIndexes: selectedIdx >= 0 ? [selectedIdx] : [],
                pickingRadius: 10,
                stroked: true,
                filled: true,
                radiusScale: 5,
                pointRadiusMinPixels: 8,
                pointRadiusMaxPixels: 12,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 8,
                pickable: true,
                getLineColor: (d) => {
                    // return [255, 255, 255]
                    return props.focusStop?.stopId === d.stop.stopId ? [0, 123, 255] : [255, 255, 255];
                    // return d.stop.focused || d.stop.selected || d.stop.editing ? [0, 123, 255] : [255, 255, 255]
                },
                getFillColor: (d) => {
                    return !d.stop.verified ? [220, 53, 69] : d.stop.stopType === 'nonpub' ? [167, 165, 165] :
                        d.stop.stopType === 'school' ? [240, 173, 78] : d.stopType === 'depot' ? [79, 79, 79] :
                            d.stop.stopType === 'venue' ? [64, 144, 162] : [0, 123, 255];
                },
                onHover: info => {
                    // if (!info.object) {
                    //     return
                    // }
                    // onFocusStop(info.object && info.object.stop ? info.object.stop.stopId : null);
                },
                onClick: pickInfo => {
                    // console.log(util.inspect(pickInfo));
                    const stop = pickInfo.object && pickInfo.object.stop;
                    setPopupData(stop);
                },
            }));
        }
        if (showCurrentLocation && currentLocation && Number.isFinite(currentLocation?.coords?.latitude) && Number.isFinite(currentLocation?.coords?.longitude)) {
            console.log('Current location is: ', currentLocation.coords);
            layers.push(new GeoJsonLayer({
                id: 'my-location',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [currentLocation.coords.longitude, currentLocation.coords.latitude]
                    }
                },
                stroked: true,
                filled: true,
                radiusScale: 5,
                pointRadiusMinPixels: 8,
                pointRadiusMaxPixels: 12,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 8,
                pickable: true,
                getLineColor: () => {
                    return [255, 255, 255];
                },
                getFillColor: () => {
                    return [23, 180, 45];
                }
            }));
            if (!userDefinedViewport) {
                setViewState(viewState => {
                    return {
                        ...viewState,
                        latitude: currentLocation.coords.latitude,
                        longitude: currentLocation.coords.longitude,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionDuration: 500
                    };
                });
            }
        }

        if (allowComments && showComments && props.filteredRoutes?.length) {
            if (props.focusPt) {
                setViewState(viewState => {
                    if (Number.isFinite(props.focusPt.lat) && Number.isFinite(props.focusPt.lon)) {
                        return {
                            ...viewState,
                            zoom: 16,
                            latitude: props.focusPt.lat,
                            longitude: props.focusPt.lon,
                            transitionInterpolator: new FlyToInterpolator(),
                            transitionDuration: 500
                        };
                    }
                    return {...viewState};
                });
                setPoiMarker(props.focusPt);
                props.setFocusPt(null);

            }
            const filteredComments = flatten(props.filteredRoutes.map(route => filterComments({
                chatContext: {
                    type: 'route',
                    id: route.routeId
                }, allRouteComments, editor
            })));
            layers.push(new GeoJsonLayer({
                id: 'comments-plot',
                data: toGeoJsonStopCollection(filteredComments.filter(c => Number.isFinite(c.lat) && Number.isFinite(c.lon) && (!highlightedRouteIds?.length || highlightedRouteIds.includes(c.id))), {objName: 'comment'}),
                // pointType: 'icon',
                // getIcon: d => {
                //     return {url: 'http://localhost:3000/location-pin.png', width: 500, height: 500}
                // },
                pickingRadius: 10,
                stroked: true,
                filled: true,
                radiusScale: 5,
                pointRadiusMinPixels: 8,
                pointRadiusMaxPixels: 12,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 8,
                pickable: true,
                getLineColor: (d) => {
                    // const rgb = [0,0,0];
                    // return [...rgb, d.comment?.unRead || chatOpen.includes(d.comment?.refId) ? 255 : 0]
                    const rgb = d.comment?.route?.colour ? hexToRgb(d.comment.route.colour) : [255, 255, 255];
                    // return [...rgb, d.comment?.unRead || chatOpen === ChatContext.toId(d.comment) ? 255 : 100]
                    return d.comment?.unRead || chatOpen === ChatContext.toId(d.comment) ? [255, 255, 255] : [150, 150, 150, 100];
                },
                getFillColor: (d) => {
                    // const rgb = d.comment?.route?.colour ? hexToRgb(d.comment.route.colour) : [255, 255, 255];
                    // return [...rgb, d.comment?.unRead || chatOpen.includes(d.comment?.refId) ? 255 : 150]
                    // const rgb = [255, 255, 255]
                    // return d.comment?.unRead || chatOpen === ChatContext.toId(d.comment) ? rgb : [150, 150, 150, 100]
                    const rgb = d.comment?.route?.colour ? hexToRgb(d.comment.route.colour) : [255, 255, 255];
                    // return [...rgb, d.comment?.unRead || chatOpen === ChatContext.toId(d.comment) ? 255 : 100]
                    return d.comment?.unRead || chatOpen === ChatContext.toId(d.comment) ? rgb : [...rgb, 100];
                },
                onHover: info => {
                    // if (!info.object) {
                    //     return
                    // }
                    // onFocusStop(info.object && info.object.stop ? info.object.stop.stopId : null);
                },
                onClick: pickInfo => {
                    const comment = pickInfo.object?.comment;
                    if (comment) {
                        setSelectedChatContext(new ChatContext(comment));
                        setZoom(zoom => {
                            if (!zoom) {
                                zoom = [comment.id];
                            }
                            if (!zoom?.includes(comment.id)) {
                                zoom = zoom.concat(comment.id);
                            }
                            return zoom;
                        });
                        //     const id = ChatContext.toId(comment)
                        //     setChatOpen(id)
                        //     // setChatOpen(open => {
                        //     //     if (open.includes(id)) {
                        //     //         return open.filter(o => o !== id)
                        //     //     }
                        //     //     return open.concat(id);
                        //     // });
                    }
                },
            }));
        }

        // if (process.env.REACT_APP_VERBOSE && props.filteredRoutes?.length && highlightedRouteIds?.length) {
        //     layers.push(new GeoJsonLayer({
        //         id: 'verbose-wp-plot',
        //         data: toGeoJsonPtCollection(props.filteredRoutes),
        //         pickingRadius: 10,
        //         stroked: true,
        //         filled: true,
        //         radiusScale: 5,
        //         pointRadiusMinPixels: 4,
        //         pointRadiusMaxPixels: 6,
        //         lineWidthMinPixels: 1,
        //         lineWidthMaxPixels: 4,
        //         getLineColor: (d) => {
        //             return [255, 255, 255]
        //         },
        //         getFillColor: (d) => {
        //             return d?.properties?.route?.colour || [0,0,0]
        //         },
        //         onHover: info => {
        //             if (!info.object) {
        //                 return
        //             }
        //             console.log('WP Idx: ', info.object.properties.wpIdx);
        //         },
        //         // onClick: pickInfo => {
        //         // console.log(util.inspect(pickInfo));
        //         // const stop = pickInfo.object && pickInfo.object.stop;
        //         // setPopupData(stop)
        //         // },
        //     }));
        // }
        setLayers(layers);
    }, [props.filteredRoutes, props.colour, props.stopOpts, props.verifiedOnly, showCurrentLocation,
        currentLocation, selectedRouteIds, highlightedRouteIds, zoom, selectedStop, selectedStops,
        props.stops, props.allStops, props.verifiedStops, setViewState, userDefinedViewport, props.tripPlanner,
        startMarker, endMarker, props.focusStop, props.locations, selectedStartWpIdx, selectedEndWpIdx, wpIdxsInView,
        props.focusTransfers, viewStateZoom, props.focusPt, props.setFocusPt, setChatContext, setChatOpen,
        showComments, allowComments, chatOpen, setZoom, props.routes, props.highlightedRouteIds
    ]);


    // useEffect(() => {
    //     console.log('Updating stops on map...')
    // setMarkers(values(props.allStops)
    //     .filter(stop => !props.filteredRoutes || !props.filteredRoutes.length || props.filteredRoutes
    //         .some(route => route.getPublicStops().findIndex(routeStop => routeStop.stopId === stop.stopId) > -1))
    //     .map(stop => {
    //         // Removed routes that aren't published yet.
    //         let _routes = stop.routes ? stop.routes.filter(r => props.filteredRoutes.findIndex(publishedRoute => r.routeId === publishedRoute.routeId) > -1) : [];
    //         return {
    //             ...stop,
    //             routes: _routes,
    //             selected: selectedStop && selectedStop.stopId === stop.stopId
    //         }
    //     }));

    // }, [setMarkers, props.allStops, props.filteredRoutes, selectedStop])

    useEffect(() => {
        setViewState(viewState => {
            if (!userDefinedViewport && props.filteredRoutes?.some(r => r.stops?.length)) {
                // console.log('setting viewport based on routes')
                const viewport = getViewport({
                    geos: flatten(props.filteredRoutes.map(r => r.stops.filter(s => !!s && s.lat && s.lon))),
                    viewport: viewState
                });
                return {
                    ...viewport,
                    transitionInterpolator: new FlyToInterpolator(),
                    transitionDuration: 500
                };
            } else {
                return viewState;
            }
        });
    }, [setViewState, props.filteredRoutes, userDefinedViewport]);

    useEffect(() => {
        if (selectedStop) {
            setViewState(viewState => {
                const bounds = new WebMercatorViewport(viewState).getBoundingRegion();

                if (!isPointInPolygon({
                    latitude: selectedStop.lat, longitude: selectedStop.lon
                }, bounds)) {
                    if (Number.isFinite(selectedStop.lat) && Number.isFinite(selectedStop.lon)) {
                        return {
                            ...viewState,
                            latitude: selectedStop.lat,
                            longitude: selectedStop.lon,
                            transitionInterpolator: new FlyToInterpolator(),
                            transitionDuration: 500
                        };
                    }
                }
                return {...viewState};

            });
            setPopupData(selectedStop);
        } else {
            setPopupData(null);
        }
    }, [selectedStop, setViewState, setPopupData, userDefinedViewport]);

    // const onViewStateChange = useCallback(({viewState}) => {
    // console.log("view state changed.")
    // }, []);

    let handleStopClick = useCallback((stop) => {
        props.handleStopClick && props.handleStopClick(stop);
    }, [props]);


    const onClick = useCallback(event => {

        const pickInfo = deckRef.current.pickMultipleObjects({
            x: event.x, y: event.y, radius: 10, depth: 100
        });
        if (pickInfo.length) {
            if (pickInfo[0].object && pickInfo[0].object.stop) {
                // setPopupData(pickInfo[0].object.stop)
                // setChatContext(cc => cc.clone({
                //     type: 'stop',
                //     id: pickInfo[0].object.stop.stopId,
                //     stop: pickInfo[0].object.stop
                // }))
                if (props.onMapSelection) {
                    props.onMapSelection(pickInfo[0].object.stop);
                    return props.setOnMapSelection(null);
                }
            } else if (pickInfo[0].object?.comment) {
                // setPopupData(pickInfo[0].object.comment)
                // setChatContext(cc => cc.clone(pickInfo[0].object.comment))
                if (props.onMapSelection) {
                    props.onMapSelection(pickInfo[0].object.comment);
                    return props.setOnMapSelection(null);
                }
            } else {
                const routes = pickInfo.filter(p => p.object && p.object.properties && p.object.properties.route && p.object.properties.route.published).map(p => p.object.properties.route);
                if (routes && routes.length) {
                    setPopupData({
                        lat: event.coordinate[1],
                        lon: event.coordinate[0],
                        routes
                    });
                } else {
                    setPopupData(null);
                }
            }
        } else {
            setPopupData(null);
        }

        if (props.onMapSelection) {
            props.onMapSelection({
                lat: event.coordinate[1],
                lon: event.coordinate[0],
            });
            return props.setOnMapSelection(null);
        }
    }, [setPopupData, props.onMapSelection]);

    const handleViewportChange = useCallback((viewState, state, oldViewState) => {
        if (viewState?.viewState) {
            setViewState(viewState.viewState);
            // if (props.setViewState) {
            //     props.setViewState(viewState.viewState)
            // }
        }
        updateStuffInView(viewState);
    }, [setViewState, updateStuffInView]);

    const handleGeocoderViewportChange = useCallback((newViewport) => {
        const geocoderDefaultOverrides = {transitionDuration: 1000};

        return handleViewportChange({
            viewState: {
                ...newViewport, ...geocoderDefaultOverrides
            }
        });
    }, [handleViewportChange]);

    const theLocalGeoCoder = useCallback((query) => {
        return localGeocoder({
            query,
            allStops: props.allStops,
            filteredRoutes: props.filteredRoutes,
            verifiedOnly: props.verifiedOnly,
            routes: props.routes
        });
    }, [props.routes, props.allStops, props.filteredRoutes, props.verifiedOnly]);


    const filterToQuery = useCallback((routes, selectedStop) => {
        let queries = [];
        if (selectedStop) {
            queries.push('s=' + selectedStop.stopId);
        }
        if (routes && routes.length) {
            queries.push(`rs=${routes.map(r => r.routeId.includes('|') ? r.routeId.split('|')[1] : r.routeId).join(',')}`);
        }
        if (props?.filter?.school !== props?.filter?.regular) {
            queries.push(`${props?.filter?.school ? 'schl' : 'reg'}=true`);
        }
        return queries.join('&');
    }, [props.filter]);

    const onResult = useCallback((event) => {
        if (event.result.place_type.indexOf('stop') > -1) {
            const stop = event.result.properties.stop;
            setSelectedStop(stop);
            setPopupData(stop);
            setPoiMarker(null);
        } else if (event.result.place_type.indexOf('route') > -1) {
            const popupPt = getNearestWaypoint({
                lat: event.result.center[1],
                lon: event.result.center[0]
            }, event.result.properties.route.waypoints);
            setPopupData({
                ...popupPt.nearestWp, routes: [event.result.properties.route]
            });
            setPoiMarker(null);
        } else {
            setPoiMarker(event.result);
            setPopupData(event.result);
        }
        return event;
    }, [setSelectedStop, setPopupData, setPoiMarker]);

    // useEffect(() => {
    //     if (currentLocation) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             console.log("Latitude is :", position.coords.latitude);
    //             console.log("Longitude is :", position.coords.longitude);
    //             if (!Number.isFinite(position?.coords?.latitude) || !Number.isFinite(position?.coords?.longitude)) {
    //                 return
    //             }
    //             setLayers(layers => {
    //                 layers.push(new GeoJsonLayer({
    //                     id: 'my-location',
    //                     data: {
    //                         type: 'Feature',
    //                         geometry: {
    //                             type: 'Point',
    //                             coordinates: [position.coords.longitude, position.coords.latitude]
    //                         },
    //                         properties: {
    //                             'title': 'Here I am!',
    //                             'marker-color': '#ff8888',
    //                             'marker-symbol': 'star'
    //                         }
    //                     },
    //                     stroked: true,
    //                     filled: true,
    //                     radiusScale: 5,
    //                     pointRadiusMinPixels: 8,
    //                     pointRadiusMaxPixels: 12,
    //                     lineWidthMinPixels: 2,
    //                     lineWidthMaxPixels: 8,
    //                     pickable: true,
    //                     getLineColor: (d) => {
    //                         return [255, 255, 255]
    //                         // return d.stop.focused || d.stop.selected || d.stop.editing ? [0, 123, 255] : [255, 255, 255]
    //                         // return d.stop.focused || d.stop.selected || d.stop.editing ? [0, 123, 255] : [255, 255, 255]
    //                     },
    //                     getFillColor: (d) => {
    //                         return [0, 255, 0]
    //                     },
    //                     onHover: info => {
    //                         // if (!info.object) {
    //                         //     return
    //                         // }
    //                         // onFocusStop(info.object && info.object.stop ? info.object.stop.stopId : null);
    //                     },
    //                 }));
    //                 return [...layers];
    //             })
    //             setViewState(viewState => {
    //                 return {
    //                     ...viewState,
    //                     latitude: position.coords.latitude,
    //                     longitude: position.coords.longitude,
    //                     transitionInterpolator: new FlyToInterpolator(),
    //                     transitionDuration: 500
    //                 }
    //             });
    //         });
    //     } else {
    //         setLayers(layers => layers.filter(l => l?.id !== 'my-location'))
    //     }
    //
    // }, [setLayers, setViewState, currentLocation]);

    const getChatControlPanel = useCallback((routeId) => {
        const route = props.filteredRoutes.find(r => r.routeId === routeId);
        if (!route) return <></>;
        return <ControlPanel
            key={`CtrlPnl-chat-${routeId || 'new'}`}
            immutable={true} className="ControlPanel CommentsPanel"
        >
            <RouteChat
                chatContext={new ChatContext({
                    type: 'route',
                    id: routeId,
                    route,
                    subType: 'wp',
                    showAllComments: true,
                    setFocusPt: props.setFocusPt,
                    setSelectedRouteIds: props.handleSelectedRoute
                })}
                selectedChatContext={selectedChatContext}
                newChatContext={chatContext}
                onSave={() => setChatContext(null)}
                onClose={() => setChatContext(null)}
                editor={editor}/>
        </ControlPanel>;
    }, [setChatOpen, setChatContext, chatContext, editor, props.setFocusPt, props.filteredRoutes, props.handleSelectedRoute, selectedChatContext]);

    return (
        <>
            <>
                {preloadImgData.map((data, idx) => <span key={`BG-Img-${idx}`} style={{background: data}}/>)}
            </>
            {viewState && layers && (<>
                <Toast animation={false} style={{
                    position: 'absolute',
                    top: 15,
                    right: 0,
                    left: 0,
                    margin: 'auto',
                    zIndex: 1000
                }} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    {/*<Toast.Header>{show}</Toast.Header>*/}
                    <Toast.Body>{show}</Toast.Body>
                </Toast>
                <div className="MapSearch">
                    <div ref={geocoderContainerRef}/>
                </div>
                <div className="ControlPanelContainer">
                    {allowComments && showComments ? (highlightedRouteIds?.length ?
                            highlightedRouteIds.map(routeId => getChatControlPanel(routeId)) :
                            chatContext ? getChatControlPanel(chatContext.id) : <></>) :
                        <></>
                    }
                </div>
                <DeckGL
                    ref={deckRef}
                    style={{overflow: 'hidden', borderRadius: '8px 0 0 8px'}}
                    viewState={viewState}
                    controller={{
                        doubleClickZoom: true
                    }}
                    onViewStateChange={handleViewportChange}
                    onInteractionStateChange={(state) => {
                        if (state?.isDragging || state?.isZooming || state?.isPanning || state?.isRotating) {
                            setUserDefinedViewport(true);
                        }
                    }}
                    ContextProvider={_MapContext.Provider}
                    layers={layers}
                    onClick={onClick}
                    getCursor={({isHovering, isDragging}) => {
                        return props.onMapSelection ? 'crosshair' : isHovering ? 'pointer' : isDragging ? 'grabbing' : 'grab';
                    }}
                    getTooltip={(info) => {
                        if (info.object && info.object.properties && info.object.properties.route) {
                            const coordinate = {
                                lon: info.coordinate[0], lat: info.coordinate[1]
                            };
                            let bearing = parseInt(getBearingAtCoordinate(coordinate, info.object.properties.route));
                            return {
                                style: {
                                    background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path transform="rotate(${bearing - 90}, 256, 256)" d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z"/></svg>')`,
                                    backgroundColor: '#fff',
                                    borderRadius: '10px',
                                    marginTop: '-20px'
                                }
                            };
                        }
                    }}
                    pickingRadius={10}
                >
                    <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                               mapStyle={MAPBOX_STYLES[style]}
                               ref={mapRef}/>

                    {

                        markers && markers.map(marker => {
                            return (<Marker
                                key={`Marker-${marker.routeId}-${marker.stopTimeId}`}
                                offsetTop={-13}
                                offsetLeft={-9}
                                latitude={Number.isFinite(marker.lat) ? marker.lat : 0}
                                longitude={Number.isFinite(marker.lon) ? marker.lon + (marker.count * 0.00006 * (20-viewStateZoom)) : 0}
                                className={marker.selected || (props.focusStop && props.focusStop.stopId === marker.stopId) ? 'FocusStop' : ''}
                                onClick={(e) => {
                                    // setSelectedStop(marker)
                                    handleStopClick(marker);
                                }}
                            >
                                <Pin size={18} type={marker.stopType}
                                     {...marker}
                                     focused={props.focusStop && props.focusStop.stopId === marker.stopId}
                                     onClick={() => handleStopClick(marker)}
                                />
                            </Marker>);
                        })}
                    <>
                        {
                            poiMarker ?
                                <Marker
                                    key={`POIMarker`}
                                    offsetTop={-40}
                                    offsetLeft={-20}
                                    latitude={poiMarker.lat || poiMarker.center[1]}
                                    longitude={poiMarker.lon || poiMarker.center[0]}
                                    onClick={() => setPopupData(poiMarker)}>
                                    <Image width={'40px'}
                                           src="https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/location-pin.png"/>
                                </Marker> : transferMarker ?
                                    <Marker
                                        key={`TransferMarker`}
                                        offsetTop={-40}
                                        offsetLeft={-20}
                                        latitude={transferMarker.lat || transferMarker.center[1]}
                                        longitude={transferMarker.lon || transferMarker.center[0]}
                                        onClick={() => setPopupData(transferMarker)}>
                                        <TransferPin/>
                                    </Marker> : <></>
                        }
                    </>
                    <>
                        {
                            startMarker && (startMarker.lat || startMarker.center) && (startMarker.lon || startMarker.center) &&
                            <Marker
                                key={`StartMarker`}
                                offsetTop={-40}
                                offsetLeft={-20}
                                latitude={startMarker.lat || startMarker.center[1]}
                                longitude={startMarker.lon || startMarker.center[0]}
                                onClick={() => setPopupData(startMarker)}>
                                <Image width={'40px'}
                                       src="https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/start-pin.png"/>
                            </Marker>
                        }
                    </>
                    <>
                        {
                            endMarker && (endMarker.lat || endMarker.center) && (endMarker.lon || endMarker.center) &&
                            <Marker
                                key={`EndMarker`}
                                offsetTop={-40}
                                offsetLeft={-20}
                                latitude={endMarker.lat || endMarker.center[1]}
                                longitude={endMarker.lon || endMarker.center[0]}
                                onClick={() => setPopupData(endMarker)}>
                                <Image width={'40px'}
                                       src="https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/location-pin.png"/>
                            </Marker>
                        }
                    </>
                    <>
                        {popupData && (popupData.routes || popupData.center) && <Popup
                            className="StopPopup"
                            offsetTop={popupData.center ? -10 : 0}
                            longitude={popupData.lon || popupData.longitude || popupData.center[0]}
                            latitude={popupData.lat || popupData.latitude || popupData.center[1]}
                            onClose={() => {
                                setPopupData(null);
                                setSelectedStop(null);
                            }}
                            closeButton={false}
                            closeOnClick={false}
                            dynamicPosition={true}
                            captureScroll={true}
                            capturePointerMove={true}
                            captureDrag={true}
                            captureClick={true}
                        >
                            {
                                props.tripPlanner && popupData.stopName ?
                                    <>
                                        <div className="row">
                                            <div className="col-lg-12 StopPopupHeader">
                                                {popupData.stopName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 text-center">
                                                <ButtonGroup>
                                                    <Button size={'sm'} variant={'outline-success'}
                                                            style={{width: '100px'}}
                                                            onClick={() => props.handleFromClicked(popupData)}>From</Button>
                                                    <Button size={'sm'} variant={'outline-danger'}
                                                            style={{width: '100px'}}
                                                            onClick={() => props.handleToClicked(popupData)}>To</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    popupData.shiftBatBuilder && popupData.stopName ?
                                        <div className="row">
                                            <div className="col-lg-12 StopPopupHeader">
                                                {popupData.stopName}
                                            </div>
                                        </div>
                                        :
                                        popupData.stopName ?
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-12 StopPopupHeader">
                                                        {popupData.stopName}
                                                    </div>

                                                </div>
                                                {!props.tripPlanner && !props.shiftBatBuilder &&
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <span>Routes served:</span>
                                                        </div>
                                                        <div className="col-lg-6"
                                                             style={{
                                                                 textAlign: 'right',
                                                                 marginTop: '-8px',
                                                                 paddingRight: '20px'
                                                             }}>
                                                            {!props.tripPlanner && !props.shiftBatBuilder && popupData.routes && popupData.routes.length &&
                                                                <>
                                                                    <FilterOutlined style={{paddingRight: '10px'}}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        props.handleSelectedRoutes(popupData.routes);
                                                                                        setShow(`Route filter set.`);
                                                                                    }}/>

                                                                    <CopyToClipboard
                                                                        text={`${fullUrl}/timetables/${key}?${filterToQuery(null, popupData)}`}
                                                                        onCopy={() => {
                                                                            console.log('Copied.');
                                                                            setShow(`Route filter copied to clipboard.`);
                                                                        }}>
                                                                        <CloudUploadOutlined/>
                                                                    </CopyToClipboard>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>}

                                            </> : popupData.routes ?
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <span className="text-title-sm">Routes</span>
                                                    </div>
                                                    <div className="col-lg-4 d-flex"
                                                         style={{textAlign: 'right', gap: '6px'}}>
                                                        {!props.tripPlanner && !props.shiftBatBuilder && popupData.routes && popupData.routes.length &&
                                                            <>
                                                                <Button className="icon-button secondary-btn"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            props.handleSelectedRoutes(popupData.routes);
                                                                            setShow(`Route filter set.`);
                                                                        }}><FilterOutlined
                                                                    style={{fontSize: '15px'}}/></Button>
                                                                <CopyToClipboard
                                                                    text={`${fullUrl}/timetables/${key}?${filterToQuery(popupData.routes)}`}
                                                                    onCopy={() => {
                                                                        console.log('Copied.');
                                                                        setShow(`Route filter copied to clipboard.`);
                                                                    }}><Button
                                                                    className="icon-button secondary-btn"><CopyOutlined
                                                                    style={{fontSize: '14px'}}/></Button>
                                                                </CopyToClipboard>
                                                                {/* <FilterOutlined style={{paddingRight: '10px'}}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    props.handleSelectedRoutes(popupData.routes);
                                                                                    setShow(`Route filter set.`)
                                                                                }}/>
                                                                <CopyToClipboard
                                                                    text={`${fullUrl}/timetables/${key}?${filterToQuery(popupData.routes)}`}
                                                                    onCopy={() => {
                                                                        console.log('Copied.')
                                                                        setShow(`Route filter copied to clipboard.`)
                                                                    }}>
                                                                    <CloudUploadOutlined/>
                                                                </CopyToClipboard> */}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                : popupData.type === 'Feature' ?
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <span className="h6">{popupData.place_name}</span>
                                                        </div>
                                                    </div>
                                                    : popupData.center ?
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <span className="h6">Location</span>
                                                            </div>
                                                        </div>
                                                        : <></>
                            }
                            {
                                !props.tripPlanner && popupData.routes ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div
                                                style={{
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto',
                                                    minWidth: '250px',
                                                    maxHeight: '400px'
                                                }}>
                                                {
                                                    popupData.routes.sort((a, b) => a.routeNumber.localeCompare(b.routeNumber)).filter(r => r.published).map(route => (
                                                        <div className="row" key={`RouteRow-${route.routeId}`}>
                                                            {props.handleSelectedRoute ?
                                                                <div className="col-lg-9 RouteListContainerItem"
                                                                     onClick={(e) => {
                                                                         e.preventDefault();
                                                                         e.stopPropagation();
                                                                         props.handleSelectedRoute(route);
                                                                     }}>
                                                                    <RouteTitle route={route} size={'sm'}
                                                                                key={`Popup-${route.routeId}`}/>
                                                                </div>
                                                                : props.handleSelectedTrip ?
                                                                    <Col className="col-lg-10">
                                                                        <RouteTitle route={route} size={'sm'}
                                                                                    key={`Popup-${route.routeId}`}/>

                                                                        <Row>
                                                                            <Col style={{overflow: 'hidden'}}>
                                                                                {route?.services && route.services.map(trip => (
                                                                                    <Row>
                                                                                        <Col
                                                                                            sm={10}>{trip.getStartTime()}</Col><Col><Button
                                                                                        onClick={() => props.handleSelectedTrip(route, trip)}>New
                                                                                        row</Button></Col>
                                                                                    </Row>
                                                                                ))}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col> : <></>}
                                                            <Col
                                                                className="col-lg-3 d-flex align-items-center justify-content-end">
                                                                <ChatButton showComments={showComments}
                                                                            chatContext={new ChatContext({
                                                                                type: 'route',
                                                                                id: route.routeId,
                                                                                route,
                                                                                subType: 'wp',
                                                                                lat: roundToPrecision(popupData.lat, LATLON_PRECISION),
                                                                                lon: roundToPrecision(popupData.lon, LATLON_PRECISION),
                                                                                subTypeId: `${roundToPrecision(popupData.lat, LATLON_PRECISION)}_${roundToPrecision(popupData.lon, LATLON_PRECISION)}`
                                                                            })} setChatContext={setChatContext}
                                                                            onClick={() => setChatOpen(true)}/>
                                                            </Col>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    : !props.tripPlanner && (popupData.transfersTo || popupData.transfersFrom) ?
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h3>Transfers</h3>
                                                <div>Latitude: {popupData.center[1]}</div>
                                                <div>Longitude: {popupData.center[0]}</div>
                                            </div>
                                        </div>
                                        : !props.tripPlanner ?
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div>Latitude: {popupData.center[1]}</div>
                                                    <div>Longitude: {popupData.center[0]}</div>
                                                </div>
                                            </div>
                                            : <></>
                            }
                        </Popup>
                        }
                    </>
                    <div className="nav" style={navStyle}>
                        <NavigationControl/>
                    </div>
                    <MapToolbar style={{position: 'absolute', top: 112, left: 10}}>
                        <Button className={'mapbox-ctrl-icon'} variant={showCurrentLocation ? 'primary' : 'light'}
                                style={{backgroundColor: showCurrentLocation ? '#3875F6' : ''}}
                                onClick={() => {
                                    setShowCurrentLocation(!showCurrentLocation);
                                }}>
                            <AimOutlined/>
                        </Button>
                        {isAuthenticated &&
                            <Popover placement="right"
                                     title={<span>Transfers</span>}
                                     content={<div>
                                         <p>Transfers
                                             from {selectedStop ? selectedStop.stopName : 'selected stop'}</p>
                                     </div>}
                                     trigger="hover">
                                <TransfersModal width={'70%'} iconOnly={true} popPlacement={'right'}
                                                point={new BaseStop(selectedStop)}/>
                            </Popover>}
                    </MapToolbar>

                    {!props.tripPlanner && <>
                        <div style={{position: 'absolute', top: isAuthenticated ? 140 : 105, left: 50}}>
                            <div ref={geocoderContainerRef}/>
                        </div>
                        <Geocoder
                            mapRef={mapRef}
                            containerRef={geocoderContainerRef}
                            onViewportChange={handleGeocoderViewportChange}
                            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                            zoom={17}
                            limit={100}
                            clearAndBlurOnEsc={true}
                            clearOnBlur={true}
                            countries={'AU'}
                            placeholder={'Search stop, POI'}
                            localGeocoder={theLocalGeoCoder}
                            render={renderSuggestions}
                            onResult={onResult}
                            onInit={geocoder => {
                                if (!geocoder._inputEl.geocoderListener) {
                                    geocoder._inputEl.addEventListener('keyup', e => {
                                        e.preventDefault();
                                        e.stopImmediatePropagation();
                                        e.stopPropagation();
                                    });
                                    geocoder._inputEl.addEventListener('click', e => {
                                        e.preventDefault();
                                        e.stopImmediatePropagation();
                                        e.stopPropagation();
                                    });
                                    geocoder._inputEl.addEventListener('mousemove', e => {
                                        e.preventDefault();
                                        e.stopImmediatePropagation();
                                        e.stopPropagation();
                                    });
                                    geocoder._inputEl.geocoderListener = true;
                                }
                            }}
                        /></>
                    }

                    {!props.noToolbar && <>
                        <MapToolbar style={{position: 'absolute', top: isAuthenticated ? 190 : 155, left: 10}}>
                            <MapLayerToolbar setLayer={setStyle}/>

                            {/*<MapGeocoderToolbar*/}
                            {/*    mapRef={mapRef}*/}
                            {/*    containerRef={geocoderContainerRef}*/}
                            {/*    onViewportChange={handleGeocoderViewportChange}*/}
                            {/*    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}*/}
                            {/*    zoom={17}*/}
                            {/*    // proximity={viewState}*/}
                            {/*    limit={100}*/}
                            {/*    clearAndBlurOnEsc={true}*/}
                            {/*    clearOnBlur={false}*/}
                            {/*    countries={'AU'}*/}
                            {/*    // marker={true}*/}
                            {/*    placeholder={'Search stop, route, POI'}*/}
                            {/*    localGeocoder={theLocalGeoCoder}*/}
                            {/*    render={renderSuggestions}*/}
                            {/*    // localGeocoderOnly={true}*/}
                            {/*    onResult={onResult}*/}
                            {/*    geocoder={geocoder}*/}
                            {/*    setGeocoder={setGeocoder}*/}
                            {/*/>*/}
                            {<MapFilterToolbar setShow={setShow} {...props}/>}

                            {allowComments &&
                                <Button className={`mapbox-ctrl-icon ${showComments ? 'btn-light-active' : ''}`}
                                        variant={'light'}
                                        onClick={() => setShowComments(c => !c)}>
                                    <Chat style={{width: '16px'}}/>
                                </Button>}
                        </MapToolbar>
                    </>
                    }

                    {props.showWpSelector &&
                        <Tooltip title="Waypoint selector">
                            <MapWpSelectorToolbar wpIdxsInView={wpIdxsInView}
                                                  single={true}
                                                  routes={props.filteredRoutes.filter(r => !props.highlightedRouteIds?.length || props.highlightedRouteIds.includes(r.routeId))}
                                                  trips={props.trips}
                                                  setSelectedStartWpIdx={val => {
                                                      setSelectedStartWpIdx(val);
                                                  }}
                                                  setSelectedEndWpIdx={val => {
                                                      setSelectedEndWpIdx(val);
                                                  }}
                                                  selectedStartWpIdx={selectedStartWpIdx}
                                                  selectedEndWpIdx={selectedEndWpIdx}/>
                        </Tooltip>
                    }
                </DeckGL>
            </>)}
        </>);
}

export default React.memo(RouteMapViewer);
