import {routesModelExpiryService} from "../services/ModelService";
import {useEffect} from "react";

export default function Refresh() {

    useEffect(() => {
        const refresh = async () => {
            console.log('Resetting cache...')
            try {
                await routesModelExpiryService.clearDb();
                window.location.replace('/');
            } catch (e) {
                console.log(e);
            }
        }

        refresh().then(() => console.log('Refreshed cache.'))
    }, [])

    return (<>Refreshing cache....</>)

}
