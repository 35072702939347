import React, {useCallback, useEffect, useState} from 'react';
import './Charters.css';
import {charterModelData, deadrunModelData} from '../../services/ModelService';
import {Charter} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import LoadMessage from '../../components/LoadMessage';
import {Card, Col, Flex, Input, Row} from 'antd';
import CharterMenu from './CharterMenu';
import ShiftBatView from '../ShiftBat/ShiftBatView';
import {ShiftBat} from '../../model/shiftBat';
import useAllStops from '../../hooks/useAllStops';
import {values} from 'lodash';

const {TextArea} = Input;

function CharterItinerary({initialInstance}) {
    const {allStops, allDepots} = useAllStops();

    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: initialInstance || new Charter(),
    });

    const [duty, setDuty] = useState(null);

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({...charter});
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    useEffect(() => {
        if (!allStops || !allDepots) {
            return;
        }
        if (charter?.itinerary?.length && !charter.duty) {
            ShiftBat.fromCharterItinerary({
                charter,
                depot: values(allDepots)?.[0],
                allStops,
                deadrunModelData
            }).then(duty => {
                setDuty(duty)
                setMode({edit: true})
            })
            return
        }
        setDuty(charter.duty);
    }, [charter, allStops, allDepots, setDuty]);

    return (
        <div className="charter-details w-secondary-menu">
            {duty ? (
                <>
                    <SaveMenu
                        save={() => {
                            charter.duty = duty;
                            charterModelData.save(charter).then(() => {
                                console.log('Charter saved.');
                                setMode({edit: false});
                            });
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => charter.isValid()}
                        closeView={true}
                    />
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={'3'}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: mode.edit,
                                    duty: false,
                                    quote: mode.edit || !charter.duty,
                                }}
                            />
                        )}
                        <Col xs={24} lg={24}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    {/* <div className="d-flex align-items-center justify-content-between filter-options-main mt-1">
                                        <h2 className="title">Charter Shift</h2>
                                    </div> */}
                                    {/* <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Shift</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    > */}
                                        <ShiftBatView charter={true} initialSB={duty} initialEditMode={mode?.edit}
                                                      onShiftBatUpdate={setDuty}/>
                                    {/* </Card> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..."/>
            )}
        </div>
    );
}

export default React.memo(CharterItinerary);
