import {Button} from 'react-bootstrap';
import {
    DeleteOutlined,
    DeleteRowOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    NodeIndexOutlined,
    PlusCircleOutlined,
    RadiusSettingOutlined,
    RollbackOutlined,
    SyncOutlined
} from '@ant-design/icons';
import React, {useState} from 'react';
import MapToolbar from './MapToolbar';
import {Popover} from 'antd';
import EditStopModal from './EditStopModal';
import {Stop} from '../model/busRoute';
import {getNearestSegment} from '../libs/routes-lib';
import {noop} from 'lodash';

function MapEditorToolbar({
                              handleOptimise, wpOptimisationCount, createNewStop, showCreateStop, setShowCreateStop,
                              selectedMarker, selectedPt, selectedStartWpIdx, selectedEndWpIdx,
                              style, editing, setEditingRoute, handleDelete, handleSnapToRoadFn, singleWpSelected,
                              handleExtendRoute, handleAddStop, handleTrim, canTrim, isExtending, wpsSelected,
                              showAllStops, setShowAllStops = noop, setRouteBuilding, routeBuilding, allStops, canBuild,
                              type, route, charter
                          }) {

    const [buildToStop, setBuildToStop] = useState(false);

    return (
        <MapToolbar style={{...style}}>
            {selectedPt &&
                <EditStopModal
                    stop={new Stop({
                        ...selectedPt,
                        stopId: '_',
                        stopType: charter ? 'charter' : 'bus',
                        stopName: '',
                        duplicate: -1
                    })}
                    allowedStopTypes={charter ? ['bus', 'charter'] : ['bus']}
                    visible={showCreateStop}
                    setVisible={setShowCreateStop}
                    cancelEditStop={async () => {
                        setShowCreateStop(false);
                    }}
                    handleSaveEdit={async (stop) => {
                        await createNewStop(stop);
                        handleAddStop(stop, buildToStop);
                        setShowCreateStop(false);
                        setShowAllStops(true);
                    }}
                    allStops={allStops}
                    type={charter ? 'charter' : type ? type : 'bus'}
                    buildToStop={buildToStop}
                    setBuildToStop={setBuildToStop}
                    charter={charter}
                />
            }

            {/*Edit route */}
            <Popover placement="right"
                     title={<span>Edit route (E)</span>}
                     content={<div>
                         <p>Edit the route path on the map</p>
                     </div>}
                     trigger="hover">
                <Button className="mapbox-ctrl-icon" variant={'light'}
                        onClick={() => setEditingRoute(!editing)}>
                    <EditOutlined/>
                </Button>
            </Popover>


            {/* <Button className="mapbox-ctrl-icon" variant={"light"}
                    onClick={() => setEditingRoute(!editing)}>
                <EditOutlined/>
            </Button> */}

            {editing &&
                <>
                    {/*<Popover placement="right" trigger="hover" title={<span>Point Data Layers</span>}*/}
                    {/*         content={<div className="data-layers-wrap">*/}
                    {/*             <Tooltip placement="bottom"*/}
                    {/*                      title={<div>*/}
                    {/*                          <div>Source System: TfNSW Transit Stop Management System</div>*/}
                    {/*                          <div>Last Updated: {busLastUpdated?.format('lll') || "Unknown"}</div>*/}
                    {/*                      </div>}>*/}
                    {/*                 <Button variant="light" onClick={() => setShowAllStops(s => toggleStrInArray(s, 'bus'))}*/}
                    {/*                         className={showmasterStops.includes('bus') ? "active" : ""}>*/}
                    {/*                     <TfNSW/>TfNSW Stops*/}
                    {/*                 </Button>*/}
                    {/*             </Tooltip>*/}
                    {/*        </div>}>*/}
                    {/*    <Button className="mapbox-ctrl-icon" variant="light" disabled={!editing}><CopyOutlined /></Button>*/}
                    {/*</Popover>*/}

                    {/*Show all points*/}
                    <Popover placement="right"
                             title={<span>{`${showAllStops ? 'Hide unused' : 'Show all'} points`}</span>}
                             content={<div>
                                 <p>{`${showAllStops ? 'Hide unused' : 'Show all'} points not on route`}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing}
                                onClick={() => setShowAllStops(x => !x)}>
                            {!showAllStops ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        </Button>
                    </Popover>

                    {/*Build to start of route*/}
                    <Popover placement="right"
                             title={<span>Build route (B)</span>}
                             content={<div>
                                 <p>{selectedPt ? 'Build route from selected point to start of route.' : singleWpSelected() ? 'Build route from selected point.' : wpsSelected(true) ? 'Replace selected waypoints with diverted route' : 'Select a waypoint or a point on the road to build the route.'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={routeBuilding ? 'outline-primary' : 'light'}
                                disabled={!editing || !canBuild()}
                                onClick={() => setRouteBuilding(!routeBuilding)}>
                            {isExtending && <SyncOutlined spin={true}/>}<NodeIndexOutlined/>
                        </Button>
                    </Popover>

                    {/*/!*Build to start of routep*!/*/}
                    {/*<Popover placement="right"*/}
                    {/*         title={<span>Build start of route</span>}*/}
                    {/*         content={<div>*/}
                    {/*             <p>{extendTo() ? `Add ${selectedMarker ? "'" + selectedMarker.stopName + "' to start of route" : 'point to start of route'}` : 'Select a stop or point to add to route start'}</p>*/}
                    {/*         </div>}*/}
                    {/*         trigger="hover">*/}
                    {/*    <Button className="mapbox-ctrl-icon" variant={"light"}*/}
                    {/*            disabled={!editing || !extendTo()}*/}
                    {/*            onClick={() => handleExtendRoute('prepend')}>*/}
                    {/*        <SubnodeOutlined rotate={180}/>*/}
                    {/*    </Button>*/}
                    {/*</Popover>*/}

                    {/*/!*Build to end of route*!/*/}
                    {/*<Popover placement="right"*/}
                    {/*         title={<span>Build end of route (B)</span>}*/}
                    {/*         content={<div>*/}
                    {/*             <p>{extendTo() ? `Add ${selectedMarker ? "'" + selectedMarker.stopName + "' to end of route" : 'point to end of route'}` : 'Select a stop or point to add to route end'}</p>*/}
                    {/*         </div>}*/}
                    {/*         trigger="hover">*/}
                    {/*    <Button className="mapbox-ctrl-icon" variant={"light"}*/}
                    {/*            disabled={!editing || !extendTo()}*/}
                    {/*            onClick={() => handleExtendRoute()}>*/}
                    {/*        <SubnodeOutlined/>*/}
                    {/*    </Button>*/}
                    {/*</Popover>*/}
                    {/*Add stop*/}
                    <Popover placement="right"
                             title={<span>{selectedPt ? 'Create stop on route (C)' : 'Add stop to route'}</span>}
                             content={<div>
                                 <p>{selectedMarker ? `Add stop point '${selectedMarker.stopName}' to route` :
                                     selectedPt ? 'Create a new stop and add it to the route' :
                                         'Select a stop to add it to the route service'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing || (!selectedMarker && !selectedPt)}
                                onClick={async () => {
                                    if (selectedPt) {
                                        const nearestSegment = getNearestSegment(selectedPt, route.waypoints, {maxDistFrom: 50});
                                        if (!nearestSegment) {
                                            await handleExtendRoute();
                                        }
                                        setShowCreateStop(true);
                                    } else if (selectedMarker) {
                                        handleAddStop(selectedMarker);
                                    }
                                }}>
                            <PlusCircleOutlined/>
                        </Button>
                    </Popover>

                    {/*/!* Show transfers *!/*/}
                    {/*<Popover placement="right"*/}
                    {/*         title={<span>Transfers</span>}*/}
                    {/*         content={<div>*/}
                    {/*             <p>{selectedMarker ? `Transfers from '${selectedMarker.stopName}'` :*/}
                    {/*                     'Select a stop to show transfers'}</p>*/}
                    {/*         </div>}*/}
                    {/*         trigger="hover">*/}
                    {/*    <TransfersModal width={"70%"} iconOnly={true} point={new Stop(selectedMarker)} routeId={}/>*/}
                    {/*</Popover>*/}
                    {/*Optimise route waypoints */}
                    <Popover placement="right"
                             title={<span>Optimise route waypoints</span>}
                             content={<div>
                                 <p>{wpOptimisationCount > 5 ? `Reduce the number of redundant waypoints on the route by ${wpOptimisationCount}` : 'Route waypoints are optimised.'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing || wpOptimisationCount <= 5}
                                onClick={handleOptimise}>
                            <RadiusSettingOutlined/>
                        </Button>
                    </Popover>

                    {/*Snap to road*/}
                    <Popover placement="right"
                             title={<span>Snap to road (S)</span>}
                             content={<div>
                                 <p>Snap route path waypoints to road network</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing}
                                onClick={handleSnapToRoadFn}>
                            <RollbackOutlined/>
                        </Button>
                    </Popover>


                    {/*Divert to X*/}
                    {/*<Tooltip*/}
                    {/*    title={extendTo() && selectedEndWpIdx > selectedEndWpIdx ? `Divert route to ${selectedMarker ? selectedMarker.stopName : 'point'} between selected waypoints` : extendTo() ? `Extend route to ${selectedMarker ? selectedMarker.stopName : 'point'}` : 'Select a point on the road, or a stop to extend the route'}*/}
                    {/*    placement="right">*/}
                    {/*    <Button className="mapbox-ctrl-icon" variant={"light"} */}
                    {/*            disabled={!editing || !extendTo()}*/}
                    {/*            onClick={handleExtendRoute}>*/}
                    {/*        <BranchesOutlined/>*/}
                    {/*    </Button>*/}
                    {/*</Tooltip>*/}


                    {/*Start from X*/}
                    {/*<Tooltip title={startFromTooltip} placement="right">*/}
                    {/*    <Button className="mapbox-ctrl-icon" variant={"light"} style={{paddingBottom: "32px"}}*/}
                    {/*            onClick={() => window.alert('Clicked')}>*/}
                    {/*        <BranchesOutlined/>*/}
                    {/*    </Button>*/}
                    {/*</Tooltip>*/}


                    {/*Trim route start */}
                    <Popover placement="right"
                             title={<span>Trim start of route</span>}
                             content={<div>
                                 <p>{selectedMarker ? `Trim from start of route to stop '${selectedMarker.stopName}'` : 'Select a stop to trim start of the route to'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing || !canTrim()}
                                onClick={() => handleTrim(false, selectedMarker)}>
                            <DeleteRowOutlined rotate={180}/>
                        </Button>
                    </Popover>

                    {/*Trim route end */}
                    <Popover placement="right"
                             title={<span>Trim end of route</span>}
                             content={<div>
                                 <p>{selectedMarker ? `Trim to end of route from stop '${selectedMarker.stopName}'` : 'Select a stop to trim to the end of the route'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing || !canTrim()}
                                onClick={() => handleTrim(true, selectedMarker)}>
                            <DeleteRowOutlined/>
                        </Button>
                    </Popover>

                    {/*Delete selected X*/}
                    <Popover placement="right"
                             title={<span>Delete selected</span>}
                             content={<div>
                                 <p>{selectedEndWpIdx > selectedStartWpIdx ? `Delete ${selectedEndWpIdx - selectedStartWpIdx} selected waypoints` : selectedMarker ? `Delete stop '${selectedMarker.stopName}' from the route service` : 'Select a stop or some waypoints to delete'}</p>
                             </div>}
                             trigger="hover">
                        <Button className="mapbox-ctrl-icon" variant={'light'}
                                disabled={!editing || ((selectedEndWpIdx === -1 || selectedStartWpIdx === -1) && !selectedMarker)}
                                onClick={() => handleDelete()}>
                            <DeleteOutlined/>
                        </Button>
                    </Popover>
                </>
            }
        </MapToolbar>);
}


export default React.memo(MapEditorToolbar);
