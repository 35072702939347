import React, {useCallback, useEffect, useRef, useState} from 'react';
import './ShiftBat.css';
import {Button, message, Select, Tag} from 'antd';
import {Col, Image, Row} from 'react-bootstrap';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as Calendar} from '../../assets/icons/Calendar.svg';
import {ReactComponent as Timer} from '../../assets/icons/Timer.svg';
import {ReactComponent as Services} from '../../assets/icons/Services.svg';
import {ReactComponent as Check} from '../../assets/icons/Check.svg';
import {ReactComponent as Undo} from '../../assets/icons/Undo.svg';
import {ReactComponent as Reset} from '../../assets/icons/Reset.svg';
import {ReactComponent as Redo} from '../../assets/icons/Redo.svg';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import RouteTitleEdit from '../../components/RouteTitleEdit';
import {ShiftBat, ShiftBatRowType} from '../../model/shiftBat';
import {Prompt, useHistory, useParams} from 'react-router-dom';
import {useAppContext} from '../../libs/contextLib';
import {toHrsMinsSecs, toKmMs} from '../../libs/formatLib';
import {
    deadrunModelData,
    noteRefModelData,
    routeModelData,
    shiftBatModelData,
    stopModelData,
    transferModelData
} from '../../services/ModelService';
import RouteMap from '../../components/RouteMap';
import ShiftBatRows from './ShiftBatRows';
import ShiftBatPrintModal from './ShiftBatPrintModal';
import {Deadrun} from '../../model/deadrun';
import {keyBy} from 'lodash/collection';
import {schedule_options} from '../../model/schedule';
import {values} from 'lodash/object';
import {debounce} from 'lodash/function';
import config from '../../config';
import util from 'util';
import {s3Upload} from '../../libs/awsLib';
import {FEATURE} from '../../model/features';
import {checkFeatureAuth} from '../../App';
import {Popconfirm} from 'antd/lib';
import {useTimeTravelState} from '../../libs/useTimeTravelState';
import LoaderButton from '../../components/LoaderButton';
import Form from 'react-bootstrap/Form';
import {ROUTE_STATUS, ROUTE_STATUSES} from '../../model/busRoute';
import RouteMapViewer from '../../components/RouteMapViewer';
import LoadMessage from '../../components/LoadMessage';
import {reverse} from 'lodash';

function ShiftBatView({
                          allowRoutes = true,
                          initialSB = null,
                          charter = false,
                          initialEditMode = true,
                          onShiftBatUpdate
                      }) {
    const {id} = useParams();
    const {
        isAuthenticated, apiKey, schedules, setBreadcrumb,
        operator,
        setFaultState,
        email,
        givenName,
        familyName,
    } = useAppContext();
    const history = useHistory();
    const [fetching, setFetching] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState({});
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [zoom, setZoom] = useState([]);
    const [viewing, setViewing] = useState({});
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);

    const [allStops, setAllStops] = useState(null);
    const [allRoutes, setAllRoutes] = useState({});
    const [allTransfers, setAllTransfers] = useState({});

    // const [route, setRoute] = useState(null);

    const [editRouteRow, setEditRouteRow] = useState(null);

    const [printShiftBat, setPrintShiftBat] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    // eslint-disable-next-line
    const initialShiftbatRef = useRef(null);
    const [isCancelling, setIsCancelling] = useState(false);
    const [updated, setUpdated] = useState(false);


    const [shiftBatNumber, setShiftBatNumber] = useState(null);
    const [shiftBatName, setShiftBatName] = useState(null);
    const [shiftBatDetails, setShiftBatDetails] = useState(null);
    const [shiftBatLogo, setShiftBatLogo] = useState(null);
    // const [shiftBatColour, setShiftBatColour] = useState()

    const [onMapSelection, setOnMapSelection] = useState(null);
    const [fileList, setFileList] = useState([]);

    const getEditor = useCallback(() => givenName?.length && familyName?.length ? `${givenName} ${familyName}` : email,
        [email, givenName, familyName]);

    const [shiftBat, setShiftBatHistory, controls] = useTimeTravelState(new ShiftBat({}), {
        maxSize: 10, onUpdate: sb => {
            if (onShiftBatUpdate) {
                onShiftBatUpdate(sb);
            }
        }
    });

    // // eslint-disable-next-line
    // const setShiftBat = useCallback((sb, opts = null) => {
    //     setShiftBatHistory(sb, opts)
    //     if (!opts?.overwriteLastEntry) {
    //         setUpdated(true);
    //     }
    // }, [setShiftBatHistory, setUpdated])

    useEffect(() => {
        console.log('Setting onMapSelection...', onMapSelection);
    }, [onMapSelection]);

    useEffect(() => {
        setEditMode(editMode => initialEditMode);
    }, [setEditMode, initialEditMode]);

    const setShiftBatNow = useCallback((sb, opts = null) => {
        setShiftBatHistory(sb, opts);
        if (!opts?.overwriteLastEntry) {
            setUpdated(true);
        }
    }, [setShiftBatHistory, setUpdated, shiftBatNumber, shiftBatName, shiftBatDetails]);

    const setShiftBat = debounce(setShiftBatNow, 500);

    const disableEditForRow = useCallback(() => values(editing).some(id => !!id), [editing]);

    useEffect(() => {
        checkFeatureAuth(setFaultState, operator, FEATURE.shfts, () => history.push('/')).then(() => console.log('Auth checked.'));
    }, [setFaultState, operator, history]);

    useEffect(() => {
        const beforeunload = (e) => {
            if (updated) {
                e.returnValue = 'unloading';
                return 'unloading';
            }
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, [updated]);

    // const updateRoute = useCallback((updatedRoute) => {
    //     const routeRow = shiftBat.rows[shiftBat.rows.findIndex(row => row.route && row.route.routeId === updatedRoute.routeId)]
    //     if (routeRow) {
    //         routeRow.hasDirections = false
    //         setUpdated(shiftBat);
    //     }
    // }, [shiftBat, setUpdated])

    const notifyUpdate = useCallback((routes) => {
        if (!Array.isArray(routes)) {
            routes = [routes];
        }
        setAllRoutes(_routes => {
            routes.forEach(route => {
                if (route.published === -1) {
                    delete _routes[route.routeId];
                } else {
                    _routes[route.routeId] = route;
                    const routeRow = shiftBat.rows[shiftBat.rows.findIndex(row => row.route && row.route.routeId === route.routeId)];
                    if (routeRow) {
                        // routeRow.hasDirections = false
                        // routeRow.loaded = false;
                        routeRow.route = route;
                    }
                }
            });
            // updateRoute(route)
            return {..._routes};
        });
    }, [shiftBat]);

    const notifyDelete = useCallback((id) => {
        setAllRoutes(_routes => {
            delete _routes[id];
            return {..._routes};
        });
    }, []);

    useEffect(() => {
        if (allStops) {
            return;
        }

        const load = async () => {

            console.log('Loading shift bat...');

            try {

                if (!isAuthenticated) {
                    console.log('NOT AUTHENTICATED');
                    history.push('/');
                }

                const allStops = await stopModelData.getAll();
                setAllStops(allStops);
                // Preload notes
                await noteRefModelData.getAll();

                const allTransfers = await transferModelData.getAll();
                console.log('All transfers finished loading.');
                setAllTransfers(allTransfers);

                if (allowRoutes) {

                    const routes = await routeModelData.getAll(apiKey);
                    setAllRoutes(keyBy(Object.values(routes).filter(r => !r.charter), 'routeId'));
                }
                // load shift bat
                if (initialSB || id === '_new_') {
                    setBreadcrumb('NEW');
                    setFileList([{
                        url: 'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg',
                        status: 'done'
                    }]);
                    if (initialSB) {
                        setShiftBatNumber(initialSB?.shiftBatNumber);
                        setShiftBatName(initialSB?.shiftBatName);
                        setShiftBatDetails(initialSB?.shiftBatDetails);
                        setShiftBatLogo(initialSB?.shiftBatLogo);
                        const sb = new ShiftBat(initialSB || {});
                        Promise.all(sb.rows.filter(row => row.updateRow).map(async row => {
                            await row.updateRow({apiKey, allStops, allTransfers, allRoutes, deadrunModelData});
                        })).then(() => {
                            setFetching(false);
                            controls.reset(sb.clone());
                            setBreadcrumb(sb.shiftBatNumber);
                        });
                    } else {
                        setFetching(false);
                    }
                } else if (id) {
                    shiftBatModelData.get(apiKey, id).then(sb => {
                        setShiftBatNumber(sb.shiftBatNumber);
                        setShiftBatName(sb.shiftBatName);
                        setShiftBatDetails(sb.shiftBatDetails);
                        // setShiftBatColour(sb.shiftBatColour)
                        Promise.all(sb.rows.filter(row => row.updateRow).map(async row => {
                            await row.updateRow({apiKey, allStops, allTransfers, allRoutes, deadrunModelData});
                        })).then(() => {
                            if (sb?.shiftBatLogo?.length) {
                                console.log('SB logo: ', sb.shiftBatLogo);
                                setFileList([{
                                    url: `https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${sb.shiftBatLogo}`,
                                    status: 'done'
                                }]);
                            } else {
                                setFileList([{
                                    url: 'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg',
                                    status: 'done'
                                }]);
                            }
                            controls.reset(sb.clone());
                            setFetching(false);
                            setBreadcrumb(sb.shiftBatNumber);
                        });
                    });
                }

                console.log('All routes finished loading.');

            } catch (e) {
                console.log(e);
            }
        };

        if (!initialShiftbatRef.current) {

            const stopListener = stopModelData.addListener({
                loaded: stops => setAllStops(stops),
                setterFn: setAllStops,
                refresh: debounce(() => {
                    stopModelData.getAll().then(stopsById => setAllStops(stopsById));
                }, 500)
            });

            const transferListener = transferModelData
                .setFetchOption('allStops', allStops)
                .setFetchOption('allSchedules', schedules);
            transferModelData.addListener({
                setterFn: setAllTransfers,
            });

            routeModelData.setFetchOption('includeStopTimes', true)
                .setFetchOption('scheduledOnly', true)
                .setFetchOption('allStops', allStops);
            const routeListener = routeModelData.addListener({notifyUpdate, notifyDelete});

            load().then(() => {
                console.log('ShiftBat loaded.');
            });

            return () => {
                stopModelData.removeListener(stopListener);
                transferModelData.removeListener(transferListener);
                routeModelData.removeListener(routeListener);
            };
        }

        // eslint-disable-next-line
    }, [apiKey, id, schedules, isAuthenticated, history, setEditMode, setAllStops, setAllTransfers, initialShiftbatRef, initialSB]);

    // eslint-disable-next-line
    const updateShiftBatAndSave = useCallback(debounce((shiftBat) => {
        setUpdating(true);
        shiftBat.updateRows({apiKey, allStops, allTransfers, allRoutes, deadrunModelData}).then(() => {
            // debounceSave(shiftBat);
            setUpdating(false);
        });
        // eslint-disable-next-line
    }, 1000), [setUpdating, apiKey, allStops, allTransfers, allRoutes]);

    // useEffect(() => {
    //     if (shiftBat?.scheduleId) {
    //         setAllRoutes(allRoutes => pickBy(allRoutes, route => route.includesSchedule(shiftBat.scheduleId)))
    //     }
    // }, [setAllRoutes, shiftBat])

    const save = useCallback(async (shiftBat, editMode = true) => {

        if (!shiftBat || !operator.features.access(FEATURE.shfts)) {
            return;
        }

        try {
            const file = fileList[0];

            if (file?.type && file?.originFileObj) {
                console.log('Saving file: ', util.inspect(file));
                if (file.type.indexOf('/') === -1) {
                    console.log('Could not save file. Tpe does not look right.');
                } else {
                    let fileExt = file.type.toLowerCase().split('/')[1];
                    console.log('File ext', fileExt);
                    const filename = `${operator.operatorId}/shiftbats/${shiftBat.shiftBatId}/logo/${Date.now()}.${fileExt}`;
                    shiftBat.shiftBatLogo = s3Upload(file.originFileObj, filename, {level: 'public'}).then(() => console.log('shift bat logo updated'));
                }
            }

            shiftBat.shiftBatNumber = shiftBatNumber;
            shiftBat.shiftBatName = shiftBatName;
            shiftBat.shiftBatDetails = shiftBatDetails;
            // shiftBat.shiftBatColour = shiftBatColour
            await shiftBatModelData.save(shiftBat);
            // await Promise.all(shiftBat.rows.filter(row => row.type === ShiftBatRowType.dead && (row.routeEdited || row.title !== row.route.routeName || row.description !== row.route.routeDetails)).map(async deadRow => {
            //     if(deadRow.route.routeName !== deadRow.title) {
            //         deadRow.route.routeId = "_";
            //     }
            //     deadRow.route.routeName = deadRow.title
            //     deadRow.route.routeDetails = deadRow.description
            //     await deadrunModelData.save(new Deadrun({...deadRow.route}))
            // }));
            // controls.reset(shiftBat)
            setEditMode(editMode);
            setHighlightedRouteIds([]);

            controls.reset(shiftBat);
            setUpdated(false);
            console.log('Shift bat saved...');
            messageApi.info('Shift bat saved...');
        } catch (e) {
            console.log(e, e);
        }
    }, [setEditMode, controls, setHighlightedRouteIds, messageApi, fileList, operator, setUpdated,
        shiftBatNumber, shiftBatName, shiftBatDetails]);

    // const debounceSave = debounce((shiftBat) => save(shiftBat), 30000)

    const onUpdate = useCallback((event, row, idx, keepEditing) => {

        const update = async () => {
            if (!keepEditing) {

                if (row.type === ShiftBatRowType.dead && row.id === editRouteRow?.id) {
                    setEditRouteRow(null);
                    editRouteRow.title = row.title;
                    editRouteRow.description = row.description;
                    if (row.title?.length && editRouteRow.route.routeName !== row.title) {
                        editRouteRow.route.routeId = '_'; // Creates a new Deadrun in the DB
                    }
                    editRouteRow.route.calculateDistances();
                    // editRouteRow.route.routeDetails = row.description
                    const deadrun = new Deadrun(editRouteRow.route);
                    deadrun.routeName = row.title;
                    deadrun.routeDetails = row.description;
                    deadrun.duration = row.duration;
                    const savedDeadrun = await deadrunModelData.save(deadrun.clone());
                    deadrun.routeId = savedDeadrun.routeId;
                    row.route = deadrun.clone();
                    row.routeId = deadrun.routeId;
                    row.routes = row.routes || {};
                    row.routes[deadrun.routeId] = deadrun;
                    row.distance = deadrun.distance;
                    // } else if (row.type === ShiftBatRowType.note) {
                    //     const savedRow = await noteRefModelData.save(clone(row));
                    //     row.refId = savedRow.refId;
                }
            }

        };

        // setUpdating(true)
        update().then(() => {
            setShiftBat(shiftBat => {
                const _shiftBat = new ShiftBat({...shiftBat});
                setUpdating(true);
                _shiftBat.replaceRow(row, idx);
                return _shiftBat;
            });
            setEditing(editing => {
                return ({...editing, [row.id]: !!keepEditing});
            });
            setHighlightedRouteIds([]);
            console.log('Row updated.');
        });
    }, [setShiftBat, setEditing, editRouteRow, setEditRouteRow, setHighlightedRouteIds]);

    const onCreate = useCallback((e, row, idx, keepEditing) => {
        if (row.type === ShiftBatRowType.note) {
            row.refId = '_';
        }
        onUpdate(e, row, idx, keepEditing);
    }, [onUpdate]);

    // const addRow = useCallback(({idx}) => {
    //     const id = ulid();
    //     console.log('New row...', id)
    //     setShiftBat(sb => {
    //         const shiftBat = sb.clone();
    //         shiftBat.addRow({id, idx})
    //         return shiftBat
    //     })
    //     setEditing(editing => {
    //         return ({...editing, [id]: true})
    //     })
    // }, [setShiftBat, setEditing])

    const deleteRow = useCallback((event, row) => {
        setShiftBatNow(sb => {
            const shiftBat = sb.clone();
            shiftBat.removeRow(row);
            return shiftBat;
        });
        setEditing(editing => ({...editing, [row.id]: false}));
        setHighlightedRouteIds([]);
    }, [setShiftBatNow, setEditing, setHighlightedRouteIds]);

    const cancel = useCallback((event, row) => {
        if (row.id === editRouteRow?.id) {
            setEditRouteRow(null);
        }
        if (row.isDefault()) {
            deleteRow(event, row);
        }

        setEditing(editing => ({...editing, [row.id]: false}));
        setHighlightedRouteIds([]);
    }, [setEditing, editRouteRow, setEditRouteRow, deleteRow, setHighlightedRouteIds]);
    //
    //
    // const cut = useCallback((startWpIdx, endWpIdx) => {
    //     if (startWpIdx >= endWpIdx) {
    //         return
    //     }
    //
    //     if (!window.confirm(`Are you sure you want to cut the route between the selected waypoints?`)) {
    //         return;
    //     }
    //
    //     setRoute(route => {
    //         const _route = route.clone();
    //         console.log('wps before cut:', route.waypoints.length)
    //         _route.cut(startWpIdx, endWpIdx);
    //         console.log('wps after cut:', route.waypoints.length)
    //
    //         console.log("Finish cut")
    //         const newRoute = new BusRoute(_route);
    //         console.log('wps after after cut:', newRoute.waypoints.length)
    //         // setStops([...route.stops]);
    //         return newRoute
    //     })
    // }, [setRoute]);

    useEffect(() => {
        const viewingRoutIds = Object.keys(viewing).filter(rowId => viewing[rowId])
            .map(rowId => shiftBat.rows[shiftBat.rows.findIndex(row => row.id === rowId)]?.route?.routeId)
            .filter(rId => !!rId);
        console.log(viewingRoutIds);
        setHighlightedRouteIds(viewingRoutIds);
    }, [viewing, setZoom, setHighlightedRouteIds, shiftBat]);

    // console.log('Updating view', shiftBat?.rows?.filter(row => row.mappedRoute).map(row=>row.mappedRoute.waypoints[0]))

    const handleNewDeadrun = useCallback((row) => {
        setEditRouteRow(editRouteRow => {
            const newDeadrun = editRouteRow.route.clone();
            newDeadrun.routeName = 'New deadrun';
            newDeadrun.routeId = '_';
            editRouteRow.routes['_'] = newDeadrun;
            return {...editRouteRow, route: newDeadrun};
        });
    }, [setEditRouteRow]);

    // function handleDragEnd(event) {
    //     if (event.over && event.over.id === 'droppable') {
    //         setIsDropped(true);
    //     }
    // }

    // useEffect(() => {
    //     console.log('Updated: ', readyToSave)
    //     console.log('Maybe saving shiftbat...')
    //     if (readyToSave && editMode) {
    //         setReadyToSave(false);
    //         console.log('Yep, saving shiftbat...')
    //         save(editMode).then(() => {
    //             console.log('shiftbat saved')
    //             messageApi.info('Shift bat saved.');
    //         })
    //     }
    // }, [readyToSave, setReadyToSave, save, editMode])

    useEffect(() => {
        if (!shiftBat || !allRoutes || !allStops) {
            return;
        }
        setUpdating(true);
        shiftBat.updateRows({apiKey, allStops, allTransfers, allRoutes, deadrunModelData}).then(() => {
            setUpdating(false);
        });
    }, [shiftBat, allRoutes, allStops, allTransfers, apiKey, setUpdating]);

    return (
        <>
            <div className="ShiftBatView">
                {contextHolder}

                <Prompt
                    when={controls.backLength > 0 || updated}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />

                {printShiftBat ? <ShiftBatPrintModal
                    allStops={allStops}
                    allRoutes={allRoutes}
                    apiKey={apiKey}
                    shiftBat={shiftBat}
                    visible={printShiftBat}
                    schedules={schedules}
                    setVisible={setPrintShiftBat}/> : <></>}
                {!initialSB && editMode ?
                    <div className="d-flex align-items-center justify-content-between page-main-controls mb-0">
                        <div></div>
                        {/* <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank"
                           rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled"
                                                             icon={<Info/>}>Help</Button></a> */}
                        <div className="page-main-controls top-float">
                            <div className="d-flex page-sec-controls">
                                {controls.backLength > 0 || updated ?
                                    <Popconfirm title={'You have unsaved changes. Are you sure you want to leave?'}
                                                onConfirm={e => {
                                                    controls.go(-controls.backLength);
                                                    setEditMode(false);
                                                }}>
                                        <Button type="primary" className="icon-button btn-filled btn-light icon-10"
                                                icon={<Close/>}
                                        > Close</Button></Popconfirm> :
                                    <Button type="primary" className="icon-button btn-filled btn-light icon-10"
                                            icon={<Close/>}
                                            onClick={() => setEditMode(false)}
                                    > Close</Button>
                                }
                                <Button type="primary" className="icon-button btn-filled" icon={<Undo/>}
                                        disabled={!controls.canUndo || disableEditForRow()}
                                        onClick={() => {
                                            controls.undo();
                                        }}
                                >Undo ({controls.backLength})</Button>
                                <Popconfirm
                                    title="Reset all changes"
                                    description="Reset all changes since loading this shift bat?"
                                    onConfirm={() => {
                                        setIsCancelling(true);
                                        // setUpdated(false)
                                        if (id === '_new_') {
                                            shiftBatModelData.delete(shiftBat.shiftBatId, true).then(() => {
                                                console.log('Deleted new sb.');
                                            }).catch(e => {
                                                console.log(e);
                                            }).finally(() => {
                                                setIsCancelling(false);
                                                setEditMode(false);
                                                history.push('/shiftbats');
                                            });
                                        } else if (initialShiftbatRef.current) {
                                            save(initialShiftbatRef.current).then(() => {
                                                console.log('Saved original sb.');
                                                controls.reset(initialShiftbatRef.current);
                                            }).catch(e => {
                                                console.log(e);
                                            }).finally(() => {
                                                setIsCancelling(false);
                                                setEditMode(false);
                                            });
                                        } else {
                                            setIsCancelling(false);
                                            setEditMode(false);
                                        }
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <LoaderButton
                                        isLoading={isCancelling}
                                        type="primary"
                                        icon={<Reset/>}
                                        disabled={controls.backLength === 0}
                                        className="icon-button btn-filled btn-error"
                                    >Reset</LoaderButton>
                                </Popconfirm>
                                <Button type="primary" className="icon-button btn-filled" icon={<Redo/>}
                                        disabled={!controls.canRedo || disableEditForRow()}
                                        onClick={() => {
                                            controls.redo();
                                        }}
                                >Redo ({controls.forwardLength})</Button>
                                <Button type="primary" className="icon-button btn-filled btn-success"
                                        icon={<Check/>}
                                        disabled={disableEditForRow() || controls.backLength === 0}
                                        onClick={async e => await save(shiftBat, true)}
                                >Save</Button>
                            </div>
                        </div>
                    </div>
                    : !initialSB ?
                        <div className="d-flex align-items-center justify-content-between page-main-controls top-float">
                            <div className="d-flex page-sec-controls">
                                <Button type="primary" className="icon-button btn-filled" icon={<Print/>}
                                        disabled={!shiftBat?.isValid({allStops, allRoutes})}
                                        onClick={async event => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setPrintShiftBat(true);
                                        }}>Print</Button>
                                {/* <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank"
                               rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled"
                                                                 icon={<Info/>}>Help</Button></a> */}
                                <Button type="primary" className="icon-button btn-filled" onClick={e => {
                                    initialShiftbatRef.current = shiftBat.clone();
                                    controls.reset(shiftBat.clone());
                                    setEditMode(true);
                                }}
                                        icon={<Edit/>}>Edit</Button>
                            </div>
                        </div> : <></>
                }
                {shiftBat && !fetching && schedules ?
                    <>
                        <div className="card-main shift-bat-map no-pad d-flex">
                            <div className="map-holder" style={{width: '65%', position: 'relative'}}>
                                <div className="MapPlaceholder">
                                    {editRouteRow ?
                                        <RouteMap route={editRouteRow.route.clone()} editingRoute={true}
                                                  charter={charter}
                                                  routeOnly={true}
                                                  allStops={editRouteRow.start && editRouteRow.end ? keyBy([editRouteRow.start, editRouteRow.end], 'stopId') : charter.stops ? keyBy(charter.stops || [], 'stopId') : {}}
                                                  updateRouteFn={route => {
                                                      console.log('Updated route from ROuteMap');
                                                      setEditRouteRow(editRouteRow => {
                                                          editRouteRow.route.waypoints = route.waypoints;
                                                          editRouteRow.route.stops = route.stops;
                                                          editRouteRow.route.stopTImes = route.stopTimes;
                                                          editRouteRow.route.services = route.services;
                                                          editRouteRow.routeEdited = true;
                                                          return {...editRouteRow};
                                                      });
                                                  }}
                                                  createNewStop={(stop) => {
                                                      // if (setCharter) {
                                                      //     setCharter(charter => {
                                                      //         charter.stops = charter.stops || [];
                                                      //         charter.stops.push(stop);
                                                      //         return new Charter({...charter});
                                                      //     });
                                                      // }
                                                  }}
                                        />
                                        :
                                        <RouteMapViewer shiftBatBuilder={true} noToolbar={true}
                                                        showWpSelector={true}
                                                        routes={shiftBat.rows.filter(row => row.route).map(row => row.route).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                                        trips={shiftBat.rows.filter(row => row.trip).map(row => row.trip)}
                                                        allStops={allStops}
                                                        locations={charter ? reverse(shiftBat.rows.filter(row => [ShiftBatRowType.location, ShiftBatRowType.stop, ShiftBatRowType.service, ShiftBatRowType.charter].includes(row.type) && row.getStartLocation()).map(row => row.getStartLocation())) : null}
                                                        verifiedOnly={true}
                                                        highlightedRouteIds={highlightedRouteIds}
                                                        selectedStop={selectedStop}
                                                        focusStop={focusStop}
                                                        zoom={zoom}
                                                        setFocusStop={setFocusStop}
                                                        handleStopClick={(stop) => setSelectedStop(stop)}
                                                        filteredRoutes={shiftBat.rows.filter(row => row.route).map(row => {
                                                            return row.route;
                                                        }).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                                        onMapSelection={onMapSelection}
                                                        setOnMapSelection={setOnMapSelection}
                                                        charter={charter}
                                            // handleSelectedRoute={({routeId}) => {
                                            //     if (routeId) {
                                            //         const route = allRoutes[routeId]
                                            //         const id = ulid();
                                            //         console.log('New row...', route.routeId)
                                            //         shiftBat.addRow({id,
                                            //             type: ShiftBatRowType.service,
                                            //             title: `Depart ${route.stops[0].stopName}`,
                                            //             route}, shiftBat.rows.length)
                                            //         setShiftBat(shiftBat.clone())
                                            //         setEditing(editing => ({...editing, [id]: true}))
                                            //
                                            //     }
                                            // }}
                                            //             handleSelectedStop={(stop) => {
                                            //                 console.log('Selected stop: ', stop)
                                            //                 const id = ulid();
                                            //                 console.log('New row...', id)
                                            //                 shiftBat.addRow({
                                            //                     id,
                                            //                     type: ShiftBatRowType.stop,
                                            //                     title: stop.stopName,
                                            //                     stop
                                            //                 }, shiftBat.rows.length)
                                            //                 setShiftBat(shiftBat.clone())
                                            //                 setEditing(editing => ({...editing, [id]: true}))
                                            //                 setUpdated(true)
                                            //             }}
                                        />}
                                </div>
                            </div>

                            <div className="map-shiftbats" style={{width: '35%'}}>
                                <div className="sb-info-wrap">
                                    {editMode ?
                                        <div>
                                            <RouteTitleEdit route={{
                                                routeNumber: shiftBatNumber,
                                                routeName: shiftBatName,
                                                routeDetails: shiftBatDetails,
                                                routeLogo: shiftBatLogo,
                                                colour: shiftBat.shiftBatColour
                                            }}
                                                            fileList={fileList}
                                                            setFileList={setFileList}
                                                            handleLogoRemove={() => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatLogo('');
                                                            }}
                                                            editRouteNumber={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatNumber(val);
                                                            }}
                                                            onBlurRouteNumber={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatNumber
                                                                }));
                                                            }}
                                                            editRouteName={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatName(val);
                                                            }}
                                                            onBlurRouteName={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatName
                                                                }));
                                                            }}
                                                            editRouteDetails={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatDetails(val);
                                                            }}
                                                            onBlurRouteDetails={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatDetails
                                                                }));
                                                            }}
                                            />
                                            {!charter && <Select
                                                className="w-100 mb-2"
                                                placeholder="Operating calendars"
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                disabled={disableEditForRow()}
                                                onChange={scheduleIds => {
                                                    const effectiveDate = scheduleIds.map(sId => schedules[sId]).filter(s => !!s).reduce((p, c) => {
                                                        if (p === null) {
                                                            return c.getFirstActiveDate();
                                                        }
                                                        if (p.isBefore(c.getFirstActiveDate())) {
                                                            return p;
                                                        }
                                                        return c.getFirstActiveDate();
                                                    }, null);
                                                    const _shiftBat = new ShiftBat({
                                                        ...shiftBat,
                                                        scheduleIds,
                                                        schedules,
                                                        effectiveDate
                                                    });
                                                    setShiftBatNow(_shiftBat);
                                                }}
                                                value={shiftBat.scheduleIds}
                                                mode="multiple"
                                                allowClear
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={schedule_options(schedules)}
                                            />}

                                        </div>
                                        :
                                        <Row className={`RouteTitle flex-wrap align-items-center w-100 row-compact`}>
                                            <Col md="auto d-flex align-items-center">
                                                <div className={`RouteLogo`}
                                                     style={{borderColor: shiftBat.shiftBatColour}}>
                                                    {shiftBat?.shiftBatLogo?.length ?
                                                        <Image
                                                            src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${shiftBat.shiftBatLogo}`}/>
                                                        : <Image
                                                            src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'}
                                                            className="BusIcon"/>
                                                    }
                                                </div>
                                                <div className={`RouteNumber`}
                                                     style={{backgroundColor: shiftBat.shiftBatColour}}>{shiftBat.shiftBatNumber}</div>
                                            </Col>
                                            <Col>
                                                <div className={`RouteInfo align-items-center pl-2`}>
                                                    <h1 style={{color: '#000'}}>{shiftBat.shiftBatName}</h1>
                                                    <div className={`RouteDetails`}>{shiftBat.shiftBatDetails}</div>
                                                </div>
                                            </Col>

                                            {!charter && <div className="d-flex flex-row simple-tags w-100"
                                                              style={{marginTop: '10px', gap: '8px'}}>
                                                {shiftBat.scheduleIds.map(sId => <Tag
                                                    key={`sid-${sId}`}>{schedules[sId]?.scheduleName || 'MISSING CALENDAR'}</Tag>)}
                                            </div>}
                                        </Row>
                                    }

                                    <div
                                        className="d-flex info-text-wrap w-label align-items-center justify-content-between FlexColumn">
                                        <div className="d-flex" style={{gap: '8px'}}>
                                            <div className="icon-info-text">
                                                <Timer/>
                                                <div>
                                                    <span>Duration</span>{toHrsMinsSecs(shiftBat.getShiftTime(), false, true)}
                                                </div>
                                            </div>
                                            <div className="icon-info-text">
                                                <Services/>
                                                <div><span>Distance</span>{toKmMs(shiftBat.getShiftDistance(), 0)}</div>
                                            </div>
                                            <div className="icon-info-text">
                                                <Calendar/>
                                                <div>
                                                    <span>Effective Date</span>{shiftBat?.effectiveDate ? shiftBat?.effectiveDate.format('DD/MM/YYYY') : '--/--/----'}
                                                </div>
                                            </div>

                                            {!editMode && !shiftBat.charter && <div className="icon-info-text">
                                                <div>
                                                    <span>{ROUTE_STATUS[shiftBat?.published]}</span>
                                                </div>
                                            </div>}
                                        </div>

                                        {editMode ?
                                            <div>
                                                <Select options={ROUTE_STATUSES} defaultValue={-1}
                                                        onChange={v => {
                                                            setShiftBatNow(sb => new ShiftBat({
                                                                ...sb,
                                                                published: v,
                                                                approvedAt: v === 0 ? Date.now() : sb.approvedAt,
                                                                approvedBy: v === 0 ? getEditor() : sb.approvedBy,
                                                                publishedAt: v === 1 ? Date.now() : sb.publishedAt,
                                                                publishedBy: v === 1 ? getEditor() : sb.publishedBy
                                                            }));
                                                        }} value={shiftBat.published}/>
                                                <Form.Control className="color-picker"
                                                              style={{width: '80px'}}
                                                              type="color"
                                                              list="presets"
                                                              value={shiftBat.shiftBatColour}
                                                              title="Choose shift colour"
                                                              onChange={event => {
                                                                  const sb = new ShiftBat({
                                                                      ...shiftBat,
                                                                      shiftBatColour: event.target.value
                                                                  });
                                                                  setShiftBat(sb);
                                                              }}

                                                />
                                                <datalist id="presets">
                                                    <option value="#007bff"></option>
                                                    <option value="#1b65ac"></option>
                                                    <option value="#00aeef"></option>
                                                    <option value="#5567a9"></option>
                                                    <option value="#812990"></option>
                                                    <option value="#cb0b0b"></option>
                                                    <option value="#a02125"></option>
                                                    <option value="#c90b86"></option>
                                                    <option value="#e6b00e"></option>
                                                    <option value="#f57d20"></option>
                                                    <option value="#008a5d"></option>
                                                    <option value="#00b26a"></option>
                                                    <option value="#005a23"></option>
                                                    <option value="#76a33a"></option>
                                                    <option value="#515553"></option>

                                                    <option value="#"></option>
                                                    <option value="#"></option>
                                                </datalist>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>

                                <ShiftBatRows shiftBat={shiftBat} setEditing={setEditing} schedules={schedules}
                                              editing={editing} allRoutes={allRoutes} updating={updating}
                                              setUpdating={setUpdating} allTransfers={allTransfers}
                                              allStops={allStops} onUpdate={onUpdate} onCancel={cancel}
                                              disableEditForRow={disableEditForRow} setAllStops={setAllStops}
                                              selectedStop={selectedStop} onCreate={onCreate}
                                              deleteRow={deleteRow} setSelectedStop={setSelectedStop}
                                              setFocusStop={setFocusStop} handleNewDeadrun={handleNewDeadrun}
                                              fetching={fetching} setEditRouteRow={setEditRouteRow}
                                              editMode={editMode} viewing={viewing} setViewing={setViewing}
                                              setHighlightedRouteIds={setHighlightedRouteIds}
                                              setOnMapSelection={setOnMapSelection}
                                              editRouteRow={editRouteRow} allowRoutes={allowRoutes} charter={charter}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <LoadMessage message={`Loading Shift bat`}/>// ${shiftBat} && ${!fetching} && ${schedules}`}/>
                }
            </div>
        </>
    )
        ;
}

export default React.memo(ShiftBatView);
